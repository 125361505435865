import React, { useState, useEffect } from "react";
// import ColorComponent from "../../../component/ColorComponent";
// import StepperCompo from "../../../component/Stepper/StepperCompo";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
import { TailSpin } from "react-loader-spinner";
export default function AccountManagement() {
	const [fullData, setfullData] = useState("");
	const [records, setRecords] = useState("");
	useEffect(() => {
		fetchData();
		const handleModalHidden = (e) => {
			e.preventDefault();
			setData({
				store_email: "",
				access_token: "",
				store_name: "",
				store_password: ""
			});
			setStoreNameError("");
			setStorePasswordError("");
			setAccessTokenError("");
			setAccessTokenError("");
			setEmailError("");
		};
		const handleBulkModalHidden = (e) => {
			e.preventDefault();
			setFileError("");
			setFileName("");
			setFile(null);
		};
		$("#addUser").on("hidden.bs.modal", handleModalHidden);
		$("#buldAdd").on("hidden.bs.modal", handleBulkModalHidden);

		// Clean up the event handler when the component unmounts
		return () => {
			$("#addUser").off("hidden.bs.modal", handleModalHidden);
			$("#buldAdd").off("hidden.bs.modal", handleBulkModalHidden);
		};
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/acounts/getAllAcountStores`,
				config
			);
			if (response.data.isError === false) {
				console.log(response);
				const sortedData = response.data.data.sort(
					(a, b) => new Date(b.date) - new Date(a.date)
				);
				setRecords(sortedData);
				setfullData(sortedData);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const fetchSingleData = async (id) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/acounts/getAllAcountStoresById`,
				{
					accountStoreId: id
				},
				config
			);
			if (response.data.isError === false) {
				console.log(response.data.data[0]);
				setEditFormData({
					storeId: response.data.data[0]._id,
					store_email: response.data.data[0].store_email,
					access_token: response.data.data[0].stores.access_token,
					store_name: response.data.data[0].stores.store_name,
					store_password: response.data.data[0].store_password,
					shopify_link: response.data.data[0].store_createdBy.shopify_link
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({
		store_email: "",
		access_token: "",
		store_name: "",
		store_password: "",
		shopify_link: ""
	});
	const [editFormData, setEditFormData] = useState({
		storeId: "",
		store_email: "",
		access_token: "",
		store_name: "",
		store_password: "",
		shopify_link: ""
	});
	const [emailError, setEmailError] = useState("");
	const [accessTokenError, setAccessTokenError] = useState("");
	const [storeNameError, setStoreNameError] = useState("");
	const [storePasswordError, setStorePasswordError] = useState("");
	const [storeShopifyLinkError, setStoreShopifyLinkError] = useState("");
	const [fileError, setFileError] = useState("");
	const [file, setFile] = useState(null);
	const [fileName, setFileName] = useState(""); // State to store the file name

	const handleFileChange = (event) => {
		const selectedFile = event.target.files[0];
		if (selectedFile) {
			// Check if the file is a CSV
			if (
				selectedFile.type === "text/csv" ||
				selectedFile.name.endsWith(".csv")
			) {
				setFile(selectedFile); // Set the selected file
				setFileName(selectedFile.name); // Update the file name state
				setFileError(""); // Clear any previous error messages
			} else {
				setFileError("Only CSV files are allowed."); // Set error message
				setFile(null); // Clear the file state
				setFileName(""); // Clear the file name
				event.target.value = null; // Reset the file input
			}
		} else {
			setFile(null); // Clear the file if none is selected
			setFileName(""); // Clear the file name
			setFileError(""); // Clear any error messages
		}
	};

	// const handleData = (data) => {
	// 	console.log(data);
	// 	setData((prevData) => ({ ...prevData, ...data }));
	// };
	const handleEditChange = async (e) => {
		const { name, value } = e.target;
		setEditFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const handleChange = async (e) => {
		const { name, value } = e.target;
		setData((prevData) => ({ ...prevData, [name]: value }));
	};
	const close_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M13.3596 0.640137L0.639648 13.3601"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3596 13.3601L0.639648 0.640137"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const edit_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M14.0345 1L2.39655 12.6379L1 19L7.36207 17.6034L19 5.96552L14.0345 1Z"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.362 17.6035L2.39648 12.6379"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0602 7.90521L12.0947 2.9397"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const delete_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M15.6667 19.0001H4.33337C3.23004 19.0001 2.33337 18.1034 2.33337 17.0001V5.66675H17.6667V17.0001C17.6667 18.1034 16.77 19.0001 15.6667 19.0001Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.33337 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6666 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 5.66675H1V4.33341C1 3.41341 1.74667 2.66675 2.66667 2.66675H17.3333C18.2533 2.66675 19 3.41341 19 4.33341V5.66675Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6667 2.66667H7.33337V1.66667C7.33337 1.3 7.63337 1 8.00004 1H12C12.3667 1 12.6667 1.3 12.6667 1.66667V2.66667Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const view_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
		</svg>
	);
	const validateEmail = (email) => {
		// Regular expression for email validation
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		console.log(emailPattern.test(email));
		return emailPattern.test(email);
	};
	const handleBulkSubmit = async (e) => {
		e.preventDefault();
		if (!file && file == null) {
			setFileError("Please select a file first!");
		} else {
			setLoading(true);
			try {
				const config = {
					headers: {
						"Content-type": "multipart/form-data",
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				};
				const formData = new FormData();
				formData.append("file", file);
				const finalApi = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/add/bulk/AcountStore`,
					formData,
					config
				);
				if (finalApi.data.status === "success") {
					$("#buldAdd").modal("hide");
					Swal.fire({
						icon: "success",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
					setFileError("");
					setFileName("");
					setFile(null);
					fetchData();
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
			} catch (error) {
				// Handle errors from await
				setLoading(false);
				Swal.fire({
					icon: "error",
					title:
						error.response != ""
							? error.response.data.message
							: "There was an error, try again later!",
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				// setAccessTokenError(error.response.data.message);
				console.log(error);
			}
		}
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		let error = false;
		if (!validateEmail(data.store_email)) {
			setEmailError("Invalid email format");
			error = true;
		}
		if (data.store_email === "") {
			setEmailError("Email is required");
			error = true;
		}
		if (
			data.store_email === "" ||
			data.store_password === "" ||
			data.access_token === "" ||
			data.store_name === "" ||
			data.shopify_link === ""
		) {
			setStorePasswordError("Password is required");
			setAccessTokenError("Access Token is required");
			setStoreNameError("Store Name is required");
			setStoreShopifyLinkError("Shopify Link is required");
			error = true;
		} else {
			setStoreNameError("");
			setStorePasswordError("");
			setAccessTokenError("");
			setAccessTokenError("");
			setEmailError("");
			setStoreShopifyLinkError("");
		}
		setLoading(true);
		try {
			if (error === false) {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				};
				// const response = await axios.get(
				// 	`${process.env.REACT_APP_FRONTEND_URL}api/get/store?storename=${data.store_name}&access_token=${data.access_token}`,
				// 	config
				// );
				// if (response.data.status === "error") {
				// 	setAccessTokenError(response.data.message);
				// 	setLoading(false);
				// } else if (response.data.status === "success") {
				const formData = new FormData();
				formData.append("store_password", data.store_password);
				formData.append("store_name", data.store_name);
				formData.append("store_email", data.store_email);
				formData.append("access_token", data.access_token);
				formData.append("shopify_link", data.shopify_link);
				const finalApi = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/add/AcountStore`,
					formData,
					config
				);
				if (finalApi.data.status === "success") {
					$("#addUser").modal("hide");
					Swal.fire({
						icon: "success",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
					setData({
						store_email: "",
						access_token: "",
						store_name: "",
						store_password: "",
						shopify_link: ""
					});
					fetchData();
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
				// }
			} else {
				setEmailError("Invalid email format");
				setLoading(false);
			}
		} catch (error) {
			// Handle errors from await
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// setAccessTokenError(error.response.data.message);
			console.log(error);
		}
	};
	// $("#addUser").on("hidden.bs.modal", function (e) {
	// 	// do something…
	// 	e.preventDefault();
	// 	console.log("hi");
	// 	setData({
	// 		store_email: "",
	// 		access_token: "",
	// 		store_name: "",
	// 		store_password: ""
	// 	});
	// 	return;
	// });
	const handleEditSubmit = async (e) => {
		e.preventDefault();
		let error = false;
		if (!validateEmail(editFormData.store_email)) {
			setEmailError("Invalid email format");
			error = true;
		}
		if (editFormData.store_email === "") {
			setEmailError("Email is required");
			error = true;
		}
		if (
			editFormData.store_email === "" ||
			editFormData.store_password === "" ||
			editFormData.access_token === "" ||
			editFormData.store_name === "" ||
			editFormData.shopify_link === ""
		) {
			setStorePasswordError("Password is required");
			setAccessTokenError("Access Token is required");
			setStoreNameError("Store Name is required");
			setStoreShopifyLinkError("Shopify Link is required");
			error = true;
		} else {
			setStoreNameError("");
			setStorePasswordError("");
			setAccessTokenError("");
			setAccessTokenError("");
			setEmailError("");
			setStoreShopifyLinkError("");
		}
		setLoading(true);
		try {
			if (error === false) {
				const config = {
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${localStorage.getItem("token")}`
					}
				};
				const formData = new FormData();
				formData.append("store_password", editFormData.store_password);
				formData.append("store_name", editFormData.store_name);
				formData.append("store_email", editFormData.store_email);
				formData.append("access_token", editFormData.access_token);
				formData.append("storeId", editFormData.storeId);
				formData.append("shopify_link", editFormData.shopify_link);
				formData.append("isActive", true);
				const finalApi = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/update/AcountStore`,
					formData,
					config
				);
				if (finalApi.data.status === "success") {
					$("#editAccount").modal("hide");
					Swal.fire({
						icon: "success",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
					setData({
						store_email: "",
						access_token: "",
						store_name: "",
						store_password: ""
					});
					fetchData();
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: finalApi.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
				// }
			} else {
				setEmailError("Invalid email format");
				setLoading(false);
			}
		} catch (error) {
			// Handle errors from await
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// setAccessTokenError(error.response.data.message);
			console.log(error);
		}
	};
	const AccountClick = async (e, id) => {
		setLoading(true);
		try {
			// Wait for fetchSingleData to complete
			await fetchSingleData(e);
			setLoading(false);
			let modal = $(`#${id}`);
			modal.modal("show");
		} catch (error) {
			setLoading(false);
			console.error("Error fetching single data:", error);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// Handle the error if needed
		}
	};
	const deleteAccountClick = async (e) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#25E5C3",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/remove/AcountStore`,
					{
						storeId: e
					},
					config
				);
				if (response.data.status === "success") {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: response.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
					fetchData();
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
				fetchData();
			}
		});
	};
	const CustomTransferCell = ({ row }) =>
		row.isVerifiedStore ? (
			<span className="badge badge-pill badge-success">Transferred</span>
		) : (
			<span className="badge badge-pill badge-warning">Not Transferred</span>
		);
	const CustomStatusCell = ({ row }) =>
		row.isActive === true ? (
			<span className="badge badge-pill badge-success">Active</span>
		) : (
			<span className="badge badge-pill badge-danger">Expired</span>
		);
	const CustomCreds = ({ row }) =>
		row.isStoreTransfered ? (
			<span className="badge badge-pill badge-success">Creds Used</span>
		) : (
			<span className="badge badge-pill badge-warning">Creds Not Used</span>
		);
	const CustomActions = ({ row }) => (
		<div className="action-btns">
			<button
				onClick={() => AccountClick(row._id, "viewAccount")}
				className="btn btn-view"
				data-id={row._id}
			>
				{view_svg}
			</button>
			<button
				onClick={() => AccountClick(row._id, "editAccount")}
				className="btn btn-edit"
				data-id={row._id}
			>
				{edit_svg}
			</button>
			<button
				onClick={() => deleteAccountClick(row._id)}
				className="btn btn-delete"
			>
				{delete_svg}
			</button>
		</div>
	);
	const CustomShopifyLinkCell = ({ row }) => (
		<a
			href={row.store_createdBy?.shopify_link}
			target="_blank"
			rel="noopener noreferrer"
		>
			Link
		</a>
	);
	const CustomCreatedCell = ({ row }) => <div>{formatDate(row.date)}</div>;
	const columns = [
		{
			name: "Email",
			selector: (row) => row.store_email,
			sortable: true,
			wrap: true,
			maxWidth: "20%"
		},
		{
			name: "Transferred to",
			selector: (row) => row.store_Transfered,
			sortable: false,
			wrap: true,
			maxWidth: "30%"
		},
		{
			name: "Creds Status",
			cell: (row) => <CustomCreds row={row} />,
			selector: (row) => row.isStoreTransfered,
			sortable: true,
			maxWidth: "10%",
			wrap: true
		},
		{
			name: "Is Transferred",
			cell: (row) => <CustomTransferCell row={row} />,
			selector: (row) => row.isVerifiedStore,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Status",
			cell: (row) => <CustomStatusCell row={row} />,
			selector: (row) => row.isActive,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Shopify Link",
			selector: (row) => <CustomShopifyLinkCell row={row} />,
			sortable: false,
			maxWidth: "10%"
		},
		{
			name: "Created At",
			cell: (row) => <CustomCreatedCell row={row} />,
			selector: (row) => row.date,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Actions",
			cell: (row) => <CustomActions row={row} />,
			sortable: false,
			maxWidth: "15%"
		}
	];
	function formatDate(dateString) {
		const options = {
			year: "numeric",
			month: "short",
			day: "numeric",
			hour: "2-digit",
			minute: "2-digit",
			hour12: true
		};
		const date = new Date(dateString);
		return date.toLocaleString("en-US", options);
	}
	function handleFilter(e) {
		if (e.target.value.toLowerCase() !== "") {
			const filterData = records.filter((row) => {
				return row["store_email"]
					.toLowerCase()
					.includes(e.target.value.toLowerCase()) || (row["store_Transfered"] && row["store_Transfered"]
					.toLowerCase()
					.includes(e.target.value.toLowerCase()));
			});
			setRecords(filterData);
		} else {
			setRecords(fullData);
		}
	}
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Account Management</h2>
				<div className="btns">
					<button
						data-toggle="modal"
						// onClick={openModal}
						data-target="#buldAdd"
						className="btn btn-outline"
					>
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1Z"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.12 15V5"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 9.11L10.12 5L14.23 9.11"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Bulk Upload
					</button>
					<div
						className="modal fade account-management-modal"
						id="buldAdd"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="modelTitleId"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-lg" role="document">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									{close_svg}
								</button>
								<div className="modal-body">
									<form onSubmit={handleBulkSubmit}>
										<h2>Upload CSV</h2>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												id="validatedCustomFile"
												onChange={handleFileChange}
											/>
											<label
												className="custom-file-label"
												htmlFor="validatedCustomFile"
											>
												{fileName || "Choose file..."}{" "}
												{/* Display file name or 'Choose file...' */}
											</label>
											<div
												className={
													fileError != "" ? "d-block invalid-feedback" : ""
												}
											>
												{fileError}
											</div>
										</div>
										<div className="form-row mt-4">
											<button
												type="submit"
												className={`btn btn-next btn-gradient`}
											>
												Save{" "}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
					<button
						className="btn btn-gradient"
						data-toggle="modal"
						// onClick={openModal}
						data-target="#addUser"
					>
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5.13989V14.8599"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.8601 10H5.14014"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Add Account
					</button>

					<div
						className="modal fade account-management-modal"
						id="addUser"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="modelTitleId"
						aria-hidden="true"
					>
						<div className="modal-dialog modal-lg" role="document">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									{close_svg}
								</button>
								<div className="modal-body">
									<form onSubmit={handleSubmit}>
										<h2>Add Account Details</h2>
										<div className="form-row">
											<div className="col-md-6">
												<div className="">
													<label className="form-group__label">
														Enter Store Email Address
													</label>
													<input
														type="text"
														name="store_email"
														className="form-group__input"
														value={data.store_email}
														onChange={handleChange}
													/>
													<p className="error">{emailError}</p>
												</div>
											</div>
											<div className="col-md-6">
												<label className="form-group__label">
													Enter Store Password
												</label>
												<input
													type="password"
													className="form-group__input"
													name="store_password"
													value={data.store_password}
													onChange={handleChange}
												/>
												<p className="error">{storePasswordError}</p>
											</div>
										</div>
										<div className="form-row">
											<div className="col-md-6">
												<div className="">
													<label className="form-group__label">
														Enter API Access Token
													</label>
													<input
														type="text"
														name="access_token"
														value={data.access_token}
														onChange={handleChange}
														placeholder="API Token"
														className="form-group__input"
													/>
													<p className="error">{accessTokenError}</p>
												</div>
											</div>
											<div className="col-md-6">
												<div className="div_business my-0">
													<label className="form-group__label">
														Enter Store Name
													</label>
													<div className="inline">
														<input
															type="text"
															value={data.store_name}
															onChange={handleChange}
															name="store_name"
															className="form-group__input"
															placeholder="Store Name"
														/>
														<div className="connector">.myshopify.com</div>
													</div>
													<p className="error">{storeNameError}</p>
												</div>
											</div>
										</div>
										<div className="form-row">
											<div className="col-md-12">
												<div className="">
													<label className="form-group__label">
														Enter Shopify Link
													</label>
													<input
														type="url"
														name="shopify_link"
														className="form-group__input"
														value={data.shopify_link}
														onChange={handleChange}
													/>
													<p className="error">{storeShopifyLinkError}</p>
												</div>
											</div>
										</div>
										<div className="form-row mt-4">
											<button
												type="submit"
												className={`btn btn-next btn-gradient`}
											>
												Save{" "}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="body">
				<div className="table">
					<div className="text-end">
						<input
							type="text"
							placeholder="Search by Email"
							onChange={handleFilter}
						/>
					</div>

					<DataTable columns={columns} data={records} pagination></DataTable>
				</div>
			</div>
			{/* view modal  */}
			<div
				className="modal fade account-management-modal"
				id="viewAccount"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>View Account</h2>

							<form onSubmit={handleEditSubmit}>
								<div className="form-row">
									<div className="col-md-6">
										<div className="">
											<label className="form-group__label">
												Enter Store Email Address
											</label>
											<input
												type="text"
												name="store_email"
												className="form-group__input"
												value={editFormData.store_email}
												onChange={handleEditChange}
												disabled
											/>
											<p className="error">{emailError}</p>
										</div>
									</div>
									<div className="col-md-6">
										<label className="form-group__label">
											Enter Store Password
										</label>
										<input
											type="text"
											className="form-group__input"
											name="store_password"
											value={editFormData.store_password}
											onChange={handleEditChange}
											disabled
										/>
										<p className="error">{storePasswordError}</p>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-6">
										<div className="">
											<label className="form-group__label">
												Enter API Access Token
											</label>
											<input
												type="text"
												name="access_token"
												value={editFormData.access_token}
												onChange={handleEditChange}
												disabled
												placeholder="API Token"
												className="form-group__input"
											/>
											<p className="error">{accessTokenError}</p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="div_business my-0">
											<label className="form-group__label">
												Enter Store Name
											</label>
											<div className="inline">
												<input
													type="text"
													value={editFormData.store_name}
													onChange={handleEditChange}
													disabled
													name="store_name"
													className="form-group__input"
													placeholder="Store Name"
												/>
												<div className="connector">.myshopify.com</div>
											</div>
											<p className="error">{storeNameError}</p>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-12">
										<div className="">
											<label className="form-group__label">
												Enter Shopify Link
											</label>
											<input
												type="url"
												name="shopify_link"
												className="form-group__input"
												value={editFormData.shopify_link}
												onChange={handleEditChange}
												disabled
											/>
											<p className="error">{storeShopifyLinkError}</p>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* edit modal  */}
			<div
				className="modal fade account-management-modal"
				id="editAccount"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-lg" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>Edit Account</h2>

							<form onSubmit={handleEditSubmit}>
								<div className="form-row">
									<div className="col-md-6">
										<div className="">
											<label className="form-group__label">
												Enter Store Email Address
											</label>
											<input
												type="text"
												name="store_email"
												className="form-group__input"
												value={editFormData.store_email}
												onChange={handleEditChange}
											/>
											<p className="error">{emailError}</p>
										</div>
									</div>
									<div className="col-md-6">
										<label className="form-group__label">
											Enter Store Password
										</label>
										<input
											type="text"
											className="form-group__input"
											name="store_password"
											value={editFormData.store_password}
											onChange={handleEditChange}
										/>
										<p className="error">{storePasswordError}</p>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-6">
										<div className="">
											<label className="form-group__label">
												Enter API Access Token
											</label>
											<input
												type="text"
												name="access_token"
												value={editFormData.access_token}
												onChange={handleEditChange}
												placeholder="API Token"
												className="form-group__input"
											/>
											<p className="error">{accessTokenError}</p>
										</div>
									</div>
									<div className="col-md-6">
										<div className="div_business my-0">
											<label className="form-group__label">
												Enter Store Name
											</label>
											<div className="inline">
												<input
													type="text"
													value={editFormData.store_name}
													onChange={handleEditChange}
													name="store_name"
													className="form-group__input"
													placeholder="Store Name"
												/>
												<div className="connector">.myshopify.com</div>
											</div>
											<p className="error">{storeNameError}</p>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-md-12">
										<div className="">
											<label className="form-group__label">
												Enter Shopify Link
											</label>
											<input
												type="url"
												name="shopify_link"
												className="form-group__input"
												value={editFormData.shopify_link}
												onChange={handleEditChange}
											/>
											<p className="error">{storeShopifyLinkError}</p>
										</div>
									</div>
								</div>
								<div className="form-row mt-4">
									<button type="submit" className={`btn btn-next btn-gradient`}>
										Update{" "}
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
