import React, { Component } from "react";

class ColorComponent extends Component {
	constructor(props) {
		super(props);
		if (this.props.storeData.colorFor === "theme_color") {
			if (this.props.storeData.theme_color) {
				this.state = {
					colorStore: this.props.storeData.theme_color
				};
			}
		} else if (this.props.storeData.colorFor === "font_color") {
			if (this.props.storeData.font_color) {
				this.state = {
					colorStore: this.props.storeData.font_color
				};
			}
		} else {
			this.state = {
				colorStore: ""
			};
		}
	}

	render() {
		const { colorFor, headingName, handleData, colorArray, defaultColor } =
			this.props;
		const handleColorChange = (e, color) => {
			e.preventDefault();
			if (e.target.getAttribute("data-customcolor") === "update") {
				this.setState({ colorStore: e.target.value });
				// this.setState({ theme_color: e.target.value });
				var newColor = e.target.value;
			} else {
				this.setState({ colorStore: color });
				// this.inputElement.click();
				// let  obj = {key: colorFor, color}
				newColor = color;
			}

			if (colorFor === "theme_color") {
				handleData({ theme_color: newColor });
				// if theme color black #000000 #FFFFFF
				if (newColor == "#000000") {
					handleData({ font_color: "#FFFFFF" });
				} else if (newColor == "#FFFFFF") {
					handleData({ font_color: "#000000" });
				} else {
					handleData({ font_color: "#FFFFFF" });
				}
			}

			if (colorFor === "font_color") {
				handleData({ font_color: newColor });
			}
		};

		var disabled = false;
		if (colorFor === "font_color") {
			disabled = true;
		}
		if (colorFor === "theme_color") {
			disabled = true;
		}
		if (this.state.colorStore) {
		} else {
			if (colorFor === "theme_color") {
				if (this.props.storeData.theme_color) {
					this.setState({ colorStore: this.props.storeData.theme_color });
				} else {
					this.setState({ colorStore: defaultColor[1] });
				}
			}

			if (colorFor === "font_color") {
				if (this.props.storeData.font_color) {
					this.setState({ colorStore: this.props.storeData.font_color });
				} else {
					this.setState({ colorStore: defaultColor[0] });
				}
			}
		}

		return (
			<div className="">
				<label>{headingName}</label>
				<div className="so-color-box-div d-flex align-items-center ">
					<div
						className="so-selected-color-div"
						style={{
							backgroundColor: this.state.colorStore,
							borderColor:
								this.state.colorStore == "#FFFFFF"
									? "#ddd"
									: this.state.colorStore
						}}
					></div>
					<div className="d-none">
						<input
							type="color"
							disabled={disabled}
							data-customcolor="update"
							value={this.state.colorStore}
							onChange={(e) => handleColorChange(e, this.state.colorStore)}
						/>
					</div>

					<div className="Main">
						{colorArray.map((colorCode, index) => (
							<button
								key={index}
								onClick={(e) => {
									handleColorChange(e, colorCode);
								}}
							>
								<div
									className="color-box"
									style={{
										backgroundColor: colorCode,
										borderColor: colorCode == "#FFFFFF" ? "#ddd" : colorCode
									}}
								></div>
							</button>
						))}

						{/* <button
            onClick={(e) => {
              handleColorChange(e, "#31d2ff");
            }}
          >
            <div className="red blue"></div>
          </button>
          <button
            onClick={(e) => {
              handleColorChange(e, "#8cff6f");
            }}
          >
            <div className="red green"></div>
          </button>
          <button
            onClick={(e) => {
              handleColorChange(e, "#fda437");
            }}
          >
            <div className="red orange"></div>
          </button> */}
					</div>
				</div>
			</div>
		);
	}
}

export default ColorComponent;
