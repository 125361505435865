import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import warningIcon from '../../images/warning-icon.png';
import { useNavigate } from 'react-router-dom';
const TokenExpirePopup = (props)=>{
    const navigate = useNavigate();
    useEffect(() => {
        if(props.isOpen){
            document.body.style.overflow = "hidden";
            setOpen(true);
        }
    }, [])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
		setOpen(false);
		document.body.style.overflow = "unset";
	};
    const handleClick = async(e)=>{
        setLoading(true);
        props.handleData({
            email: ""
        });
        navigate('/');
        document.body.style.overflow = "auto";
    }
    return (
        <Popup open={open} onClose={closeModal} modal closeOnDocumentClick>
				<div className="expired-popup">
                    <img src={warningIcon} alt="warning" className="img-fluid" />
					<h4>
						{props.message}
					</h4>
                    <button className="btn btn-continue" onClick={handleClick} disabled={loading}>{loading ? (<div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>) : 'Close'}</button>
				</div>
		</Popup>
    );
}
export default TokenExpirePopup;