import React from "react";
import { Stepper } from "react-form-stepper";
import "./stepper.css";

const StepperCompo = ({ count }) => {
	const stepObj = [
		{ label: "Business Details" },
		// { label: 'Pick Your Color' },
		// { label: 'Pick Your Logo' },
		// { label: 'Setup Store' },
		// { label: 'Pick a Store Name' },
		{ label: "Store Access" },
		// { label: 'Pick Your Store Theme' },
		// { label: 'Store Detail' },
		{ label: "Finish" }
	];

	return (
		<Stepper
			steps={stepObj}
			activeStep={count}
			styleConfig={{
				activeBgColor: "#084e93",
				activeTextColor: "#fff",
				inactiveBgColor: "#fff",
				inactiveTextColor: "#ffc260",
				completedBgColor: "#084e93",
				completedTextColor: "#fff",
				size: "3em"
			}}
			className={"stepper"}
			stepClassName={"stepper__step"}
		/>
	);
};

export default StepperCompo;
