import React from "react";
import $ from "jquery";
const dashboard_svg = (
	<svg
		width="22"
		height="20"
		viewBox="0 0 22 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9995 13.2727C12.2547 13.2727 13.2723 12.2552 13.2723 11C13.2723 9.74483 12.2547 8.72729 10.9995 8.72729C9.74434 8.72729 8.72681 9.74483 8.72681 11C8.72681 12.2552 9.74434 13.2727 10.9995 13.2727Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 11H4.50909"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.4905 11H20.9996"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 1V4.50909"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.6091 9.39086L15.8 6.19995"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.92749 3.92725L6.40931 6.40906"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.65455 18.7273H17.3455C19.5727 16.8909 21 14.1091 21 11C21 5.47273 16.5273 1 11 1C5.47273 1 1 5.47273 1 11C1 14.1091 2.42727 16.8909 4.65455 18.7273Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const um_svg = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.4778 15.7221C10.4778 15.7221 10.4444 15.1888 10.4 14.9332L11.5556 14.0999L10.3556 12.0666L9.03333 12.6332C8.62222 12.2888 8.15555 12.011 7.63333 11.8443L7.47778 10.4443H5.07778L4.92222 11.8443C4.4 12.0221 3.93333 12.2888 3.52222 12.6332L2.2 12.0666L1 14.0999L2.15556 14.9332C2.1 15.1888 2.07778 15.7221 2.07778 15.7221C2.07778 15.7221 2.11111 16.2554 2.15556 16.511L1 17.3443L2.2 19.3777L3.52222 18.811C3.93333 19.1554 4.4 19.4332 4.92222 19.5999L5.07778 20.9999H7.47778L7.63333 19.5999C8.15555 19.4221 8.62222 19.1554 9.03333 18.811L10.3556 19.3777L11.5556 17.3443L10.4 16.511C10.4556 16.2554 10.4778 15.7221 10.4778 15.7221Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.27777 17.4777C7.24734 17.4777 8.03333 16.6917 8.03333 15.7221C8.03333 14.7525 7.24734 13.9666 6.27777 13.9666C5.30821 13.9666 4.52222 14.7525 4.52222 15.7221C4.52222 16.6917 5.30821 17.4777 6.27777 17.4777Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0444 12.7444C17.0888 12.7444 20.9999 13.6111 20.9999 15.7888V19.6999C20.9999 20.4222 20.411 20.9999 19.6999 20.9999H12.3555"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0444 9.88889C11.8777 9.88889 10.1333 8.13333 10.1333 5.97778V4.91111C10.1333 2.74444 11.8889 1 14.0444 1C16.2111 1 17.9555 2.75556 17.9555 4.91111V5.97778C17.9555 8.13333 16.2 9.88889 14.0444 9.88889Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const shop_svg = (
	<svg
		fill="#fff"
		width="22"
		height="22"
		viewBox="-1.52 0 122.88 122.88"
		version="1.1"
		id="Layer_1"
	>
		<g>
			<path
				className="st0"
				d="M20.57,0h79.69c1.87,0,3.39,1.53,3.39,3.4s-1.53,3.4-3.39,3.4l-79.69,0c-1.87,0-3.39-1.53-3.39-3.4 C17.17,1.53,18.7,0,20.57,0L20.57,0z M118.58,43.17v4.1c0.04,0.39,0.06,0.78,0.06,1.17c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88l-0.57,0c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.57c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.36c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.34,0.02-0.67,0.05-1v-4.27L118.58,43.17L118.58,43.17z M16.84,10.44h87.64l11.17,23.85H5.26 L16.84,10.44L16.84,10.44z M3.39,108.01h12.12v-38.3c0.2,0.01,0.4,0.02,0.6,0.02l0.05,0l0.23,0h0.04l0.03,0h0.16l0.03,0h0.14v0.03 h0.01v-0.03c5.48,0,10.55-1.75,14.5-4.65l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0l0.1,0.07l0,0l0.03,0.02l0.01,0.01l0.05,0.03l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02L32,65.59l0.03,0.02l0.02,0.02l0,0l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.01,0l0.23,0.15l0.03,0.02l0.03,0.02l0.18,0.12l0.03,0.02l0.22,0.13L33,66.22l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.03,0.02l0.03,0.02v25.1l53.38,0V66.93c0.86-0.45,1.7-0.96,2.49-1.51l0.02-0.02l0.01,0l0.16-0.11l0.01-0.01l0.05-0.03l0.05-0.03 l0,0l0.08-0.06l0.01,0l0.01-0.01l0.17-0.12c3.97,2.95,9.08,4.73,14.61,4.73v0.03l0.01,0v-0.03h0.03v38.27h12.12 c1.87,0,3.4,3.34,3.4,7.43c0,4.09-1.53,7.43-3.4,7.43H3.39c-1.86,0-3.39-3.34-3.39-7.43C0,111.36,1.53,108.01,3.39,108.01 L3.39,108.01z"
			/>
		</g>
	</svg>
);
const hamburger_svg = (
	<svg
		width="16"
		height="14"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="16" height="2" fill="white" />
		<rect y="6" width="16" height="2" fill="white" />
		<rect y="12" width="16" height="2" fill="white" />
	</svg>
);
const close_svg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
	>
		<path
			d="M13.3596 0.640137L0.639648 13.3601"
			stroke="white"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.3596 13.3601L0.639648 0.640137"
			stroke="white"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
function Sidebar(props) {
	const handleClick = (e) => {
		e.preventDefault();
		if (props.isMobile) {
			$("#sidebar").hide();
		} else $(".wrapper").toggleClass("collapsed");
	};
	return (
		<>
			<nav id="sidebar">
				<div className="sidebar-content">
					<div className="sidebar-header">
						<img
							src={props.store_logo}
							alt=""
							className="img-fluid sidebar-logo"
						/>
						<button className="btn btn-toggle" onClick={handleClick}>
							{props.isMobile ? close_svg : hamburger_svg}
						</button>
					</div>

					<ul className="list-unstyled components">
						<li className={props.active === "home" ? "active" : ""}>
							<a href="home">
								<div className="icon">{dashboard_svg}</div>
								<div className="text">Dashboard</div>
							</a>
						</li>
						<li className={props.active === "shopify_link" ? "active" : ""}>
							<a href="shopify">
								<div className="icon">{shop_svg}</div>
								<div className="text">Update Shopify Link</div>
							</a>
						</li>
						<li className={props.active === "profile" ? "active" : ""}>
							<a href="profile">
								<div className="icon">{um_svg}</div>
								<div className="text">My Profile</div>
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</>
	);
}

export default Sidebar;
