import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import $ from "jquery";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Switch from "react-switch";
import { log } from "async";
function UserManagement() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getAllUser`,
				config
			);
			if (response.data.isError === false) {
				const sortedData = response.data.data.sort(
					(a, b) => new Date(b.date) - new Date(a.date)
				);
				setRecords(sortedData);
				setfullData(sortedData);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const fetchSingleData = async (id) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getUserById`,
				{
					userId: id
				},
				config
			);
			if (response.data.isError === false) {
				console.log(response.data.data[0].isStockEnabled);
				setEditFormData({
					userId: response.data.data[0]._id,
					email: response.data.data[0].email,
					firstName: response.data.data[0].firstName,
					lastName: response.data.data[0].lastName,
					shopify_link: response.data.data[0].shopify_link,
					max_stock: response.data.data[0].max_stock,
					stock_enabled: response.data.data[0].isStockEnabled,
					// password: response.data.data[0].password,
					affiliate_link: new URL(
						response.data.data[0].affiliate_link
					).searchParams
						.get("ref")
						.replace("/", ""),
					last_affiliate_link: new URL(
						response.data.data[0].affiliate_link
					).searchParams
						.get("ref")
						.replace("/", "")
					// ... update other properties based on the response
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	// ];
	const close_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M13.3596 0.640137L0.639648 13.3601"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3596 13.3601L0.639648 0.640137"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const edit_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M14.0345 1L2.39655 12.6379L1 19L7.36207 17.6034L19 5.96552L14.0345 1Z"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.362 17.6035L2.39648 12.6379"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0602 7.90521L12.0947 2.9397"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const delete_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M15.6667 19.0001H4.33337C3.23004 19.0001 2.33337 18.1034 2.33337 17.0001V5.66675H17.6667V17.0001C17.6667 18.1034 16.77 19.0001 15.6667 19.0001Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.33337 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6666 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 5.66675H1V4.33341C1 3.41341 1.74667 2.66675 2.66667 2.66675H17.3333C18.2533 2.66675 19 3.41341 19 4.33341V5.66675Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6667 2.66667H7.33337V1.66667C7.33337 1.3 7.63337 1 8.00004 1H12C12.3667 1 12.6667 1.3 12.6667 1.66667V2.66667Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const view_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
		</svg>
	);
	const password_svg = (
		<svg
			fill="#005694"
			height="24px"
			width="24px"
			version="1.1"
			id="Layer_1"
			viewBox="0 0 330 330"
		>
			<g id="XMLID_509_">
				<path
					id="XMLID_510_"
					d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
		S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
		s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
		C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
				/>
			</g>
		</svg>
	);
	const generate_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="27"
			height="22"
			viewBox="0 0 27 22"
			fill="none"
		>
			<path
				d="M3.32007 9.49C4.05007 4.68 8.20007 1 13.2101 1C15.2301 1 17.1101 1.6 18.6801 2.63"
				stroke="#069399"
				stroke-width="2"
				strokeMiterlimit="10"
				stroke-linecap="round"
			/>
			<path
				d="M6.97 8.64007L3.21 14.5201L0 8.32007L6.97 8.64007Z"
				fill="#069399"
			/>
			<path
				d="M23.1002 12.51C22.3702 17.32 18.2202 21 13.2102 21C11.1902 21 9.31023 20.4 7.74023 19.37"
				stroke="#069399"
				stroke-width="2"
				strokeMiterlimit="10"
				stroke-linecap="round"
			/>
			<path
				d="M19.4402 13.36L23.2102 7.47998L26.4202 13.68L19.4402 13.36Z"
				fill="#069399"
			/>
		</svg>
	);
	const handleCopyClick = (url) => {
		// Access the data-url attribute from the button and copy it to the clipboard
		const dataUrl = url;

		// Use the Clipboard API to copy the data-url to the clipboard
		navigator.clipboard
			.writeText(dataUrl)
			.then(() => {
				console.log("Data copied to clipboard:", dataUrl);
				// Optionally, you can provide user feedback that the copy was successful
			})
			.catch((error) => {
				console.error("Unable to copy data to clipboard", error);
				// Handle errors or provide user feedback on copy failure
			});
	};
	const CustomNameCell = ({ row }) => (
		<div>
			{row.firstName} {row.lastName}
		</div>
	);
	const CustomShopifyLinkCell = ({ row }) => (
		<a href={row.shopify_link} target="_blank" rel="noopener noreferrer">
			Link
		</a>
	);
	const CustomAffiliateLinkCell = ({ row }) => (
		<span>
			{row.affiliate_link}{" "}
			<span
				className="cursor-pointer"
				data-url={row.affiliate_link}
				onClick={() => handleCopyClick(row.affiliate_link)}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="13"
					height="15"
					viewBox="0 0 13 15"
					fill="none"
				>
					<path d="M9 4H1V14H9V4Z" stroke="#444444" strokeMiterlimit="10" />
					<path d="M4 1H12V11" stroke="#444444" strokeMiterlimit="10" />
				</svg>
			</span>
		</span>
	);
	const CustomActions = ({ row }) =>
		row.roles[0] !== 5150 ? (
			row.isActive === true ? (
				<div className="action-btns">
					<button
						onClick={() => UserClick(row._id, "viewUser")}
						className="btn btn-view"
						data-id={row._id}
					>
						{view_svg}
					</button>
					<button
						onClick={() => UserClick(row._id, "editUser")}
						className="btn btn-edit"
						data-id={row._id}
					>
						{edit_svg}
					</button>
					<button
						onClick={() => UserClick(row._id, "passwordEditUser")}
						className="btn btn-password"
						data-id={row._id}
					>
						{password_svg}
					</button>
					<button
						onClick={() => deleteUserClick(row._id)}
						className="btn btn-delete"
					>
						{delete_svg}
					</button>
				</div>
			) : (
				<div className="action-btns">
					<button
						onClick={() => UserClick(row._id, "viewUser")}
						className="btn btn-view"
						data-id={row._id}
					>
						{view_svg}
					</button>
				</div>
			)
		) : null;
	const CustomStatusCell = ({ row }) =>
		row.isActive === true ? (
			<span className="badge badge-pill badge-success">Active</span>
		) : (
			<span className="badge badge-pill badge-danger">Disabled</span>
		);
	const CustomStockEnabledCell = ({ row }) =>
		row.roles[0] !== 5150 ? (
			<Switch
				name="stock_enabled"
				value={row.isStockEnabled}
				onChange={() => handleStockSwitch(row._id, row.isStockEnabled)}
				checked={row.isStockEnabled}
				checkedIcon={false}
				uncheckedIcon={false}
				onColor={"#149CC4"}
			/>
		) : null;
	const StockCell = ({ row }) => (
		<span>
			{row.consumed_stock}/{row.max_stock}
		</span>
	);
	const handleStockSwitch = async (e, stock_enabled) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/update/toggleUserStock`,
				{
					userId: e,
					stock_enabled: !stock_enabled
				},
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				fetchData();
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	const columns = [
		{
			name: "Name",
			selector: (row) => row.firstName,
			cell: (row) => <CustomNameCell row={row} />,
			sortable: true,
			wrap: true,
			maxWidth: "10%"
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			wrap: true,
			maxWidth: "15%"
		},
		{
			name: "Max Stock",
			selector: (row) => row.max_stock,
			cell: (row) => <StockCell row={row} />,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Stock Enabled",
			selector: (row) => row.isStockEnabled,
			cell: (row) => <CustomStockEnabledCell row={row} />,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Affiliate Link",
			cell: (row) => <CustomAffiliateLinkCell row={row} />,
			sortable: false,
			maxWidth: "20%",
			wrap: true
		},
		{
			name: "Shopify Link",
			cell: (row) => <CustomShopifyLinkCell row={row} />,
			sortable: false,
			maxWidth: "10%"
		},
		{
			name: "Status",
			cell: (row) => <CustomStatusCell row={row} />,
			selector: (row) => row.isActive,
			sortable: true,
			maxWidth: "5%"
		},
		{
			name: "Action",
			cell: (row) => <CustomActions row={row} />,
			sortable: false,
			maxWidth: "20%"
		}
	];
	const [fullData, setfullData] = useState("");
	const [records, setRecords] = useState("");
	const [error, setError] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const showPasswordClick = (e) => {
		e.preventDefault();
		setShowPassword(!showPassword);
	};
	function handleFilter(e) {
		if (e.target.value.toLowerCase() !== "") {
			const filterData = records.filter((row) => {
				return row["firstName"]
					.toLowerCase()
					.includes(e.target.value.toLowerCase());
			});
			setRecords(filterData);
		} else {
			setRecords(fullData);
		}
	}

	const [formData, setFormData] = useState({
		email: "",
		firstName: "",
		lastName: "",
		shopify_link: "",
		password: "",
		affiliate_link: "",
		max_stock: 10,
		stock_enabled: true
	});
	const [editFormData, setEditFormData] = useState({
		userId: "",
		email: "",
		firstName: "",
		lastName: "",
		shopify_link: "",
		password: "",
		affiliate_link: "",
		max_stock: 10,
		stock_enabled: false
	});
	const isLinkExist = async (link) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getAffilateLinkExist`,
				{ affiliate_link: process.env.REACT_APP_URL + "?ref=" + link + "/" },
				config
			);
			if (response.data.isError === false) {
				return true;
			} else {
				return false;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	};
	const handleChange = async (e) => {
		//stock enable check
		if (typeof e === "boolean") {
			setFormData((prevData) => ({ ...prevData, stock_enabled: e }));
		} else {
			const { name, value } = e.target;
			console.log(name, value);
			// if (name === "affiliate_link") {
			// 	const res = await isLinkExist(value);
			// 	if (res) {
			// 		setError(true);
			// 	} else setError(false);
			// }
			setFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};
	const handleEditChange = async (e) => {
		if (typeof e === "boolean") {
			setEditFormData((prevData) => ({ ...prevData, stock_enabled: e }));
		} else {
			const { name, value } = e.target;
			// if (name === "affiliate_link") {
			// 	const res = await isLinkExist(value);
			// 	if (res) {
			// 		setError(true);
			// 	} else setError(false);
			// }
			setEditFormData((prevData) => ({ ...prevData, [name]: value }));
		}
	};
	const handleEditSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);
		try {
			const linkExist = await isLinkExist(editFormData.affiliate_link);
			if (editFormData.last_affiliate_link !== editFormData.affiliate_link) {
				if (linkExist) {
					setError(true);
					setLoading(false);
					return;
				} else setError(false);
			}
			const updatedFormData = {
				...editFormData,
				["affiliate_link"]:
					`${process.env.REACT_APP_URL}?ref=` +
					editFormData.affiliate_link.replace(/\//g, "") +
					"/"
			};
			delete updatedFormData["last_affiliate_link"];
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/update/affiliate_user`,
				updatedFormData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				// Reset the form if needed
				$("#editUser").modal("hide");
				fetchData();
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				setEditFormData({
					_id: "",
					email: "",
					firstName: "",
					lastName: "",
					shopify_link: "",
					password: "",
					affiliate_link: ""
				});
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	const handlePasswordSubmit = async (e) => {
		e.preventDefault();
		const sendData = {
			userId: editFormData.userId,
			password: editFormData.password
		};
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/update/affiliate_user_password`,
				sendData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				// Reset the form if needed
				$("#passwordEditUser").modal("hide");
				fetchData();
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				setEditFormData({
					_id: "",
					email: "",
					firstName: "",
					lastName: "",
					shopify_link: "",
					password: "",
					affiliate_link: ""
				});
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	const UserClick = async (e, id) => {
		setLoading(true);
		try {
			// Wait for fetchSingleData to complete
			await fetchSingleData(e);
			setLoading(false);
			let modal = $(`#${id}`);
			modal.modal("show");
		} catch (error) {
			setLoading(false);
			console.error("Error fetching single data:", error);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// Handle the error if needed
		}
	};
	const deleteUserClick = async (e) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#25E5C3",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/user/delete/affiliate_user`,
					{
						userId: e
					},
					config
				);
				if (response.data.isError === false) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: response.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
				fetchData();
			}
		});
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const linkExist = await isLinkExist(formData.affiliate_link);
			if (linkExist) {
				setError(true);
				setLoading(false);
				return;
			} else setError(false);
			const updatedFormData = {
				...formData,
				["affiliate_link"]:
					`${process.env.REACT_APP_URL}?ref=` +
					formData.affiliate_link.replace(/\//g, "") +
					"/"
			};

			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/create/affiliate_user`,
				updatedFormData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				// Reset the form if needed
				$("#addUser").modal("hide");
				fetchData();
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				setFormData({
					email: "",
					firstName: "",
					lastName: "",
					shopify_link: "",
					password: "",
					affiliate_link: ""
				});
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	const handleNewGenerate = () => {
		let url = process.env.REACT_APP_URL + "?ref=" + uuidv4();
		setFormData((prevData) => ({ ...prevData, ["affiliate_link"]: url }));
	};
	const handleEditNewGenerate = () => {
		let url = process.env.REACT_APP_URL + "?ref=" + uuidv4();
		setEditFormData((prevData) => ({ ...prevData, ["affiliate_link"]: url }));
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}

			<div className="header">
				<h2>Affiliate User Management</h2>
				<div className="btns">
					<button className="btn btn-outline">
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1Z"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.12 15V5"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 9.11L10.12 5L14.23 9.11"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Bulk Upload
					</button>
					<button
						className="btn btn-gradient"
						data-toggle="modal"
						data-target="#addUser"
					>
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5.13989V14.8599"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.8601 10H5.14014"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Add User
					</button>

					<div
						className="modal fade"
						id="addUser"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="modelTitleId"
						aria-hidden="true"
					>
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									{close_svg}
								</button>
								<div className="modal-body">
									<h2>Add New Affiliate User</h2>

									<form onSubmit={handleSubmit}>
										<div className="form-row">
											<div className="col-6">
												<div className="form-group">
													<label htmlFor="">First Name</label>
													<input
														type="text"
														className="form-control"
														name="firstName"
														value={formData.firstName}
														onChange={handleChange}
														required
													/>
												</div>
											</div>
											<div className="col-6">
												<div className="form-group">
													<label htmlFor="">Last Name</label>
													<input
														type="text"
														className="form-control"
														name="lastName"
														value={formData.lastName}
														onChange={handleChange}
														required
													/>
												</div>
											</div>
										</div>
										<div className="form-group">
											<label htmlFor="">Email</label>
											<input
												type="email"
												className="form-control"
												name="email"
												value={formData.email}
												onChange={handleChange}
												required
											/>
										</div>
										<div className="form-row">
											<div className="col-6">
												<div className="form-group">
													<label htmlFor="">Max Stock</label>
													<input
														type="number"
														className="form-control"
														name="max_stock"
														value={formData.max_stock}
														onChange={handleChange}
														min={1}
														required
													/>
												</div>
											</div>
											<div className="col-6">
												<label htmlFor="">Stock Enabled?</label>
												<div>
													<Switch
														name="stock_enabled"
														value={formData.stock_enabled}
														onChange={handleChange}
														checked={formData.stock_enabled}
														checkedIcon={false}
														uncheckedIcon={false}
														onColor={"#149CC4"}
													/>
												</div>
											</div>
										</div>

										<div className="form-group">
											<label htmlFor="">Shopify Link</label>
											<input
												type="url"
												className="form-control"
												name="shopify_link"
												placeholder="e.g. https://www.shopify.com/xyz"
												value={formData.shopify_link}
												onChange={handleChange}
												required
											/>
										</div>
										<div className="form-group">
											<div className="d-flex align-items-center justify-content-between">
												<label htmlFor="">Affiliate Link</label>
												{/* <div
													className="cursor-pointer"
													onClick={handleNewGenerate}
												>
													{generate_svg}
												</div> */}
											</div>
											<div className="group d-flex align-items-center justify-content-center">
												<div className="input-group">
													<div className="input-group-text">
														{process.env.REACT_APP_URL}?ref=
													</div>
												</div>
												<input
													type="text"
													className="form-control"
													name="affiliate_link"
													placeholder="e.g. xyz"
													value={formData.affiliate_link}
													onChange={handleChange}
													required
												/>
											</div>
											{error ? (
												<small className="text-danger">
													*Link already taken
												</small>
											) : (
												""
											)}
										</div>
										<div className="form-group">
											<label htmlFor="">Password</label>
											<div className="right-group d-flex align-items-center justify-content-center">
												<input
													type={showPassword ? "text" : "password"}
													className="form-control"
													name="password"
													value={formData.password}
													onChange={handleChange}
													autoComplete="current-password"
													required
												/>
												<div
													className={
														showPassword
															? "input-group cursor-pointer active"
															: "input-group cursor-pointer "
													}
													onClick={showPasswordClick}
												>
													<div className="input-group-text">{view_svg}</div>
												</div>
											</div>
										</div>

										<button className="btn btn-gradient">Save User</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="body">
				<div className="table">
					<div className="text-end">
						<input
							type="text"
							placeholder="Search by name"
							onChange={handleFilter}
						/>
					</div>

					<DataTable columns={columns} data={records} pagination></DataTable>
				</div>
			</div>

			{/* edit modal  */}
			<div
				className="modal fade"
				id="editUser"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>Edit User</h2>

							<form onSubmit={handleEditSubmit}>
								<div className="form-row">
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">First Name</label>
											<input
												type="text"
												className="form-control"
												name="firstName"
												value={editFormData.firstName}
												onChange={handleEditChange}
												required
											/>
										</div>
									</div>
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">Last Name</label>
											<input
												type="text"
												className="form-control"
												name="lastName"
												value={editFormData.lastName}
												onChange={handleEditChange}
												required
											/>
										</div>
									</div>
								</div>
								<div className="form-row">
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">Max Stock</label>
											<input
												type="number"
												className="form-control"
												name="max_stock"
												value={editFormData.max_stock}
												onChange={handleEditChange}
												min={1}
												required
											/>
										</div>
									</div>
									<div className="col-6">
										<label htmlFor="">Stock Enabled?</label>
										<div>
											<Switch
												name="stock_enabled"
												value={formData.stock_enabled}
												onChange={handleEditChange}
												checked={editFormData.stock_enabled}
												checkedIcon={false}
												uncheckedIcon={false}
												onColor={"#149CC4"}
											/>
										</div>
									</div>
								</div>

								<div className="form-group">
									<label htmlFor="">Shopify Link</label>
									<input
										type="url"
										className="form-control"
										name="shopify_link"
										placeholder="e.g. https://www.shopify.com/xyz"
										value={editFormData.shopify_link}
										onChange={handleEditChange}
										required
									/>
								</div>
								<div className="form-group">
									<div className="d-flex align-items-center justify-content-between">
										<label htmlFor="">Affiliate Link</label>
										{/* <div
											className="cursor-pointer"
											onClick={handleEditNewGenerate}
										>
											{generate_svg}
										</div> */}
									</div>
									<div className="group d-flex align-items-center justify-content-center">
										<div className="input-group">
											<div className="input-group-text">
												{process.env.REACT_APP_URL}?ref=
											</div>
										</div>
										<input
											type="text"
											className="form-control"
											name="affiliate_link"
											value={editFormData.affiliate_link}
											onChange={handleEditChange}
											required
										/>
									</div>
									{error ? (
										<small className="text-danger">*Link already taken</small>
									) : (
										""
									)}
								</div>
								<button className="btn btn-gradient">Save User</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* password modal  */}
			<div
				className="modal fade"
				id="passwordEditUser"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>Change Password</h2>

							<form onSubmit={handlePasswordSubmit}>
								<div className="form-group">
									<div className="right-group d-flex align-items-center justify-content-center">
										<input
											type={showPassword ? "text" : "password"}
											name="password"
											value={editFormData.password}
											onChange={handleEditChange}
											className="form-control"
										/>
										<div
											className={
												showPassword
													? "input-group cursor-pointer active"
													: "input-group cursor-pointer "
											}
											onClick={showPasswordClick}
										>
											<div className="input-group-text">{view_svg}</div>
										</div>
									</div>
								</div>

								<button className="btn btn-gradient">Save New Password</button>
							</form>
						</div>
					</div>
				</div>
			</div>
			{/* view modal */}
			<div
				className="modal fade"
				id="viewUser"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>View Affiliate User</h2>

							<form onSubmit={handleEditSubmit}>
								<div className="form-row">
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">First Name</label>
											<input
												type="text"
												className="form-control"
												name="firstName"
												value={editFormData.firstName}
												onChange={handleEditChange}
												disabled
											/>
										</div>
									</div>
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">Last Name</label>
											<input
												type="text"
												className="form-control"
												name="lastName"
												value={editFormData.lastName}
												onChange={handleEditChange}
												disabled
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="">Email</label>
									<input
										type="email"
										className="form-control"
										name="email"
										value={editFormData.email}
										onChange={handleEditChange}
										disabled
									/>
								</div>
								<div className="form-row">
									<div className="col-6">
										<div className="form-group">
											<label htmlFor="">Max Stock</label>
											<input
												type="number"
												className="form-control"
												name="max_stock"
												value={editFormData.max_stock}
												onChange={handleEditChange}
												min={1}
												required
											/>
										</div>
									</div>
									<div className="col-6">
										<label htmlFor="">Stock Enabled?</label>
										<div>
											<Switch
												name="stock_enabled"
												value={formData.stock_enabled}
												onChange={handleEditChange}
												checked={editFormData.stock_enabled}
												checkedIcon={false}
												uncheckedIcon={false}
												onColor={"#149CC4"}
												disabled={true}
											/>
										</div>
									</div>
								</div>
								<div className="form-group">
									<label htmlFor="">Shopify Link</label>
									<input
										type="url"
										className="form-control"
										name="shopify_link"
										placeholder="e.g. https://www.shopify.com/xyz"
										value={editFormData.shopify_link}
										onChange={handleEditChange}
										disabled
									/>
								</div>
								<div className="form-group">
									<label htmlFor="">Affiliate Link</label>
									<input
										type="url"
										className="form-control"
										name="affiliate_link"
										placeholder="e.g. https://www.brandbldr.io?id=123"
										value={
											process.env.REACT_APP_URL +
											"?ref=" +
											editFormData.affiliate_link +
											"/"
										}
										onChange={handleEditChange}
										disabled
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default UserManagement;
