import React, { Component } from "react";
import addFile_img from "../images/add-file.png";
import logo_img from "../images/logo.png";
import next_img from "../images/next.png";
import axios from "axios";

class PickLogo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSkipChecked: false, // Initial state
			isFileChecked: false // Initial state
		};
	}

	continue = (e) => {
		e.preventDefault();
		this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { errorFileMessage, handleData } = this.props;

		const onSelectImageHandler = (e) => {
			// alert(this.state.isChecked);
			var files = e.target.files;
			handleData({
				file: files[0],
				skip_logo: false
			});
			if (this.props.storeData.skip_logo) {
				onCheckBoxHandler2(e);
			}
		};

		const onCheckBoxHandler2 = (e) => {
			this.setState(
				(prevState) => ({
					isChecked: !prevState.isChecked
				}),
				() => {
					const { isChecked } = this.state;
					if (isChecked) {
						handleData({
							skip_logo: true
						});
					} else {
						handleData({
							skip_logo: false
						});
					}
				}
			);
		};

		const onCheckBoxHandler = (e) => {
			handleData({
				file: ""
			});

			this.setState(
				(prevState) => ({
					isChecked: !prevState.isChecked
				}),
				() => {
					const { isChecked } = this.state;
					if (isChecked) {
						handleData({
							skip_logo: true
						});
					} else {
						handleData({
							skip_logo: false
						});
					}
				}
			);
		};

		const triggerCheckBoxHandler = (e) => {
			onCheckBoxHandler(e);
		};

		const fileUploadSubmit = (e) => {
			e.preventDefault();

			var result = new Promise((resolve, reject) => {
				const formData = new FormData();
				// formData.append("access_token", this.state.access_token);
				formData.append("file", this.props.storeData.file);
				// formData.append("storename", this.state.storename);
				// formData.append("theme_id", this.state.theme_id);

				const config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};

				axios
					.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/logo`,
						formData,
						config
					)
					.then((response) => {
						if (response.data.status === "error") {
							resolve(response.data);
						} else {
							resolve(response.data);
						}
					})
					.catch((error) => {
						this.setState({
							submitError: error
						});
						reject(error); // Reject the promise on axios request error
					});
			});

			console.log(result);
		};

		if (this.props.storeData.skip_logo) {
			var myclassName_3 = "option-box-1 input-checkbox pro_select";
		} else {
			myclassName_3 = "option-box-1 input-checkbox";
		}

		if (this.props.storeData.file) {
			var myclassName_1 = "option-box-1 pro_select";
		} else {
			myclassName_1 = "option-box-1";
		}

		return (
			<>
				<label className="">Choose Store Logo</label>

				<div className="form-row so-logo-box mb-0">
					<div className="col-4">
						<div className={myclassName_1 + " w-100"}>
							<label for="storeLogo">
								{/* <img src={addFile_img} alt="add file" />  */}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
								>
									<path
										d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
										stroke="#069399"
										stroke-miterlimit="10"
									/>
									<path
										d="M16 9.68005V22.3201"
										stroke="#069399"
										stroke-miterlimit="10"
									/>
									<path
										d="M22.3197 16H9.67969"
										stroke="#069399"
										stroke-miterlimit="10"
									/>
								</svg>
								Add File
							</label>
							<input
								type="file"
								id="storeLogo"
								name="storeLogo"
								onChange={(e) => onSelectImageHandler(e)}
								className="form-group__input"
							/>
						</div>
					</div>
					<div className="col-4">
						<div className="option-box-1 w-100">
							<a
								rel="noopener noreferrer"
								className=""
								href="https://turbologo.com/"
								target="_blank"
							>
								{/* <img src={logo_img} alt="add file" /> */}
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="32"
									height="32"
									viewBox="0 0 32 32"
									fill="none"
								>
									<path
										d="M4.85 5.97C2.46 8.62 1 12.14 1 16C1 24.28 7.72 31 16 31C24.28 31 31 24.28 31 16C31 7.72 24.28 1 16 1V18.18"
										stroke="#069399"
										stroke-miterlimit="10"
									/>
									<path
										d="M11.91 14.86L12.64 14.18L16 17.79L19.36 14.18L20.09 14.86L16 19.26L11.91 14.86Z"
										fill="#069399"
									/>
								</svg>
								Generate Logo
							</a>
						</div>
					</div>
					<div className="col-4 ">
						<div
							className={myclassName_3 + " w-100"}
							onClick={(e) => triggerCheckBoxHandler(e)}
						>
							{/* <img src={next_img} alt="add file" /> */}
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
							>
								<path
									d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z"
									stroke="#069399"
									stroke-miterlimit="10"
								/>
								<path
									d="M10.71 9.68005L20.29 16.0001L10.71 22.3201V9.68005Z"
									stroke="#069399"
									stroke-miterlimit="10"
								/>
								<path
									d="M21.29 9.68005V22.3201"
									stroke="#069399"
									stroke-miterlimit="10"
								/>
							</svg>
							<div className="d-flex">
								<label for="skipLogo" className="skip-title">
									<input
										type="checkbox"
										value=""
										name="skip_logo"
										className="form-group"
										onChange={(e) => onCheckBoxHandler(e)}
										checked={this.props.storeData.skip_logo}
									/>
									Skip For Now
								</label>
							</div>
						</div>
					</div>
					<p className="error">{errorFileMessage}</p>
				</div>
				{/* <button onClick={(e) => fileUploadSubmit(e)}> submit </button> */}

				{/* <div className="Add_file_div">
          <label className="form-group__label" for="storeLogo">
            <span className="material-symbols-outlined">add</span>
            Add File
          </label> */}
				{/* <input type='file' value={pickLogo} name='storeLogo' onChange={handleChange('pickLogo')} className='form-group__input'/> */}
				{/* <input
            type="file"
            id="storeLogo"
            name="storeLogo"
            onChange={(e) => onSelectImageHandler(e.target.files)}
            className="form-group__input"
          />
        </div> */}
			</>
		);
	}
}

export default PickLogo;
