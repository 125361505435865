import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import warningIcon from '../../images/warning-icon.png';
import axios from "axios";
const AccountExpiredPopup = (props)=>{
    useEffect(() => {
        if(props.isOpen){
            document.body.style.overflow = "hidden";
            setOpen(true);
        }
    }, [])
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const closeModal = () => {
		setOpen(false);
		document.body.style.overflow = "unset";
	};
    const handleClick = async(e)=>{
        e.preventDefault();
        setLoading(true);
        setError("");
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const response = await axios.post(
                `${process.env.REACT_APP_FRONTEND_URL}api/acounts/transferStore`,
                { userEmail: props.email },
                config
            );
            if (response.data.status == "success") {
                props.handleData({
                    username: response.data.data.storeEmail,
                    password: response.data.data.storePassword,
                    shop_link: response.data.data.storeUrl,
                    storename: response.data.data.storeName,
                    access_token: response.data.data.access_token
                });
                setLoading(false);
                closeModal();
            } else {
                setLoading(false);
                setError(response.data.message);
            }
        } catch (e) {
            setLoading(false);
            setError(e.data.message);
        }
    }
    return (
        <Popup open={open} onClose={closeModal} modal closeOnDocumentClick>
				<div className="expired-popup">
                    <img src={warningIcon} alt="warning" className="img-fluid" />
					<h4>
						Previous Account has been expired!
					</h4>
					<p>Don't worry we have made a new account for you.</p>
                    <button className="btn btn-continue" onClick={handleClick} disabled={loading}>{loading ? (<div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>) : 'Continue'}</button>
                    <p className="text-danger mt-3">{error == "" ? '' : error}</p>
				</div>
		</Popup>
    );
}
export default AccountExpiredPopup;