import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
function UserHome() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const stats = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getCurrentUser`,
				config
			);
			if (stats.data.isError === false) {
				setAffiliateLink(stats.data.data[0].affiliate_link);
				setAffiliateClicks(stats.data.data[0].stats.affiliate_clicks);
				setShopifyClicks(stats.data.data[0].stats.shopify_clicks);
				setConvertedStores(stats.data.data[0].stats.converted_stores);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};

	const [affiliateLink, setAffiliateLink] = useState("");
	const [affiliateClicks, setAffiliateClicks] = useState(0);
	const [shopifyClicks, setShopifyClicks] = useState(0);
	const [convertedStores, setConvertedStores] = useState(0);

	const affiliate_svg = (
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 47 47"
			fill="#fff"
		>
			<g>
				<g id="Layer_1_110_">
					<g>
						<path
							d="M17.567,15.938l-2.859-2.702c0.333-0.605,0.539-1.29,0.539-2.029c0-2.342-1.897-4.239-4.24-4.239
				c-2.343,0-4.243,1.896-4.243,4.239c0,2.343,1.9,4.241,4.243,4.241c0.826,0,1.59-0.246,2.242-0.654l2.855,2.699
				C16.536,16.922,17.023,16.399,17.567,15.938z"
						/>
						<path
							d="M29.66,15.6l3.799-6.393c0.374,0.107,0.762,0.184,1.169,0.184c2.347,0,4.244-1.898,4.244-4.241
				c0-2.342-1.897-4.239-4.244-4.239c-2.343,0-4.239,1.896-4.239,4.239c0,1.163,0.469,2.214,1.227,2.981l-3.787,6.375
				C28.48,14.801,29.094,15.169,29.66,15.6z"
						/>
						<path
							d="M42.762,20.952c-1.824,0-3.369,1.159-3.968,2.775l-5.278-0.521c0,0.04,0.006,0.078,0.006,0.117
				c0,0.688-0.076,1.36-0.213,2.009l5.276,0.521c0.319,2.024,2.062,3.576,4.177,3.576c2.342,0,4.238-1.896,4.238-4.238
				C47,22.85,45.104,20.952,42.762,20.952z"
						/>
						<path
							d="M28.197,37.624l-1.18-5.156c-0.666,0.232-1.359,0.398-2.082,0.481l1.182,5.157c-1.355,0.709-2.29,2.11-2.29,3.746
				c0,2.342,1.896,4.237,4.243,4.237c2.342,0,4.238-1.896,4.238-4.237C32.311,39.553,30.479,37.692,28.197,37.624z"
						/>
						<path
							d="M14.357,25.37l-6.57,2.201c-0.758-1.158-2.063-1.926-3.548-1.926C1.896,25.645,0,27.542,0,29.884
				c0,2.345,1.896,4.242,4.239,4.242c2.341,0,4.242-1.897,4.242-4.242c0-0.098-0.021-0.188-0.029-0.284l6.591-2.207
				C14.746,26.752,14.51,26.077,14.357,25.37z"
						/>
						<circle cx="23.83" cy="23.323" r="7.271" />
					</g>
				</g>
			</g>
		</svg>
	);
	const shop_svg = (
		<svg
			fill="#fff"
			width="32"
			height="32"
			viewBox="-1.52 0 122.88 122.88"
			version="1.1"
			id="Layer_1"
		>
			<g>
				<path
					className="st0"
					d="M20.57,0h79.69c1.87,0,3.39,1.53,3.39,3.4s-1.53,3.4-3.39,3.4l-79.69,0c-1.87,0-3.39-1.53-3.39-3.4 C17.17,1.53,18.7,0,20.57,0L20.57,0z M118.58,43.17v4.1c0.04,0.39,0.06,0.78,0.06,1.17c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88l-0.57,0c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.57c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.36c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.34,0.02-0.67,0.05-1v-4.27L118.58,43.17L118.58,43.17z M16.84,10.44h87.64l11.17,23.85H5.26 L16.84,10.44L16.84,10.44z M3.39,108.01h12.12v-38.3c0.2,0.01,0.4,0.02,0.6,0.02l0.05,0l0.23,0h0.04l0.03,0h0.16l0.03,0h0.14v0.03 h0.01v-0.03c5.48,0,10.55-1.75,14.5-4.65l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0l0.1,0.07l0,0l0.03,0.02l0.01,0.01l0.05,0.03l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02L32,65.59l0.03,0.02l0.02,0.02l0,0l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.01,0l0.23,0.15l0.03,0.02l0.03,0.02l0.18,0.12l0.03,0.02l0.22,0.13L33,66.22l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.03,0.02l0.03,0.02v25.1l53.38,0V66.93c0.86-0.45,1.7-0.96,2.49-1.51l0.02-0.02l0.01,0l0.16-0.11l0.01-0.01l0.05-0.03l0.05-0.03 l0,0l0.08-0.06l0.01,0l0.01-0.01l0.17-0.12c3.97,2.95,9.08,4.73,14.61,4.73v0.03l0.01,0v-0.03h0.03v38.27h12.12 c1.87,0,3.4,3.34,3.4,7.43c0,4.09-1.53,7.43-3.4,7.43H3.39c-1.86,0-3.39-3.34-3.39-7.43C0,111.36,1.53,108.01,3.39,108.01 L3.39,108.01z"
				/>
			</g>
		</svg>
	);
	const click_svg = (
		<svg
			width="32"
			height="32"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="#fff"
				d="M459.15 269.75a133.197 133.197 0 0 1-55.862 179.975l-42.782 22.541-10.52 5.533a71.277 71.277 0 0 1-62.966 1.685l-167.077-71.38 15.733-46.676 99.363 19.194-51.458-97.78-82.843-157.411 40.357-21.232 82.844 157.457 19.934-10.485-36.521-69.445 40.335-21.22 36.52 69.445 19.935-10.485-28.2-53.598 40.358-21.232 28.2 53.598 19.945-10.576-19.354-36.886 40.346-21.174 19.354 36.885 54.348 103.301zM73.268 146.674a60.03 60.03 0 0 1 42.361-102.459 60.098 60.098 0 0 1 56.58 80.169l10.588 20.013A78.29 78.29 0 0 0 115.708 26a78.233 78.233 0 0 0-5.635 156.262L99.428 162.02a59.688 59.688 0 0 1-26.16-15.346z"
			/>
		</svg>
	);
	const close_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M13.3596 0.640137L0.639648 13.3601"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3596 13.3601L0.639648 0.640137"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const copy_svg = (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g id="Edit / Copy">
				<path
					id="Vector"
					d="M9 9V6.2002C9 5.08009 9 4.51962 9.21799 4.0918C9.40973 3.71547 9.71547 3.40973 10.0918 3.21799C10.5196 3 11.0801 3 12.2002 3H17.8002C18.9203 3 19.4801 3 19.9079 3.21799C20.2842 3.40973 20.5905 3.71547 20.7822 4.0918C21.0002 4.51962 21.0002 5.07967 21.0002 6.19978V11.7998C21.0002 12.9199 21.0002 13.48 20.7822 13.9078C20.5905 14.2841 20.2839 14.5905 19.9076 14.7822C19.4802 15 18.921 15 17.8031 15H15M9 9H6.2002C5.08009 9 4.51962 9 4.0918 9.21799C3.71547 9.40973 3.40973 9.71547 3.21799 10.0918C3 10.5196 3 11.0801 3 12.2002V17.8002C3 18.9203 3 19.4801 3.21799 19.9079C3.40973 20.2842 3.71547 20.5905 4.0918 20.7822C4.5192 21 5.07899 21 6.19691 21H11.8036C12.9215 21 13.4805 21 13.9079 20.7822C14.2842 20.5905 14.5905 20.2839 14.7822 19.9076C15 19.4802 15 18.921 15 17.8031V15M9 9H11.8002C12.9203 9 13.4801 9 13.9079 9.21799C14.2842 9.40973 14.5905 9.71547 14.7822 10.0918C15 10.5192 15 11.079 15 12.1969L15 15"
					stroke="#fff"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</g>
		</svg>
	);
	const copyToClipboard = () => {
		navigator.clipboard
			.writeText(affiliateLink)
			.then(() => {
				// Handle successful copying here, like showing a message
				console.log("Text copied to clipboard!");
			})
			.catch((err) => {
				// Handle errors here, such as if the user denies clipboard permissions
				console.error("Failed to copy text: ", err);
			});
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Dashboard</h2>
				<div className="btns">
					<button
						type="button"
						className="btn btn-gradient"
						data-toggle="modal"
						data-target="#affLink"
					>
						Get My Affiliate Link
					</button>

					<div
						className="modal fade"
						id="affLink"
						tabindex="-1"
						role="dialog"
						aria-labelledby="modelTitleId"
						aria-hidden="true"
					>
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									{close_svg}
								</button>
								<div className="modal-body">
									<h2>Copy Affiliate Link</h2>
									<div className="form-group affiliate-group">
										<div class="input-group mb-2">
											<input
												type="text"
												class="form-control"
												id="inlineFormInputGroup"
												placeholder="Affiliate Link"
												value={affiliateLink}
												disabled
											/>
											<div
												class="input-group-prepend cursor-pointer"
												onClick={copyToClipboard}
											>
												<div class="input-group-text">{copy_svg}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="body mt-4">
				<div className="form-row mt-2">
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{affiliate_svg}</div>
								<div className="text">Total Affiliate Clicks</div>
							</div>
							<div className="right">
								<div className="number">{affiliateClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{click_svg}</div>
								<div className="text">Total Shopify Clicks</div>
							</div>
							<div className="right">
								<div className="number">{shopifyClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{shop_svg}</div>
								<div className="text">Total Converted Stores</div>
							</div>
							<div className="right">
								<div className="number">{convertedStores}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default UserHome;
