import React, { Component } from "react";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import StepperCompo from "./Stepper/StepperCompo";
import ColorComponent from "./ColorComponent";
import PickLogo from "./PickLogo";
import StoreDetails from "./StoreDetails";
import GuidLinePopup from "./Popup/GuidLinePopup";
import AccessStorePopup from "./Popup/AccessStorePopup";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
class BusinessDetails extends Component {
	constructor(props) {
		super(props);
		const defaultColor = ["#003a6b", "#a8a8a8"];
		this.props.handleData({ theme_color: defaultColor[0] });
		this.props.handleData({ font_color: defaultColor[1] });

		this.state = {
			errorFontMessage: "",
			errorEmailMessage: "",
			// errorFileMessage: "",
			errorTransferApi: "",
			errorSelectOption: "",
			selectedOptions: [],
			submitError: "",
			isLoading: false,
			swal: {}
		};
	}
	componentDidMount() {
		const trackId = localStorage.getItem("page_track_id");
		this.pageTracker(trackId);
    }
	async pageTracker(trackId) {
        try {
			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};
			const formData = new FormData();
			if(trackId === null || trackId ==="")
            // Fetch client IP
            {
				const ipResponse = await axios.get('https://api.ipify.org?format=json');
				const ip = ipResponse.data.ip;

				// Fetch client country using the IP
				const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
				const country = countryResponse.data.country_name;

				// Determine the device type
				let deviceType = 'Unknown';
				if (isMobile) {
					deviceType = 'Mobile';
				} else if (isTablet) {
					deviceType = 'Tablet';
				} else if (isBrowser) {
					deviceType = 'Browser';
				}
				// Storing Data
				
				formData.append("ip", ip);
				formData.append("device", deviceType);
				formData.append("country", country);
			}else{
				formData.append("track_id", trackId);
			}
			formData.append("landing_page_count", 0);
			formData.append("step_1_count", 1);
			formData.append("step_2_count", 0);
			formData.append("built_store_count", 0);
            const pageTrackerResponse = await axios.post(
                `${process.env.REACT_APP_FRONTEND_URL}api/page-tracker`,
                formData,
                config
            );
            if (pageTrackerResponse.data.success === true) {
                localStorage.setItem("page_track_id", pageTrackerResponse.data.data._id);
            }
        } catch (error) {
            console.error('Error Page Track info:', error);
        }
    }
	validateEmail = (email) => {
		// Regular expression for email validation
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailPattern.test(email);
	};

	render() {
		const {
			businessname,
			handleChange,
			handleData,
			validateBusinessName,
			isErrorBusinessName,
			errorMessageBusinessName,
			storeData,
			product_display,
			email,
			isMailAvail,
			mailId,
		} = this.props;
		const updateMailStatus = async()=>{
			if(mailId !== undefined){
				const config = {
					headers: {
						"Content-Type": "application/json"
					}
				};
				const formData = new FormData();
				formData.append("email", email);
				
				formData.append("userId", mailId);
				formData.append("storeStatus", 'creds_transferred');
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/update/userEmailStatus`,
					formData,
					config
				);
				if (response.data.isError === false) {
					return true;
				} else {
					return false;
				}
			}
		}
		const addUserSteps = async(userEmail, storeEmail, themeColor, businessName, storeProducts) =>{
			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/add/userStoreSteps`,
				{ 
					user_email: userEmail,
					store_email: storeEmail,
					themeColor: themeColor,
					business_name: businessName,
					store_products: storeProducts
				 },
				config
			);
			if(response.data.isError===false){
				return true;
			}else{	
				this.setState({
					errorTransferApi: response.data.message
				});
				return false;
			}
		}
		const continue_step = async (e) => {
			e.preventDefault();
			if (this.state.isLoading) return;
			this.setState({ isLoading: true });
			const isBusinessNameValid = this.props.validateBusinessName();
			// const isValid = this.props.storeData.fontchoices;
			let isEmail = this.props.storeData.email;
			let isCategories = true;
			// var isfile = this.props.storeData.file;
			// const isSkipfile = this.props.storeData.skip_logo;

			// if (isSkipfile) {
			// 	isfile = true;
			// }

			if (this.validateEmail(isEmail)) {
				isEmail = true;
			} else {
				isEmail = false;
			}
			if (this.state.selectedOptions.length < 1) {
				this.setState({
					errorSelectOption: "Atleast one category is required."
				});
				isCategories = false;
			}
			// if (isBusinessNameValid && isValid && isEmail === true && isfile) {
			if (isEmail === true && isCategories && isBusinessNameValid) {
				this.setState({ errorEmailMessage: "" });
				this.setState({ errorSelectOption: "" });
				const config = {
					headers: {
						"Content-Type": "application/json"
					}
				};
				try {
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/acounts/transferStore`,
						{ userEmail: this.props.email },
						config
					);
					if (response.data.status == "success") {
						handleData({
							username: response.data.data.storeEmail,
							password: response.data.data.storePassword,
							shop_link: response.data.data.storeUrl,
							storename: response.data.data.storeName,
							access_token: response.data.data.access_token
						});
						const storeUserSteps = await addUserSteps(this.props.storeData.email, response.data.data.storeEmail, this.props.storeData.theme_color, this.props.storeData.businessname, this.props.storeData.selectedOptions);
						if(storeUserSteps !== true){
							this.setState({ isLoading: false });
							return;
						}
						await updateMailStatus();
						this.setState({ isLoading: false });
						this.props.nextStep();
					} else {
						this.setState({
							errorTransferApi: response.data.message
						});
						this.setState({ isLoading: false });
					}
				} catch (e) {
					this.setState({ isLoading: false });
					this.setState({
						errorTransferApi: e.data.message
					});
				}
			}else{
				this.setState({ isLoading: false });
			}

			// if (!isValid) {
			// 	this.setState({ errorFontMessage: "Please Choose Font." });
			// }

			if (!isEmail) {
				this.setState({
					errorEmailMessage:
						"Email is not valid. Please enter a valid email address."
				});
			}else{
				this.setState({ isLoading: false });
			}

			// if (!isfile) {
			// 	this.setState({ errorFileMessage: "Please Upload Your Store Logo." });
			// }
		};
		// const defaultColor = ["#003a6b","#a8a8a8"];
		const defaultColor = ["#FFFFFF", "#003a6b"];

		const handleFontChange = (e) => {
			if (e.target.value) {
				this.setState({ errorFontMessage: "" });
			}

			this.setState({ fontchoices: e.target.value });
			handleData({ fontchoices: e.target.value });
		};

		const themeColorArr = [
			"#000000",
			"#BFFF00",
			"#2E8B57",
			"#FFA500",
			"#A020F0",
			"#FF4500",
			"#D20970",
			"#FFFFFF"
		];
		const fontColorArr = [
			"#0000FF",
			"#000000",
			"#00FFFF",
			"#00FF00",
			"#2E8B57",
			"#FF00FF",
			"#FFA500",
			"#A020F0",
			"#FF0000",
			"#FF4500",
			"#EE82EE",
			"#FFFFFF",
			"#FFFF00"
		];
		const handleCheckboxChange = (option)=>{
			const { selectedOptions } = this.state;
			const optionIndex = selectedOptions.indexOf(option);

			if (optionIndex > -1) {
				// If the option is already selected, remove it
				selectedOptions.splice(optionIndex, 1);
				handleData({
					selectedOptions: selectedOptions
				});
			} else {
				// If the option is not selected, add it
				if (selectedOptions.length < 4) {
					selectedOptions.push(option);
					handleData({
						selectedOptions: selectedOptions
						});
				} else {
					this.setState({ errorSelectOption: 'You can only select up to 4 options' });
					return; // Exit the function to prevent further updates
				}
			}

			this.setState({
			selectedOptions,
			errorSelectOption: ''
			});
		}
		const onSelect = (selectedList, selectedItem) => {
			if (selectedList.length <= 4) {
				this.setState({
					selectedOptions: selectedList,
					errorSelectOption: ""
				});

				handleData({
					selectedOptions: selectedList
				});
			} else {
				this.setState({
					errorSelectOption: "You can only select up to 4 Category."
				});
			}
		};

		// Callback function to handle unselection change
		const onRemove = (selectedList, removedItem) => {
			this.setState({ selectedOptions: selectedList, errorSelectOption: "" });
			handleData({
				selectedOptions: selectedList
			});
		};
		const productCategoryObj = [
			{
				name: "Baby Products",
				value: "Baby Products"
			},
			{
				name: "Childcare",
				value: "Childcare Niche"
			},
			{
				name: "Home Decor",
				value: "Home Decor Niche"
			},
			{
				name: "Pet Supplies",
				value: "Pet Supplies Niche"
			},
			{
				name: "Tech and Gadgets",
				value: "Tech and Gadgets Niche"
			},
			{
				name: "Health & Wellness",
				value: "Health & Wellness Niche"
			},
			{
				name: "Beauty and Skincare",
				value: "Beauty and Skincare Niche"
			},
			{
				name: "Fashion and Accessories",
				value: "Fashion and Accessories Niche"
			},
			{
				name: "Travel and Outdoor Adventure",
				value: "Travel and Outdoor Adventure Niche"
			},
			{
				name: "Home Office and Productivity",
				value: "Home Office and Productivity Niche"
			},
		];
		return (
			<div className="so-form">
				<form>
					<StepperCompo count={0} />

					<h4>Store Builder Form</h4>
					<div className="form-row">
						<div className={!isMailAvail?'col-md-6':'col-md-12'}>
							<div className="div_business">
								<label className="form-group__label">Enter Your Name</label>
								<input
									type="text"
									value={businessname}
									name="first name"
									onChange={handleChange("businessname")}
									onBlur={validateBusinessName}
									className="form-group__input"
								/>
								<p className="error">
									{isErrorBusinessName && errorMessageBusinessName}
								</p>
							</div>
						</div>
						{!isMailAvail ? (<div className="col-md-6">
							<div className="div_business so-mt-sm">
								<label className="form-group__label">
									{/*	<div className="d-flex align-items-start mini-head">
										<span className="number">1</span>
										<div className="text">
											<div className="text">Enter Email Address</div>
											<div>
												<span className="guidline"> */}
									{/* Please enter a valid email */}
									Enter Email Address
									{/* </span>
											</div>
										</div>
									</div> */}
								</label>
								{/* <label className="form-group__label"></label> */}
								<input
									type="text"
									value={email}
									name="email"
									onChange={handleChange("email")}
									className="form-group__input"
								/>
								<p className="error">{this.state.errorEmailMessage}</p>
							</div>
						</div>) : ""}
						
					</div>
					<div className="form-row">
						<div className="col-md-6">
							<ColorComponent
								headingName="Choose Theme Color"
								colorFor="theme_color"
								handleData={handleData}
								defaultColor={defaultColor}
								storeData={storeData}
								colorArray={themeColorArr}
							/>
						</div>
						<div className="col-md-6">
							<div className="div_business select-category">
								<label className="form-group__label">
									<div className="d-flex align-items-start mini-head">
										<div className="text">
											<div className="text">Select Product Category</div>
											<div>
												<span className="guidline">Max selection up to 4</span>
											</div>
										</div>
									</div>
								</label> 
								<div className="so-category-selection">
									{productCategoryObj.map((option, index) => (
										<div key={index}>
											<label htmlFor={`checkbox-${index}`} className={this.state.selectedOptions.includes(option.value)? 'active' : ''}>
											<input
											type="checkbox"
											id={`checkbox-${index}`}
											value={option}
											checked={this.state.selectedOptions.includes(option.value)}
											onChange={() => handleCheckboxChange(option.value)}
											/>
											{option.name}</label>
										</div>
									))}
								</div>
								{/* <Multiselect
									options={productCategoryObj}
									isObject={false} // Set to false because options are strings
									onSelect={onSelect}
									onRemove={onRemove}
									selectedValues={this.state.selectedOptions}
									displayValue="Product Categories"
									selectionLimit="4"
									placeholder="Select Product Category"
								/> */}
								<p className="error mt--1">{this.state.errorSelectOption}</p>
							</div>
						</div>
					</div>
					{/* <div className="form-row">
						<div className="col-12">
							<PickLogo
								handleChange={handleChange}
								errorFileMessage={this.state.errorFileMessage}
								handleData={handleData}
								storeData={storeData}
							/>
						</div>
					</div> */}
					{/* <div className="form-row">
						<div className="col-md-6">
							<ColorComponent
								headingName="Choose Theme Color"
								colorFor="theme_color"
								handleData={handleData}
								defaultColor={defaultColor}
								storeData={storeData}
								colorArray={themeColorArr}
							/>
						</div>
						<div className="col-md-6">
							<div className="form-group__element font_selector_dropdown">
								<label className="form-group__label">Choose Font</label>
								<select
									id="input-font"
									name="fontchoices"
									className="form-group__input"
									onChange={(e) => handleFontChange(e)}
									value={this.props.storeData.fontchoices}
								>
									<option value=""> -- Choose Font -- </option>
									<option value="Arial" selected>
										Arial
									</option>
									<option value="Algerian">Algerian</option>
									<option value="Berlin-sans-fb">Berlin Sans FB</option>
									<option value="Times-new-roman">Times New Roman</option>
									<option value="bold" className="bolder">
										Tebal
									</option>
								</select>
								<p className="error">{this.state.errorFontMessage}</p>
							</div>
						</div>
					</div> */}
					<div className="error mt-4 mb-0">{this.state.errorTransferApi}</div>
					<div className="next-button-div mt-2">
						<button
							className={
								this.state.isLoading
									? "buttons__button buttons__button--next btn_next disabled"
									: "buttons__button buttons__button--next btn_next"
							}
							onClick={continue_step}
							disabled={this.state.isLoading}
						>
							{this.state.isLoading ? (
								<div>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 100 100"
										preserveAspectRatio="xMidYMid"
										width="25"
										height="25"
									>
										<g>
											<circle
												strokeDasharray="164.93361431346415 56.97787143782138"
												r="35"
												strokeWidth="10"
												stroke="#ffffff"
												fill="none"
												cy="50"
												cx="50"
											>
												<animateTransform
													keyTimes="0;1"
													values="0 50 50;360 50 50"
													dur="1s"
													repeatCount="indefinite"
													type="rotate"
													attributeName="transform"
												></animateTransform>
											</circle>
											<g></g>
										</g>
									</svg>
								</div>
							) : (
								<span>
									Next{" "}
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="12"
										viewBox="0 0 20 12"
										fill="none"
									>
										<path
											d="M0 6H18.3333"
											stroke="white"
											strokeWidth="2"
											stroke-miterlimit="10"
										/>
										<path
											d="M13.3336 1L18.3336 6L13.3336 11"
											stroke="white"
											strokeWidth="2"
											stroke-miterlimit="10"
										/>
									</svg>
								</span>
							)}
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default BusinessDetails;
