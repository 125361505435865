import React from "react";
import $ from "jquery";
const dashboard_svg = (
	<svg
		width="22"
		height="20"
		viewBox="0 0 22 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.9995 13.2727C12.2547 13.2727 13.2723 12.2552 13.2723 11C13.2723 9.74483 12.2547 8.72729 10.9995 8.72729C9.74434 8.72729 8.72681 9.74483 8.72681 11C8.72681 12.2552 9.74434 13.2727 10.9995 13.2727Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 11H4.50909"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.4905 11H20.9996"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11 1V4.50909"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.6091 9.39086L15.8 6.19995"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.92749 3.92725L6.40931 6.40906"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.65455 18.7273H17.3455C19.5727 16.8909 21 14.1091 21 11C21 5.47273 16.5273 1 11 1C5.47273 1 1 5.47273 1 11C1 14.1091 2.42727 16.8909 4.65455 18.7273Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const pm_svg = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.38 18.14H1.53C1.24 18.14 1 17.9 1 17.61V1.53C1 1.24 1.24 1 1.53 1H20.47C20.76 1 21 1.24 21 1.53V17.61C21 17.9 20.76 18.14 20.47 18.14H18.59"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1 3.86011H21"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path d="M2.42993 2.42993H3.37993" stroke="white" strokeLinejoin="round" />
		<path d="M4.33008 2.42993H5.29008" stroke="white" strokeLinejoin="round" />
		<path
			d="M15.9301 14.8101C15.9301 14.4901 15.9001 14.1801 15.8401 13.8801L17.2001 12.9001L15.7901 10.5201L14.2401 11.1801C13.7601 10.7701 13.2101 10.4601 12.6001 10.2501L12.4101 8.61011H9.59006L9.41006 10.2501C8.80006 10.4501 8.24006 10.7801 7.77006 11.1801L6.22006 10.5201L4.81006 12.9001L6.17006 13.8801C6.11006 14.1801 6.08006 14.4901 6.08006 14.8101C6.08006 15.1301 6.11006 15.4401 6.17006 15.7401L4.81006 16.7201L6.22006 19.1001L7.77006 18.4401C8.25006 18.8501 8.80006 19.1701 9.42006 19.3701L9.60006 21.0001H12.4201L12.6101 19.3601C13.2201 19.1601 13.7801 18.8401 14.2501 18.4301L15.8001 19.0901L17.2101 16.7101L15.8501 15.7301C15.8901 15.4401 15.9301 15.1301 15.9301 14.8101Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.0599 14.81C13.0599 15.95 12.1399 16.87 10.9999 16.87C9.85994 16.87 8.93994 15.95 8.93994 14.81C8.93994 13.67 9.85994 12.75 10.9999 12.75C12.1399 12.75 13.0599 13.67 13.0599 14.81Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const um_svg = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M10.4778 15.7221C10.4778 15.7221 10.4444 15.1888 10.4 14.9332L11.5556 14.0999L10.3556 12.0666L9.03333 12.6332C8.62222 12.2888 8.15555 12.011 7.63333 11.8443L7.47778 10.4443H5.07778L4.92222 11.8443C4.4 12.0221 3.93333 12.2888 3.52222 12.6332L2.2 12.0666L1 14.0999L2.15556 14.9332C2.1 15.1888 2.07778 15.7221 2.07778 15.7221C2.07778 15.7221 2.11111 16.2554 2.15556 16.511L1 17.3443L2.2 19.3777L3.52222 18.811C3.93333 19.1554 4.4 19.4332 4.92222 19.5999L5.07778 20.9999H7.47778L7.63333 19.5999C8.15555 19.4221 8.62222 19.1554 9.03333 18.811L10.3556 19.3777L11.5556 17.3443L10.4 16.511C10.4556 16.2554 10.4778 15.7221 10.4778 15.7221Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.27777 17.4777C7.24734 17.4777 8.03333 16.6917 8.03333 15.7221C8.03333 14.7525 7.24734 13.9666 6.27777 13.9666C5.30821 13.9666 4.52222 14.7525 4.52222 15.7221C4.52222 16.6917 5.30821 17.4777 6.27777 17.4777Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0444 12.7444C17.0888 12.7444 20.9999 13.6111 20.9999 15.7888V19.6999C20.9999 20.4222 20.411 20.9999 19.6999 20.9999H12.3555"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0444 9.88889C11.8777 9.88889 10.1333 8.13333 10.1333 5.97778V4.91111C10.1333 2.74444 11.8889 1 14.0444 1C16.2111 1 17.9555 2.75556 17.9555 4.91111V5.97778C17.9555 8.13333 16.2 9.88889 14.0444 9.88889Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const accounts_svg = (
	<svg
		fill="#FFF"
		width="22"
		height="22"
		viewBox="0 0 32 32"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path d="M16 21.416c-5.035 0.022-9.243 3.537-10.326 8.247l-0.014 0.072c-0.018 0.080-0.029 0.172-0.029 0.266 0 0.69 0.56 1.25 1.25 1.25 0.596 0 1.095-0.418 1.22-0.976l0.002-0.008c0.825-3.658 4.047-6.35 7.897-6.35s7.073 2.692 7.887 6.297l0.010 0.054c0.127 0.566 0.625 0.982 1.221 0.982 0.69 0 1.25-0.559 1.25-1.25 0-0.095-0.011-0.187-0.031-0.276l0.002 0.008c-1.098-4.78-5.305-8.295-10.337-8.316h-0.002zM9.164 11.102c0 0 0 0 0 0 2.858 0 5.176-2.317 5.176-5.176s-2.317-5.176-5.176-5.176c-2.858 0-5.176 2.317-5.176 5.176v0c0.004 2.857 2.319 5.172 5.175 5.176h0zM9.164 3.25c0 0 0 0 0 0 1.478 0 2.676 1.198 2.676 2.676s-1.198 2.676-2.676 2.676c-1.478 0-2.676-1.198-2.676-2.676v0c0.002-1.477 1.199-2.674 2.676-2.676h0zM22.926 11.102c2.858 0 5.176-2.317 5.176-5.176s-2.317-5.176-5.176-5.176c-2.858 0-5.176 2.317-5.176 5.176v0c0.004 2.857 2.319 5.172 5.175 5.176h0zM22.926 3.25c1.478 0 2.676 1.198 2.676 2.676s-1.198 2.676-2.676 2.676c-1.478 0-2.676-1.198-2.676-2.676v0c0.002-1.477 1.199-2.674 2.676-2.676h0zM31.311 19.734c-0.864-4.111-4.46-7.154-8.767-7.154-0.395 0-0.784 0.026-1.165 0.075l0.045-0.005c-0.93-2.116-3.007-3.568-5.424-3.568-2.414 0-4.49 1.448-5.407 3.524l-0.015 0.038c-0.266-0.034-0.58-0.057-0.898-0.063l-0.009-0c-4.33 0.019-7.948 3.041-8.881 7.090l-0.012 0.062c-0.018 0.080-0.029 0.173-0.029 0.268 0 0.691 0.56 1.251 1.251 1.251 0.596 0 1.094-0.417 1.22-0.975l0.002-0.008c0.684-2.981 3.309-5.174 6.448-5.186h0.001c0.144 0 0.282 0.020 0.423 0.029 0.056 3.218 2.679 5.805 5.905 5.805 3.224 0 5.845-2.584 5.905-5.794l0-0.006c0.171-0.013 0.339-0.035 0.514-0.035 3.14 0.012 5.765 2.204 6.442 5.14l0.009 0.045c0.126 0.567 0.625 0.984 1.221 0.984 0.69 0 1.249-0.559 1.249-1.249 0-0.094-0.010-0.186-0.030-0.274l0.002 0.008zM16 18.416c-0 0-0 0-0.001 0-1.887 0-3.417-1.53-3.417-3.417s1.53-3.417 3.417-3.417c1.887 0 3.417 1.53 3.417 3.417 0 0 0 0 0 0.001v-0c-0.003 1.886-1.53 3.413-3.416 3.416h-0z"></path>
	</svg>
);
const email_svg = (
	<svg
		viewBox="0 0 24 24"
		width="22"
		height="22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g id="style=stroke">
			<g id="email">
				<path
					id="vector (Stroke)"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.88534 5.2371C3.20538 5.86848 2.75 6.89295 2.75 8.5V15.5C2.75 17.107 3.20538 18.1315 3.88534 18.7629C4.57535 19.4036 5.61497 19.75 7 19.75H17C18.385 19.75 19.4246 19.4036 20.1147 18.7629C20.7946 18.1315 21.25 17.107 21.25 15.5V8.5C21.25 6.89295 20.7946 5.86848 20.1147 5.2371C19.4246 4.59637 18.385 4.25 17 4.25H7C5.61497 4.25 4.57535 4.59637 3.88534 5.2371ZM2.86466 4.1379C3.92465 3.15363 5.38503 2.75 7 2.75H17C18.615 2.75 20.0754 3.15363 21.1353 4.1379C22.2054 5.13152 22.75 6.60705 22.75 8.5V15.5C22.75 17.393 22.2054 18.8685 21.1353 19.8621C20.0754 20.8464 18.615 21.25 17 21.25H7C5.38503 21.25 3.92465 20.8464 2.86466 19.8621C1.79462 18.8685 1.25 17.393 1.25 15.5V8.5C1.25 6.60705 1.79462 5.13152 2.86466 4.1379Z"
					fill="#fff"
				/>
				<path
					id="vector (Stroke)_2"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M19.3633 7.31026C19.6166 7.63802 19.5562 8.10904 19.2285 8.3623L13.6814 12.6486C12.691 13.4138 11.3089 13.4138 10.3185 12.6486L4.77144 8.3623C4.44367 8.10904 4.38328 7.63802 4.63655 7.31026C4.88982 6.98249 5.36083 6.9221 5.6886 7.17537L11.2356 11.4616C11.6858 11.8095 12.3141 11.8095 12.7642 11.4616L18.3113 7.17537C18.6391 6.9221 19.1101 6.98249 19.3633 7.31026Z"
					fill="#fff"
				/>
			</g>
		</g>
	</svg>
);
const setting_svg = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21 12.7471V9.264L18.8222 8.83139C18.6333 8.15474 18.3 7.68885 17.9556 7.08985L19.2556 5.34831L16.6444 2.74154L14.9 4.03938C14.3 3.69551 13.8333 3.35164 13.1556 3.17415L12.7222 1H9.25556L8.82222 3.19634C8.14444 3.37382 7.67778 3.71769 7.08889 4.06156L5.34444 2.76373L2.74444 5.3594L4.04444 7.10094C3.72222 7.71104 3.36667 8.18802 3.17778 8.83139L1 9.264V12.736L3.17778 13.1686C3.4 13.8453 3.7 14.3111 4.04444 14.9102L2.74444 16.6517L5.35556 19.2585L7.1 17.9606C7.68889 18.3489 8.16667 18.6484 8.84444 18.8258L9.27778 21H12.7556L13.1889 18.8258C13.8667 18.6373 14.3333 18.3045 14.9333 17.9606L16.6778 19.2585L19.2889 16.6517L17.9889 14.9102C18.3333 14.3111 18.6778 13.8453 18.8556 13.1686L21 12.7471Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M11.0001 14.3334C12.841 14.3334 14.3334 12.841 14.3334 11.0001C14.3334 9.15913 12.841 7.66675 11.0001 7.66675C9.15913 7.66675 7.66675 9.15913 7.66675 11.0001C7.66675 12.841 9.15913 14.3334 11.0001 14.3334Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const news_svg = (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.1412 16.2941H17C17 16.2941 14.7118 12.1765 10.1412 12.1765H8.42941C8.42941 15.1176 8.92353 17.9235 9.26471 19.5882C9.41177 20.3176 8.87059 21 8.14706 21H6.43529C5.91765 21 5.45882 20.6412 5.32941 20.1294C4.99412 18.8 4.42941 16.2941 4.42941 12.1765H3.28824C2.65882 12.1765 2.14706 11.6471 2.14706 11L1 10.4118V6.88235L2.14118 6.29412C2.14118 5.64706 2.65294 5.11765 3.28235 5.11765H10.1412C14.7118 5.11765 17 1 17 1H18.1412C18.1412 1 18.7118 3.94118 18.7118 8.64706C18.7118 13.3529 18.1412 16.2941 18.1412 16.2941Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.7119 6.29395C19.9766 6.29395 21.0001 7.34689 21.0001 8.64689C21.0001 9.94689 19.9766 10.9998 18.7119 10.9998"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.1411 5.11768V12.1765"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
const faq_svg = (
	<svg
		width="19"
		height="22"
		viewBox="0 0 19 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.6 1H16.2C17.08 1 17.8 1.72 17.8 2.6V15.4C17.8 16.28 17.08 17 16.2 17H8.136L4.2 21V17H2.6C1.72 17 1 16.28 1 15.4V2.6C1 1.72 1.72 1 2.6 1Z"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.39951 11.1839C9.39951 11.1839 9.65551 10.1279 10.1995 9.58394C11.2955 8.48794 11.7995 8.27994 11.7995 7.18394C11.7995 6.08794 11.0395 4.78394 9.39951 4.78394C7.75951 4.78394 6.99951 6.08794 6.99951 7.18394"
			stroke="white"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.9803 12.7842C8.7493 12.7842 8.5603 12.9732 8.5603 13.2042C8.5603 13.4352 8.7493 13.6242 8.9803 13.6242C9.2113 13.6242 9.4003 13.4352 9.4003 13.2042C9.4003 12.9732 9.2113 12.7842 8.9803 12.7842Z"
			fill="white"
		/>
	</svg>
);
const hamburger_svg = (
	<svg
		width="16"
		height="14"
		viewBox="0 0 16 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<rect width="16" height="2" fill="white" />
		<rect y="6" width="16" height="2" fill="white" />
		<rect y="12" width="16" height="2" fill="white" />
	</svg>
);
const close_svg = (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
	>
		<path
			d="M13.3596 0.640137L0.639648 13.3601"
			stroke="white"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.3596 13.3601L0.639648 0.640137"
			stroke="white"
			strokeMiterlimit="10"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
function Sidebar(props) {
	const handleClick = (e) => {
		e.preventDefault();
		if (props.isMobile) {
			$("#sidebar").hide();
		} else $(".wrapper").toggleClass("collapsed");
	};
	return (
		<>
			<nav id="sidebar">
				<div className="sidebar-content">
					<div className="sidebar-header">
						<img
							src={props.store_logo}
							alt=""
							className="img-fluid sidebar-logo"
						/>
						<button className="btn btn-toggle" onClick={handleClick}>
							{props.isMobile ? close_svg : hamburger_svg}
						</button>
					</div>

					<ul className="list-unstyled components">
						<li className={props.active === "home" ? "active" : ""}>
							<a href="home">
								<div className="icon">{dashboard_svg}</div>
								<div className="text">Dashboard</div>
							</a>
						</li>

						<li
							className={props.active === "product-management" ? "active" : ""}
						>
							<a href="product-management">
								<div className="icon">{pm_svg}</div>
								<div className="text">Products Management</div>
							</a>
						</li>
						<li className={props.active === "user-management" ? "active" : ""}>
							<a href="user-management">
								<div className="icon">{um_svg}</div>
								<div className="text">User Management</div>
							</a>
						</li>
						<li
							className={props.active === "account-management" ? "active" : ""}
						>
							<a href="account-management">
								<div className="icon">{accounts_svg}</div>
								<div className="text">Account Management</div>
							</a>
						</li>
						<li className={props.active === "email-templates" ? "active" : ""}>
							<a href="email-templates">
								<div className="icon">{email_svg}</div>
								<div className="text">Email Templates</div>
							</a>
						</li>
						{/* <li className={props.active === "setting" ? "active" : ""}>
							<a href="setting">
								<div className="icon">{setting_svg}</div>
								<div className="text">Setting</div>
							</a>
						</li>
						<li className={props.active === "news" ? "active" : ""}>
							<a href="news">
								<div className="icon">{news_svg}</div>
								<div className="text">News</div>
							</a>
						</li>
						<li className={props.active === "faq" ? "active" : ""}>
							<a href="faq">
								<div className="icon">{faq_svg}</div>
								<div className="text">FAQ</div>
							</a>
						</li> */}
					</ul>
				</div>
			</nav>
		</>
	);
}

export default Sidebar;
