import React, { Component } from "react";
import Popup from "reactjs-popup";
import axios from "axios";
import "reactjs-popup/dist/index.css";
import shopifyLogo from "../../images/shopify.png";
import SweetAlert2 from "sweetalert2";
import Step1 from "../../images/steps/1.png";
import Step2 from "../../images/steps/2.png";
class AccessStorePopup extends Component {
	constructor(props) {
		super(props);

		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		const isMobile = window.innerWidth < 1200;
		this.state = {
			open: false,
			shopifyUrl: "https://shopify.pxf.io/c/3761361/1101159/13624",
			swal: {},
			isMobileView: isMobile
		};
	}
	async componentDidMount() {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getAffiliateUser`,
				{ affiliate_link: localStorage.getItem("affiliate_url") },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			);
			console.log(response.data.data[0].shopify_link);
			if (response.data.isError === false)
				this.setState({ shopifyUrl: response.data.data[0].shopify_link });
		} catch (error) {
			console.log(error);
		}
	}
	openModal(e) {
		e.preventDefault();
		this.setState({ open: true });
		document.body.style.overflow = "hidden";
	}

	closeModal() {
		this.setState({ open: false });
		document.body.style.overflow = "unset";
	}

	handleAccessToken = (e) => {
		e.preventDefault();
		this.setState({
			swal: {
				show: true,
				title: "Access token",
				text: "Do you want to continue with desktop ?",
				html:
					'<label className="form-group__label">Email</label>' +
					'<input id="email-input" type="text" value="" name="email" className="form-group__input" />',
				showCloseButton: true,
				showCancelButton: true,
				focusConfirm: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}
		});
	};

	// Function to open the SweetAlert2 dialog
	openSwal = (e) => {
		e.preventDefault();

		SweetAlert2.fire({
			show: true,
			html:
				"<h3>Please enter your email to receive the link</h3>" +
				'<input id="email-input" class="swal2-input" type="email" placeholder="Your Email Address">',
			showCancelButton: true,
			showCloseButton: true
		}).then(async (result) => {
			if (result.isConfirmed) {
				const emailInput = document.getElementById("email-input");
				const email = emailInput.value;

				if (email) {
					try {
						const config = {
							headers: {
								"Content-Type": "application/json"
							}
						};

						const response = await axios.get(
							`${process.env.REACT_APP_FRONTEND_URL}api/send/mail?access_token_email=${email}`,
							config
						);

						if (response.data.status === "error") {
							// Handle the error case
						} else if (response.data.status === "success") {
							// Handle the success case
							SweetAlert2.fire({
								icon: "success",
								title: "Success",
								text: "Mail sent successfully."
							});
						}
					} catch (error) {
						// Handle any network or request error here
						console.error("API Request Error:", error);
					}
				} else {
					// Email is empty, show an alert
					SweetAlert2.fire({
						icon: "error",
						title: "Error",
						text: "Email id required."
					});
				}
			}
		});
	};
	handleNoClick = async (e) => {
		this.closeModal();
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/addNewShopifyClick`,
				{ shopify_link: this.state.shopifyUrl },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			);
			console.log(response);
		} catch (error) {
			console.log(error);
		}
		window.open(this.state.shopifyUrl);
	};
	handleClick = async (e) => {
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/addNewShopifyClick`,
				{ shopify_link: this.state.shopifyUrl },
				{
					headers: {
						"Content-Type": "application/json"
					}
				}
			);
			console.log(response);
		} catch (error) {
			console.log(error);
		}
		window.open(this.state.shopifyUrl);
	};
	render() {
		return (
			<div>
				{this.state.isMobileView ? (
					<a className="btn btn-general" onClick={(e) => this.openModal(e)}>
						<img src={shopifyLogo} alt="shopify" /> Access Shopify
					</a>
				) : (
					<a
						rel="noopener noreferrer"
						className="btn"
						onClick={this.handleClick}
						target="_blank"
					>
						<img src={shopifyLogo} alt="shopify" /> Access Shopify
					</a>
				)}
				<Popup
					open={this.state.open}
					onClose={this.closeModal}
					modal
					closeOnDocumentClick
				>
					<div className="show-box">
						<h4>
							We recommend you use your computer for this, go to{" "}
							<a
								href="http://brandbldr.io"
								target="_blank"
								rel="noopener noreferrer"
							>
								brandbldr.io
							</a>{" "}
							on your computer to continue.
						</h4>
						<h4>
							You can proceed on mobile, however you must open this site from
							your phones browser.
						</h4>
						<h4 className="text-danger">
							Our App will not work properly in any "app browsers" (IG,
							Facebook, TikTok..) so follow these steps to open the app in your
							phones default browser:
						</h4>
						<ul>
							<li>
								Click on 3 dots menu and select "Open in external browser"
							</li>
						</ul>
						<div className="form-row">
							<div className="col-6">
								<img src={Step1} alt="" className="img-fluid" />
							</div>
							<div className="col-6">
								<img src={Step2} alt="" className="img-fluid" />
							</div>
						</div>
						<a
							className="btn btn-warning w-100 mt-4"
							onClick={this.handleNoClick}
						>
							I confirm that I am in my phones browser
						</a>
					</div>
				</Popup>
			</div>
		);
	}
}

export default AccessStorePopup;
