import React, { Component } from "react";
// import { useNavigate } from "react-router-dom";
import BusinessDetails from "./BusinessDetails";
import AccessStore from "./AccessStore";
// import SuccessForm from "./SuccessForm";
import axios from "axios";
// import { forEach } from "async";
const NavigateToThankYou = ({ shopifyLink }) => {
	// let navigate = useNavigate();
	window.location.href = "/thankyou";
	// React.useEffect(() => {
	// 	// console.log(shopifyLink);
	// 	// navigate(`/thankyou`);
	// 	window.location.href = "/thankyou";
	// }, [shopifyLink]);
	// return null;
};
class Form extends Component {
	state = {
		// step: 1,
		businessname: "",
		storename: "",
		businesslogo: "",
		email: this.props.email,
		username: "",
		password: "",
		shop_link: "",
		theme_color: "",
		font_color: "",
		product_display: "list",
		payment_method: "",
		access_token: "",
		isErrorBusinessName: true,
		isErrorBusinessLogo: true,
		errorMessageBusinessName: "",
		errorMessageBusinessLogo: "",
		posts: [],
		storeLogo: "",
		theme_id: "",
		pickThemeError: "",
		fontchoices: "Arial",
		skip_logo: "",
		shopifyLink: ""
	};
	componentDidMount() {
		this.setState({ shopifyLink: localStorage.getItem("shopify_link") });
	}

	// fetchAffiliateData = async () => {
	// 	const currentUrl = window.location.href; // or any other logic to obtain the URL
	// 	try {
	// 		const response = await axios.post(
	// 			`${process.env.REACT_APP_FRONTEND_URL}api/user/getAffiliateUser`,
	// 			{ affiliate_link: currentUrl },
	// 			{ headers: { "Content-Type": "application/json" } }
	// 		);
	// 		console.log(response.data);
	// 	} catch (error) {
	// 		this.setState({ error: error.message }); // Handle errors
	// 		console.error("API call failed:", error);
	// 	}
	// };

	// nextStep = (e) => {
	// 	// const { step } = this.state;
	// 	// this.setState({
	// 	// 	step: step + 1
	// 	// });
	// 	this.props.nextStep;
	// };

	// prevStep = () => {
	// 	// const { step } = this.state;
	// 	// this.setState({
	// 	// 	step: step - 1
	// 	// });
	// 	this.props.prevStep;
	// };
	handleShopifyLink = (e) => {
		this.setState({
			shopifyLink: e
		});
	};
	handleChange = (input) => (e) => {
		// e.preventDefault();
		// alert(input);
		const productdisplay = e.target.getAttribute("data-productdisplay");
		const paymentmethod = e.target.getAttribute("data-paymentmethod");
		// if (input === "product_display") {
		// 	this.setState({
		// 		[input]: productdisplay
		// 	});
		// } else
		if (input === "payment_method") {
			this.setState({
				[input]: paymentmethod
			});
		} else {
			this.setState({
				[input]: e.target.value
			});
		}

		if (input === "businessname") {
			if (this.state.businessname.length >= 1) {
				this.setState({
					isErrorBusinessName: false
				});
			}
		}
	};

	addColor = (e) => {
		const colorschema = e.target.value;
		this.setState({
			customcolor: colorschema
		});
	};

	validateBusinessName = () => {
		if (this.state.businessname.length < 2) {
			this.setState({
				isErrorBusinessName: true,
				errorMessageBusinessName:
					"Type your name (at least 2 characters)"
			});
			return false;
		}
		if (this.state.businessname === "") {
			this.setState({
				isErrorBusinessName: true,
				errorMessageBusinessName: "Name is required"
			});
			return false;
		}
		return true;
	};

	onSelectThemeHandler = (files) => {
		this.setState({
			themeFile: files[0]
		});
	};

	handleChildData = (data) => {
		this.setState({
			theme_id: ""
		});

		this.setState({
			theme_id: data
		});
	};

	handleData = (data) => {
		this.setState(data);
	};

	productStoreSubmit = async (e) => {
		const obj = this;

		console.log(obj.state.selectedOptions);
		const config = {
			headers: {
				"Content-Type": "application/json"
			}
		};

		const catArr = obj.state.selectedOptions;
		let catCount = 0;
		if (catArr.length == 1) {
			try {
				for (let i = 0; i < 5; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[0]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				return Promise.resolve({ status: "success" });
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		}
		if (catArr.length === 2) {
			try {
				for (let i = 0; i < 3; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[0]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				for (let i = 0; i < 2; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[1]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				return Promise.resolve({ status: "success" }); // Resolve the promise with the theme_id
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		}
		if (catArr.length == 3) {
			try {
				for (let i = 0; i < 2; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[0]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				for (let i = 0; i < 2; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[1]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				const formData = new FormData();
				//formData.append("access_token", obj.state.access_token);
				formData.append("store_name", obj.state.storename);
				formData.append("store_categories", catArr[2]);
				const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

				await delay(12000); // Add a delay of 4 seconds
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
					formData,
					config
				);

				if (response.data.status === "error") {
					obj.setState({
						productError: response.data.message
					});
					return Promise.reject(response.data); // Reject the promise if there's an error
				} else {
					catCount++;
					obj.setState({
						productError: ""
					});
					// await this.delay(20000);
					// Resolve the promise with the theme_id
					console.log({ ...response.data, catCount: catCount });
				}
				return Promise.resolve({ status: "success" });
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		}
		if (catArr.length == 4) {
			try {
				for (let i = 0; i < 2; i++) {
					const formData = new FormData();
					//formData.append("access_token", obj.state.access_token);
					formData.append("store_name", obj.state.storename);
					formData.append("store_categories", catArr[0]);
					const delay = (ms) =>
						new Promise((resolve) => setTimeout(resolve, ms));

					await delay(12000); // Add a delay of 4 seconds
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
						formData,
						config
					);

					if (response.data.status === "error") {
						obj.setState({
							productError: response.data.message
						});
						return Promise.reject(response.data); // Reject the promise if there's an error
					} else {
						catCount++;
						obj.setState({
							productError: ""
						});
						// await this.delay(20000);
						// Resolve the promise with the theme_id
						console.log({ ...response.data, catCount: catCount });
					}
				}
				const formData1 = new FormData();
				// formData1.append("access_token", obj.state.access_token);
				formData1.append("store_name", obj.state.storename);
				formData1.append("store_categories", catArr[1]);
				const delay1 = (ms) =>
					new Promise((resolve) => setTimeout(resolve, ms));

				await delay1(12000); // Add a delay of 4 seconds
				const response1 = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
					formData1,
					config
				);

				if (response1.data.status === "error") {
					obj.setState({
						productError: response1.data.message
					});
					return Promise.reject(response1.data); // Reject the promise if there's an error
				} else {
					catCount++;
					obj.setState({
						productError: ""
					});
					// await this.delay(20000);
					// Resolve the promise with the theme_id
					console.log({ ...response1.data, catCount: catCount });
				}

				const formData2 = new FormData();
				// formData2.append("access_token", obj.state.access_token);
				formData2.append("store_name", obj.state.storename);
				formData2.append("store_categories", catArr[2]);
				const delay2 = (ms) =>
					new Promise((resolve) => setTimeout(resolve, ms));

				await delay2(12000); // Add a delay of 4 seconds
				const response2 = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
					formData2,
					config
				);

				if (response2.data.status === "error") {
					obj.setState({
						productError: response2.data.message
					});
					return Promise.reject(response2.data); // Reject the promise if there's an error
				} else {
					catCount++;
					obj.setState({
						productError: ""
					});
					// await this.delay(20000);
					// Resolve the promise with the theme_id
					console.log({ ...response2.data, catCount: catCount });
				}
				const formData = new FormData();
				//formData.append("access_token", obj.state.access_token);
				formData.append("store_name", obj.state.storename);
				formData.append("store_categories", catArr[3]);
				const delay3 = (ms) =>
					new Promise((resolve) => setTimeout(resolve, ms));

				await delay3(12000); // Add a delay of 4 seconds
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/products`,
					formData,
					config
				);

				if (response.data.status === "error") {
					obj.setState({
						productError: response.data.message
					});
					return Promise.reject(response.data); // Reject the promise if there's an error
				} else {
					catCount++;
					obj.setState({
						productError: ""
					});
					// await this.delay(20000);
					// Resolve the promise with the theme_id
					console.log({ ...response.data, catCount: catCount });
				}
				return Promise.resolve({ status: "success" });
			} catch (error) {
				console.error(error);
				return Promise.reject(error);
			}
		}
	};

	themFileSubmit = (e) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			formData.append("businessname", this.state.businessname);
			formData.append("storename", this.state.storename);
			formData.append("theme_color", this.state.theme_color);
			formData.append("access_token", this.state.access_token);

			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};

			axios
				.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/theme`,
					formData,
					config
				)
				.then((response) => {
					if (response.data.status === "error") {
						this.setState({
							pickThemeError: response.data.message
						});
						resolve(response.data); // Reject the promise if there's an error
					} else {
						this.setState({
							pickThemeError: "",
							theme_file_name: response.data.theme_name,
							theme_id: response.data.theme_id
						});
						resolve(response.data); // Resolve the promise with the theme_id
					}
				})
				.catch((error) => {
					reject(error); // Reject the promise on axios request error
				});
		});
	};
	//upload products
	processStore = (e) => {
		return new Promise((resolve, reject) => {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			};
			const formData = new FormData();
			// formData.append("access_token", this.state.access_token);
			formData.append("store_email", this.state.username);
			if (this.state.selectedOptions && this.state.selectedOptions.length) {
				this.state.selectedOptions.forEach((item, index) => {
					formData.append(`product_categories[${index}]`, item);
				});
			} else {
				// Handle the case where no options are selected or selectedOptions is empty
				formData.append("product_categories", "");
			}
			axios
				.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/add/BuildStore`,
					formData,
					config
				)
				.then((response) => {
					if (response.data.status === "error") {
						// resolve(response.data);
						return resolve({ status: "error", message: response.message });
					} else {
						return resolve({ status: "success" });
					}
				})
				.catch((error) => {
					this.setState({
						submitError: error
					});
					reject(error);
				});
		});
	};
	submitStoreData = (e) => {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			// formData.append("access_token", this.state.access_token);
			// formData.append("file", this.state.file);
			formData.append("businessname", this.state.businessname);
			// formData.append("storename", this.state.storename);
			formData.append("store_email", this.state.username);
			formData.append("theme_color", this.state.theme_color);
			formData.append("product_display", this.state.product_display);
			formData.append("payment_method", "none");
			// formData.append("fontchoices", this.state.fontchoices);
			formData.append("font_color", this.state.font_color);
			formData.append("theme_id", this.state.theme_id);

			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};

			axios
				.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/create/AcountStore`,
					formData,
					config
				)
				.then((response) => {
					// alert(response.data.message);
					if (response.data.status === "error") {
						// this.setState({
						//   submit_status: false,
						//   submitError: response.data.message,
						// });
						resolve(response.data);
					} else {
						// this.setState({
						//   submit_status: true,
						//   submitError: "",
						// });
						// this.nextStep();
						resolve(response.data);
					}
				})
				.catch((error) => {
					console.log('error', error);
					
					// this.setState({
					// 	submitError: error.response.message
					// });
					resolve(error.response.data); // Reject the promise on axios request error
				});
		});
	};

	render() {
		const {
			// step,
			businessname,
			storename,
			product_display,
			payment_method,
			theme_color,
			email,
			addColor,
			access_token,
			isErrorBusinessName,
			errorMessageBusinessName,
			pickThemeError
		} = this.state;

		switch (this.props.step) {
			case 1:
				return (
					<BusinessDetails
						nextStep={this.props.nextStep}
						handleChange={this.handleChange}
						businessname={businessname}
						email={email}
						mailId={this.props.mailId}
						isMailAvail={this.props.isMailAvail}
						validateBusinessName={this.validateBusinessName}
						isErrorBusinessName={isErrorBusinessName}
						errorMessageBusinessName={errorMessageBusinessName}
						handleData={this.handleData}
						storeData={this.state}
						addColor={addColor}
						onSelectImageHandler={this.onSelectImageHandler}
					/>
				);
			// case 2:
			//   return (
			//     <PickColor
			//       nextStep={this.nextStep}
			//       prevStep={this.prevStep}
			//       handleChange={this.handleChange}
			//       addColor={addColor}
			//       handleData={this.handleData}
			//       storeData={this.state}
			//     />
			//   );
			// case 3:
			//   return (
			//     <PickLogo
			//       nextStep={this.nextStep}
			//       prevStep={this.prevStep}
			//       handleChange={this.handleChange}
			//       onSelectImageHandler={this.onSelectImageHandler}
			//     />
			//   );
			// case 4:
			//   return (
			//     <SetupStore
			//       nextStep={this.nextStep}
			//       prevStep={this.prevStep}
			//       handleChange={this.handleChange}
			//     />
			//   );
			// case 5:
			//   return (
			//     <StoreName
			//       nextStep={this.nextStep}
			//       prevStep={this.prevStep}
			//       handleChange={this.handleChange}
			//       storename={storename}
			//     />
			//   );
			case 2:
				return (
					<AccessStore
						nextStep={this.props.nextStep}
						prevStep={this.props.prevStep}
						handleChange={this.handleChange}
						access_token={access_token}
						storename={storename}
						username={this.state.username}
						password={this.state.password}
						shop_link={this.state.shop_link}
						onSelectThemeHandler={this.onSelectThemeHandler}
						storeData={this.state}
						sendDataToParent={this.handleChildData}
						handleData={this.handleData}
						pickThemeError={pickThemeError}
						themFileSubmit={this.themFileSubmit}
						processStore={this.processStore}
						submitStoreData={this.submitStoreData}
						productStoreSubmit={this.productStoreSubmit}
						handleShopifyLink={this.handleShopifyLink}
						shopifyLink={this.state.shopifyLink}
						token={this.props.token}
						email={email}
					/>
				);
			// case 7:
			//   return (
			//     <PickTheme
			//       nextStep={this.nextStep}
			//       prevStep={this.prevStep}
			//       onSelectThemeHandler={this.onSelectThemeHandler}
			//       storename={storename}
			//       storeData={this.state}
			//       handleChange={this.handleChange}
			//       sendDataToParent={this.handleChildData}
			//     />
			//   );
			// case 8:
			//   return (
			//     <StoreDetails
			//       nextStep={this.nextStep}
			//       email={email}
			//       prevStep={this.prevStep}
			//       handleChange={this.handleChange}
			//       theme_color={theme_color}
			//       product_display={product_display}
			//       payment_method={payment_method}
			//       storeData={this.state}
			//       sendDataToParent={this.handleChildData}
			//     />
			//   );
			case 3:
				// return <SuccessForm shopifyLink={this.state.shopifyLink} />;
				return <NavigateToThankYou />;
			default:
				return null;
		}
	}
}

export default Form;
