import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import $ from "jquery";
import axios from "axios";
function AdminHome() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getDashBoardData`,
				config
			);
			if (response.data.isError === false) {
				setRecords(response.data.data);
			}
			const getAllUsers = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getAllUser`,
				config
			);
			if (getAllUsers.data.isError === false) {
				setAllUsers(getAllUsers.data.data);
				const sumOfAffiliateClicks = getAllUsers.data.data.reduce(
					(sum, item) => {
						return sum + item.stats.affiliate_clicks;
					},
					0
				);
				const sumOfShopifyClicks = getAllUsers.data.data.reduce((sum, item) => {
					return sum + item.stats.shopify_clicks;
				}, 0);
				const sumOfConvertedStores = getAllUsers.data.data.reduce(
					(sum, item) => {
						return sum + item.stats.converted_stores;
					},
					0
				);
				setTotalAffiliateClicks(sumOfAffiliateClicks);
				setTotalShopifyClicks(sumOfShopifyClicks);
				setTotalConvertedStores(sumOfConvertedStores);
			}
			const shopResponse = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/store/getAllStore`,
				config
			);
			if (shopResponse.data.isError === false) {
				const sortedData = shopResponse.data.data.sort(
					(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
				);
				setShops(sortedData);
			}
			const stats = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getCurrentUser`,
				config
			);
			if (stats.data.isError === false) {
				setAffiliateClicks(stats.data.data[0].stats.affiliate_clicks);
				setShopifyClicks(stats.data.data[0].stats.shopify_clicks);
				setConvertedStores(stats.data.data[0].stats.converted_stores);
			}
			await getStatsTotal();
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const getStatsTotal = async(e)=>{
		const pageTracks = await axios.get(
			`${process.env.REACT_APP_FRONTEND_URL}api/page-tracker/totals`,
			config
		);
		if(pageTracks.data.success === true){
			setPageTrackData({
				landingPageCount: pageTracks.data.data.landingPageCount,
				step1Count:pageTracks.data.data.step1Count,
				step2Count:pageTracks.data.data.step2Count,
				storesBuiltCount:pageTracks.data.data.storesBuiltCount
			});
		}
	}
	const fetchSingleData = async (id) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/store/getStoreById`,
				{
					storeId: id
				},
				config
			);
			if (response.data.isError === false) {
				setEditFormData({
					_id: response.data.data._id,
					store_name: response.data.data.store_name,
					email: response.data.data.email,
					shipping_option: response.data.data.shipping_option,
					createdAt: response.data.data.createdAt
					// ... update other properties based on the response
				});
				console.log(response.data.data.store_name);
				console.log(editFormData);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const [records, setRecords] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [pageTrackDetails, setPageTrackDetails] = useState([]);
	const [pageTrackDetailsFilter, setPageTrackDetailsFilter] = useState([]);
	const [affiliateClicks, setAffiliateClicks] = useState(0);
	const [shopifyClicks, setShopifyClicks] = useState(0);
	const [convertedStores, setConvertedStores] = useState(0);
	const [totalAffiliateClicks, setTotalAffiliateClicks] = useState(0);
	const [totalShopifyClicks, setTotalShopifyClicks] = useState(0);
	const [totalConvertedStores, setTotalConvertedStores] = useState(0);
	const [shops, setShops] = useState("");
	const [shopVisible, setshopVisible] = useState(false);
	const [userVisible, setuserVisible] = useState(false);
	const [statsType, setStatsType] = useState("");
	const [statsVisible, setStatsVisible] = useState(false);
	const [editFormData, setEditFormData] = useState({
		_id: "",
		store_name: "",
		email: "",
		shipping_option: "",
		createdAt: ""
	});
	const [pageTrackData, setPageTrackData] = useState({
		landingPageCount: 0,
		step1Count:0,
		step2Count:0,
		storesBuiltCount:0
	});
	const CustomDate = ({ row }) => (
		<span>{new Date(row.createdAt).toLocaleString()}</span>
	);
	const CustomActions = ({ row }) => (
		<div className="action-btns">
			<button
				onClick={() => shopClick(row._id, "viewShop")}
				className="btn btn-view"
				data-id={row._id}
			>
				{view_svg}
			</button>
		</div>
	);
	const columns = [
		{
			name: "Store Name",
			selector: (row) => row.store_name,
			sortable: true,
			wrap: true,
			maxWidth: "25%"
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			maxWidth: "35%"
		},
		{
			name: "Created At",
			cell: (row) => <CustomDate row={row} />,
			selector: (row) => row.createdAt,
			sortable: true,
			maxWidth: "20%"
		},
		{
			name: "Action",
			cell: (row) => <CustomActions row={row} />,
			sortable: false,
			maxWidth: "20%"
		}
	];
	const CustomName = ({ row }) => (
		<span>
			{row.firstName} {row.lastName}
		</span>
	);
	const user_columns = [
		{
			name: "Account Name",
			cell: (row) => <CustomName row={row} />,
			selector: (row) => row.firstName,
			sortable: true,
			wrap: true,
			maxWidth: "15%"
		},
		{
			name: "Email",
			selector: (row) => row.email,
			sortable: true,
			maxWidth: "25%"
		},
		{
			name: "Total Affiliate Clicks",
			selector: (row) => row.stats.affiliate_clicks,
			sortable: true,
			maxWidth: "20%"
		},
		{
			name: "Total Shopify Clicks",
			selector: (row) => row.stats.shopify_clicks,
			sortable: true,
			maxWidth: "20%"
		},
		{
			name: "Total Converted Stores",
			selector: (row) => row.stats.converted_stores,
			sortable: true,
			maxWidth: "20%"
		}
	];
	const stats_columns = [
		{
			name: "IP",
			selector: (row) => row.ip,
			sortable: true,
			maxWidth: "30%"
		},
		{
			name: "Country",
			selector: (row) => row.country,
			sortable: true,
			wrap: true,
			maxWidth: "25%"
		},
		{
			name: "Device",
			selector: (row) => row.device,
			sortable: true,
			maxWidth: "25%"
		},
		{
			name: "Date",
			cell: (row) => <CustomDate row={row} />,
			selector: (row) => row.createdAt,
			sortable: true,
			maxWidth: "20%"
		}
	];
	const user_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M1 31C1 26 8.5 24.575 16 24.575C23.5 24.575 31 26 31 31"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.5748 9.2625C24.5748 4.7 20.7373 1 15.9998 1C11.2623 1 7.4248 4.7 7.4248 9.2625V9.875C7.4248 14.4375 11.2623 18.1375 15.9998 18.1375C20.7373 18.1375 24.5748 14.4375 24.5748 9.875V9.2625Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M24.5748 9.2625C24.5748 4.7 20.7373 1 15.9998 1C11.2623 1 7.4248 4.7 7.4248 9.2625V9.875C7.4248 14.4375 11.2623 18.1375 15.9998 18.1375C20.7373 18.1375 24.5748 14.4375 24.5748 9.875V9.2625Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const product_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M16 1L1 8.17L16 15.35L31 8.17L16 1Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31 8.16992V23.8299L16 30.9999L1 23.8299V8.16992"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16 15.3501V31.0001"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const category_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<path
				d="M14 1H1V14H14V1Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14 18H1V31H14V18Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31 1H18V14H31V1Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31 18H18V31H31V18Z"
				stroke="white"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const view_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
		</svg>
	);
	const close_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M13.3596 0.640137L0.639648 13.3601"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3596 13.3601L0.639648 0.640137"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const affiliate_svg = (
		<svg
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 47 47"
			fill="#fff"
		>
			<g>
				<g id="Layer_1_110_">
					<g>
						<path
							d="M17.567,15.938l-2.859-2.702c0.333-0.605,0.539-1.29,0.539-2.029c0-2.342-1.897-4.239-4.24-4.239
				c-2.343,0-4.243,1.896-4.243,4.239c0,2.343,1.9,4.241,4.243,4.241c0.826,0,1.59-0.246,2.242-0.654l2.855,2.699
				C16.536,16.922,17.023,16.399,17.567,15.938z"
						/>
						<path
							d="M29.66,15.6l3.799-6.393c0.374,0.107,0.762,0.184,1.169,0.184c2.347,0,4.244-1.898,4.244-4.241
				c0-2.342-1.897-4.239-4.244-4.239c-2.343,0-4.239,1.896-4.239,4.239c0,1.163,0.469,2.214,1.227,2.981l-3.787,6.375
				C28.48,14.801,29.094,15.169,29.66,15.6z"
						/>
						<path
							d="M42.762,20.952c-1.824,0-3.369,1.159-3.968,2.775l-5.278-0.521c0,0.04,0.006,0.078,0.006,0.117
				c0,0.688-0.076,1.36-0.213,2.009l5.276,0.521c0.319,2.024,2.062,3.576,4.177,3.576c2.342,0,4.238-1.896,4.238-4.238
				C47,22.85,45.104,20.952,42.762,20.952z"
						/>
						<path
							d="M28.197,37.624l-1.18-5.156c-0.666,0.232-1.359,0.398-2.082,0.481l1.182,5.157c-1.355,0.709-2.29,2.11-2.29,3.746
				c0,2.342,1.896,4.237,4.243,4.237c2.342,0,4.238-1.896,4.238-4.237C32.311,39.553,30.479,37.692,28.197,37.624z"
						/>
						<path
							d="M14.357,25.37l-6.57,2.201c-0.758-1.158-2.063-1.926-3.548-1.926C1.896,25.645,0,27.542,0,29.884
				c0,2.345,1.896,4.242,4.239,4.242c2.341,0,4.242-1.897,4.242-4.242c0-0.098-0.021-0.188-0.029-0.284l6.591-2.207
				C14.746,26.752,14.51,26.077,14.357,25.37z"
						/>
						<circle cx="23.83" cy="23.323" r="7.271" />
					</g>
				</g>
			</g>
		</svg>
	);
	const shop_svg = (
		<svg
			fill="#fff"
			width="32"
			height="32"
			viewBox="-1.52 0 122.88 122.88"
			version="1.1"
			id="Layer_1"
		>
			<g>
				<path
					className="st0"
					d="M20.57,0h79.69c1.87,0,3.39,1.53,3.39,3.4s-1.53,3.4-3.39,3.4l-79.69,0c-1.87,0-3.39-1.53-3.39-3.4 C17.17,1.53,18.7,0,20.57,0L20.57,0z M118.58,43.17v4.1c0.04,0.39,0.06,0.78,0.06,1.17c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88l-0.57,0c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.57c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.29,0.01-0.59,0.03-0.88h-0.36c0.02,0.29,0.03,0.58,0.03,0.88c0,6.88-6.43,12.46-14.36,12.46 c-7.93,0-14.36-5.58-14.36-12.46c0-0.34,0.02-0.67,0.05-1v-4.27L118.58,43.17L118.58,43.17z M16.84,10.44h87.64l11.17,23.85H5.26 L16.84,10.44L16.84,10.44z M3.39,108.01h12.12v-38.3c0.2,0.01,0.4,0.02,0.6,0.02l0.05,0l0.23,0h0.04l0.03,0h0.16l0.03,0h0.14v0.03 h0.01v-0.03c5.48,0,10.55-1.75,14.5-4.65l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02l0.01,0l0.1,0.07l0,0l0.03,0.02l0.01,0.01l0.05,0.03l0.03,0.02l0.03,0.02l0.01,0.01l0.02,0.01 l0.03,0.02l0.03,0.02l0.03,0.02L32,65.59l0.03,0.02l0.02,0.02l0,0l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.01,0l0.23,0.15l0.03,0.02l0.03,0.02l0.18,0.12l0.03,0.02l0.22,0.13L33,66.22l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02l0.03,0.02 l0.03,0.02l0.03,0.02v25.1l53.38,0V66.93c0.86-0.45,1.7-0.96,2.49-1.51l0.02-0.02l0.01,0l0.16-0.11l0.01-0.01l0.05-0.03l0.05-0.03 l0,0l0.08-0.06l0.01,0l0.01-0.01l0.17-0.12c3.97,2.95,9.08,4.73,14.61,4.73v0.03l0.01,0v-0.03h0.03v38.27h12.12 c1.87,0,3.4,3.34,3.4,7.43c0,4.09-1.53,7.43-3.4,7.43H3.39c-1.86,0-3.39-3.34-3.39-7.43C0,111.36,1.53,108.01,3.39,108.01 L3.39,108.01z"
				/>
			</g>
		</svg>
	);
	const tranfer_svg = (
		<svg
			width="32px"
			height="32px"
			viewBox="0 0 48 48"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title>transfer</title>
			<g id="Layer_2" data-name="Layer 2">
				<g id="invisible_box" data-name="invisible box">
					<rect width="48" height="48" fill="none" />
				</g>
				<g id="icons_Q2" data-name="icons Q2">
					<g>
						<path
							stroke="#fff"
							fill="#fff"
							d="M19,26a2,2,0,0,0-2,2V40H7V28a2,2,0,0,0-4,0V42a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2V28A2,2,0,0,0,19,26Z"
						/>
						<path
							stroke="#fff"
							fill="#fff"
							d="M43,26a2,2,0,0,0-2,2V40H31V28a2,2,0,0,0-4,0V42a2,2,0,0,0,2,2H43a2,2,0,0,0,2-2V28A2,2,0,0,0,43,26Z"
						/>
						<path
							stroke="#fff"
							fill="#fff"
							d="M34,16v1.2l-2.6-2.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l6,5.9a1.9,1.9,0,0,0,2.8,0l6-5.9a2.1,2.1,0,0,0,.2-2.7,1.9,1.9,0,0,0-3-.2L38,17.2V16a14,14,0,0,0-28,0v6a2,2,0,0,0,4,0V16a10,10,0,0,1,20,0Z"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
	const creds_svg = (
		<svg
			fill="#fff"
			width="32px"
			height="32px"
			viewBox="0 0 32 32"
			id="icon"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M21,22H19V20a1.0011,1.0011,0,0,0-1-1H14a1.0011,1.0011,0,0,0-1,1v2H11V20a3.0033,3.0033,0,0,1,3-3h4a3.0033,3.0033,0,0,1,3,3Z"
				transform="translate(0 0)"
			/>
			<path
				d="M16,16a3.5,3.5,0,1,1,3.5-3.5A3.5041,3.5041,0,0,1,16,16Zm0-5a1.5,1.5,0,1,0,1.5,1.5A1.5017,1.5017,0,0,0,16,11Z"
				transform="translate(0 0)"
			/>
			<path
				d="M30.4141,17.4141a1.9995,1.9995,0,0,0,0-2.8282L24.6272,8.7993l2.9006-2.8628a2.0018,2.0018,0,1,0-1.4416-1.3872L23.2129,7.3848,17.4141,1.5859a1.9995,1.9995,0,0,0-2.8282,0L8.7993,7.3726,5.9368,4.4717A2.002,2.002,0,1,0,4.55,5.9136l2.835,2.8735L1.5859,14.5859a1.9995,1.9995,0,0,0,0,2.8282l5.7989,5.7988L4.55,26.0864a1.9977,1.9977,0,1,0,1.387,1.4419l2.8625-2.9009,5.7866,5.7867a1.9995,1.9995,0,0,0,2.8282,0l5.7988-5.7989,2.8733,2.8355a1.998,1.998,0,1,0,1.4416-1.3872l-2.9006-2.8628ZM16,29,3,16,16,3,29,16Z"
				transform="translate(0 0)"
			/>
			<rect
				id="_Transparent_Rectangle_"
				data-name="&lt;Transparent Rectangle&gt;"
				width="32"
				height="32"
				fill="none"
			/>
		</svg>
	);
	const stats_svg = (
		<svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="18" y="7" width="4" height="13" rx="1" stroke="#fff" stroke-linejoin="round"/>
			<rect x="10" y="13" width="4" height="7" rx="1" stroke="#fff" stroke-linejoin="round"/>
			<rect x="2" y="9" width="4" height="11" rx="1" stroke="#fff" stroke-linejoin="round"/>
		</svg>
	);
	const click_svg = (
		<svg
			width="32"
			height="32"
			viewBox="0 0 512 512"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fill="#fff"
				d="M459.15 269.75a133.197 133.197 0 0 1-55.862 179.975l-42.782 22.541-10.52 5.533a71.277 71.277 0 0 1-62.966 1.685l-167.077-71.38 15.733-46.676 99.363 19.194-51.458-97.78-82.843-157.411 40.357-21.232 82.844 157.457 19.934-10.485-36.521-69.445 40.335-21.22 36.52 69.445 19.935-10.485-28.2-53.598 40.358-21.232 28.2 53.598 19.945-10.576-19.354-36.886 40.346-21.174 19.354 36.885 54.348 103.301zM73.268 146.674a60.03 60.03 0 0 1 42.361-102.459 60.098 60.098 0 0 1 56.58 80.169l10.588 20.013A78.29 78.29 0 0 0 115.708 26a78.233 78.233 0 0 0-5.635 156.262L99.428 162.02a59.688 59.688 0 0 1-26.16-15.346z"
			/>
		</svg>
	);
	
	const showShopsClick = (e) => {
		e.preventDefault();
		console.log('shops');
		if (userVisible) {
			setuserVisible(!userVisible);
		}
		if(statsVisible){
			setStatsVisible(!statsVisible);
		}
		setshopVisible(!shopVisible);
	};
	const showUserClick = (e) => {
		// console.log('user');
		e.preventDefault();
		if (shopVisible) {
			setshopVisible(!shopVisible);
		}
		if(statsVisible){
			setStatsVisible(!statsVisible);
		}
		if(userVisible===false)
			setuserVisible(!userVisible);
	};
	const showStatsClick = async (type) => {
		if(type !="" || type!=null){
			setStatsType(type);
			setStartDate(null);
			setEndDate(null);
			getStatsTotal();
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/page-tracker/details`,
				{
					page_type: type,
				},
				config
			);
			setPageTrackDetails(response.data.data);
			setPageTrackDetailsFilter(response.data.data);
			if (shopVisible) {
				setshopVisible(!shopVisible);
			}
			if (userVisible) {
				setuserVisible(!userVisible);
			}
			if(statsVisible === false)
				setStatsVisible(!statsVisible);
		}
		
	};
	const shopClick = async (e, id) => {
		setLoading(true);
		try {
			// Wait for fetchSingleData to complete
			await fetchSingleData(e);
			setLoading(false);
			let modal = $(`#${id}`);
			modal.modal("show");
		} catch (error) {
			setLoading(false);
			console.error("Error fetching single data:", error);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// Handle the error if needed
		}
	};
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	const [dateFilter, setDateFilter] = useState('');
	const handleDateFilterDropdown = (e)=>{
		const { name, value } = e.target;
		console.log(name, value);
		const now = new Date();
		let start, end;
		switch (value) {
			case 'today':
				start = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
				end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
				break;
			case 'yesterday':
				start = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 0, 0, 0, 0);
				end = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1, 23, 59, 59, 999);
				break;
			case 'thisMonth':
				start = new Date(now.getFullYear(), now.getMonth(), 1);
				end = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59, 999);
				break;
			case 'lastMonth':
				start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
				end = new Date(now.getFullYear(), now.getMonth(), 0, 23, 59, 59, 999);
				break;
			case 'nextMonth':
				start = new Date(now.getFullYear(), now.getMonth() + 1, 1);
				end = new Date(now.getFullYear(), now.getMonth() + 2, 0, 23, 59, 59, 999);
				break;
			default:
				start = null;
				end = null;
		}
		setDateFilter(value);
		onChange([start,end]);
	
	} 
	const onChange = (dates) => {
		const [start, end] = dates;
		setStartDate(start);
		setEndDate(end);
		if(start !== null && end !== null){
			const filtered = pageTrackDetails.filter((item) => {
				const itemDate = moment(item.createdAt).startOf('day');
				return itemDate.isBetween(moment(start).startOf('day'), moment(end).startOf('day'), null, '[]');
			  });
			  setPageTrackDetailsFilter(filtered);
			  if(statsType === 'landing'){
				setPageTrackData((prev)=>({
					...prev,
					landingPageCount : filtered.length
				}))
			  } else if(statsType === "step_1"){
				setPageTrackData((prev)=>({
					...prev,
					step1Count : filtered.length
				}))
			  } else if(statsType === "step_2"){
				setPageTrackData((prev)=>({
					...prev,
					step2Count : filtered.length
				}))
			  } else if(statsType === "built_store"){
				setPageTrackData((prev)=>({
					...prev,
					storesBuiltCount : filtered.length
				}))
			  }
		}else{
			setPageTrackDetailsFilter(pageTrackDetails);
			setDateFilter('');
			if(statsType === 'landing'){
				setPageTrackData((prev)=>({
					...prev,
					landingPageCount : pageTrackDetails.length
				}))
			  } else if(statsType === "step_1"){
				setPageTrackData((prev)=>({
					...prev,
					step1Count : pageTrackDetails.length
				}))
			  } else if(statsType === "step_2"){
				setPageTrackData((prev)=>({
					...prev,
					step2Count : pageTrackDetails.length
				}))
			  } else if(statsType === "built_store"){
				setPageTrackData((prev)=>({
					...prev,
					storesBuiltCount : pageTrackDetails.length
				}))
			  }
		}
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Dashboard</h2>
			</div>
			<div className="body">
				<div className="form-row">
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{user_svg}</div>
								<div className="text">Total Active Users</div>
							</div>
							<div className="right">
								<div className="number">{allUsers.length}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card" onClick={showShopsClick}>
							<div className="left">
								<div className="icon">{product_svg}</div>
								<div className="text">Total Shops</div>
							</div>
							<div className="right">
								<div className="number">{records.stores}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{category_svg}</div>
								<div className="text">Total Categories</div>
							</div>
							<div className="right">
								<div className="number">{records.categories}</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="form-row mt-2">
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{affiliate_svg}</div>
								<div className="text">Admin Affiliate Clicks</div>
							</div>
							<div className="right">
								<div className="number">{affiliateClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{click_svg}</div>
								<div className="text">Admin Shopify Clicks</div>
							</div>
							<div className="right">
								<div className="number">{shopifyClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card">
							<div className="left">
								<div className="icon">{shop_svg}</div>
								<div className="text">Admin Converted Stores</div>
							</div>
							<div className="right">
								<div className="number">{convertedStores}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-12">
						<hr className="so-hr" />
					</div>
				</div>				
				<div className="form-row mt-2">
					<div className="col-lg-6">
						<div className="home-card user-card">
							<div className="left">
								<div className="icon">{tranfer_svg}</div>
								<div className="text">Total Transferred Stores</div>
							</div>
							<div className="right">
								<div className="number">{records.account_transfered_count}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-6">
						<div className="home-card user-card">
							<div className="left">
								<div className="icon">{creds_svg}</div>
								<div className="text">Total Creds Used Stores</div>
							</div>
							<div className="right">
								<div className="number">{records.creds_used_count}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-12">
						<hr className="so-hr" />
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-lg-4">
						<div className="home-card user-card" onClick={showUserClick}>
							<div className="left">
								<div className="icon">{affiliate_svg}</div>
								<div className="text">Total Affiliate Clicks</div>
							</div>
							<div className="right">
								<div className="number">{totalAffiliateClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card user-card" onClick={showUserClick}>
							<div className="left">
								<div className="icon">{click_svg}</div>
								<div className="text">Total Shopify Clicks</div>
							</div>
							<div className="right">
								<div className="number">{totalShopifyClicks}</div>
							</div>
						</div>
					</div>
					<div className="col-lg-4">
						<div className="home-card user-card" onClick={showUserClick}>
							<div className="left">
								<div className="icon">{shop_svg}</div>
								<div className="text">Total Converted Stores</div>
							</div>
							<div className="right">
								<div className="number">{totalConvertedStores}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-12">
						<hr className="so-hr" />
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-md-12">
						<h3 className="so-dash-stats">Stats</h3>
					</div>
					<div className="col-md-3">
						<div className="home-card user-card" onClick={()=>showStatsClick('landing')}>
							<div className="left">
								<div className="icon">{stats_svg}</div>
								<div className="text">Lost at Landing Page</div>
							</div>
							<div className="right">
								<div className="number">{pageTrackData.landingPageCount}</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="home-card user-card" onClick={()=>showStatsClick('step_1')}>
							<div className="left">
								<div className="icon">{stats_svg}</div>
								<div className="text">Lost at Step 1</div>
							</div>
							<div className="right">
								<div className="number">{pageTrackData.step1Count}</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="home-card user-card" onClick={()=>showStatsClick('step_2')}>
							<div className="left">
								<div className="icon">{stats_svg}</div>
								<div className="text">Lost at Step 2</div>
							</div>
							<div className="right">
								<div className="number">{pageTrackData.step2Count}</div>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="home-card user-card" onClick={()=>showStatsClick('built_store')}>
							<div className="left">
								<div className="icon">{stats_svg}</div>
								<div className="text">Reached at Thank you</div>
							</div>
							<div className="right">
								<div className="number">{pageTrackData.storesBuiltCount}</div>
							</div>
						</div>
					</div>
				</div>
				{shopVisible && (
					<div className="row mt-4">
						<div className="col-12">
							<div className="table table-responsive">
								<DataTable
									columns={columns}
									data={shops}
									pagination
								></DataTable>
							</div>
						</div>
					</div>
				)}
				{userVisible && (
					<div className="row mt-4">
						<div className="col-12">
							<div className="table table-responsive">
								<DataTable
									columns={user_columns}
									data={allUsers}
									pagination
								></DataTable>
							</div>
						</div>
					</div>
				)}
				{statsVisible && (
					<div className="form-row mt-4">
						<div className="col-6">
							<div className="d-flex align-items-center filter-date-dash">
								<h4>Filter By Date: </h4>
								<DatePicker 
									selected={startDate}
									onChange={onChange}
									startDate={startDate}
									endDate={endDate}
									selectsRange
									isClearable={true}
								/>
								<div class="form-group">
								  <select class="form-control" name="date-filter" value={dateFilter} onChange={handleDateFilterDropdown}>
									<option>--Select--</option>
									<option value={"today"}>Today</option>
									<option value={"yesterday"}>Yesterday</option>
									<option value={"thisMonth"}>This Month</option>
									<option value={"lastMonth"}>Last Month</option>
									{/* <option value={"nextMonth"}>Next Month</option> */}
								  </select>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="table table-responsive">
								<DataTable
									columns={stats_columns}
									data={pageTrackDetailsFilter}
									pagination
								></DataTable>
							</div>
						</div>
					</div>
				)}
			</div>
			{/* view modal */}
			<div
				className="modal fade"
				id="viewShop"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<h2>View Shop</h2>
							<div className="table-responsive">
								<div className="details">
									<div className="item">
										<h4 className="text-left">Store Name</h4>
										<p>{editFormData.store_name}</p>
									</div>
									<hr />
									<div className="item">
										<h4 className="text-left">Store Email</h4>
										<p>{editFormData.email}</p>
									</div>
									<hr />
									<div className="item">
										<h4 className="text-left">Store Shipping Option</h4>
										<p>{editFormData.shipping_option}</p>
									</div>
									<hr />
									<div className="item">
										<h4 className="text-left">Created At</h4>
										<p> {new Date(editFormData.createdAt).toLocaleString()}</p>
									</div>
								</div>
								{/* <table className="table">
									<tr>
										<th>Store Name:</th>
										<td className="text-right">{editFormData.store_name}</td>
									</tr>
									<tr>
										<th>Email:</th>
										<td className="text-right">{editFormData.email}</td>
									</tr>
									<tr>
										<th>Shipping:</th>
										<td className="text-right">
											{editFormData.shipping_option}
										</td>
									</tr>
									<tr>
										<th>Created At:</th>
										<td className="text-right">
											{new Date(editFormData.createdAt).toLocaleString()}
										</td>
									</tr>
								</table> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default AdminHome;
