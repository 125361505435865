import React, { useEffect } from "react";
// import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import axios from "axios";
import StepperCompo from "../../component/Stepper/StepperCompo";
import "../../css/style.css";
import store_logo from "../../images/store-logo/brandbldr-logo-dark.png";
import remove_password from "../../images/thankyou/remove-password.png";
import remove_api_one from "../../images/thankyou/1.png";
import remove_api_two from "../../images/thankyou/2.png";
import remove_api_three from "../../images/thankyou/3.png";
function Success(props) {
	const insertSnapPixel = (snapId) => {
		if (window.snaptr) return;

		// Snap Pixel initialization
		window.snaptr = function () {
			window.snaptr.handleRequest
				? window.snaptr.handleRequest.apply(window.snaptr, arguments)
				: window.snaptr.queue.push(arguments);
		};
		window.snaptr.queue = [];

		// Creating the Snap Pixel script tag
		const scriptTag = document.createElement("script");
		scriptTag.async = true;
		scriptTag.src = "https://sc-static.net/scevent.min.js";

		// Inserting the script tag before other scripts
		const firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

		// Function to initialize and track events after the script loads
		scriptTag.onload = () => {
			window.snaptr("init", snapId, {});
			window.snaptr("track", "SIGN_UP");
		};
	};
	const pageTracker = async(trackId)=> {
        try {
			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};
			const formData = new FormData();
			if(trackId !== null || trackId !=="")
            // Fetch client IP
				{
					// const ipResponse = await axios.get('https://api.ipify.org?format=json');
					// const ip = ipResponse.data.ip;

					// // Fetch client country using the IP
					// const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
					// const country = countryResponse.data.country_name;

					// // Determine the device type
					// let deviceType = 'Unknown';
					// if (isMobile) {
					// 	deviceType = 'Mobile';
					// } else if (isTablet) {
					// 	deviceType = 'Tablet';
					// } else if (isBrowser) {
					// 	deviceType = 'Browser';
					// }
					// // Storing Data
					
					// formData.append("ip", ip);
					// formData.append("device", deviceType);
					// formData.append("country", country);
					formData.append("track_id", trackId);
					formData.append("landing_page_count", 0);
					formData.append("step_1_count", 0);
					formData.append("step_2_count", 0);
					formData.append("built_store_count", 1);
			
				const pageTrackerResponse = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/page-tracker`,
					formData,
					config
				);
				if (pageTrackerResponse.data.success === true) {
					// localStorage.setItem("page_track_id", pageTrackerResponse.data.data._id);
					localStorage.removeItem("page_track_id");
				}
			}
        } catch (error) {
            console.error('Error Page Track info:', error);
        }
    }
	// const insertTikTokAnalytics = (tiktokId) => {
	// 	const script = document.createElement("script");
	// 	script.id = tiktokId;
	// 	script.type = "text/javascript";
	// 	script.async = true;
	// 	script.innerHTML = `
	//   !function(w,d,t){
	//     w.TiktokAnalyticsObject=t;
	//     var ttq=w[t]=w[t]||[];
	//     ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
	//     ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};
	//     for(var i=0;i<ttq.methods.length;i++){ttq.setAndDefer(ttq,ttq.methods[i])};
	//     ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=d.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=d.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
	//     ttq.load(${tiktokId});
	// 	ttq.track('CompleteRegistration');
	//     ttq.page();
	//   }(window, document, 'ttq');
	// `;

	// 	document.body.appendChild(script);
	// };
	useEffect(async () => {
		insertSnapPixel("ad1fa1c3-78fb-47dc-b631-bb7075fd34e1");
		const trackId = localStorage.getItem("page_track_id");
		console.log(trackId);
		pageTracker(trackId);
		// insertTikTokAnalytics("CNUVHNJC77U47AMPHSD0");
	}, []);
	return (
		<main className="so-form-container d-flex align-items-center">
			<div className="container">
				<div className="card">
					<div className="thankyou-title text-center">
						<img src={store_logo} alt="" className="centr-logo" />
						{/* <h1>Create a Shopify Store</h1>
						<p>Fill all details and read to access your store.</p> */}
					</div>
					<div className="so-form congrats">
						<form>
							<StepperCompo count={8} />

							<div className="text-center">
								{/* <img src={check_mark_img} alt="sucess card" /> */}
								<svg
									width="91"
									height="91"
									viewBox="0 0 91 91"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M45.1443 88.2887C68.9723 88.2887 88.2887 68.9723 88.2887 45.1443C88.2887 21.3164 68.9723 2 45.1443 2C21.3164 2 2 21.3164 2 45.1443C2 68.9723 21.3164 88.2887 45.1443 88.2887Z"
										stroke="#ffc260"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
									<path
										d="M24.0547 44.7268L38.399 59.0619L66.2341 31.2268"
										stroke="#ffc260"
										stroke-width="4"
										stroke-miterlimit="10"
									/>
								</svg>

								<h5 className="text-center">Congratulations </h5>

								<div className="info w-75 mx-auto">
									<h6 className="sub-head">
										We are nearly there - just one final step!
									</h6>
									{/* <p>
										You have now given us all the permissions to apply needed
										customizations <br /> to your Shopify Store.
									</p> */}
									<p>
										Your store is being built, you will receive an email when its done. When that is complete, It's time to make your store public and accessible to everyone. By default, Shopify secures your store with a password until you are ready to launch. You'll need to remove this password to go live.
									</p>
								</div>
								{/* <div className="visit">
									<p>Visit your store from shopify</p> */}
									{/* <a
										target="_blank"
										href={props.shopifyLink}
										className="btn so-btn"
									>
										Visit Store
									</a> */}
								{/* </div> */}
							</div>
							<div className="form-row">
								<div className="col-md-6">
									<div className="step2_formgroup">
										<div className="whats_next_box">
											<h4>What's next?</h4>
										</div>
										<ul>
											<li className="next-li">Go to your Shopify Store</li>
											<li className="next-li">
												Verify the customizations from{" "}
												<strong>online store</strong> and click{" "}
												<strong>themes &gt; View Your Store.</strong>
											</li>
										</ul>
										<div className="publish-store">
											<h5 className="text-left">
												Follow these steps to publish your store:
											</h5>
											<ul>
												{/* <li className="next-li">
													Click the button below,{" "}
													<strong>"Access Shopify to Publish Store."</strong>
												</li> */}
												<li className="next-li">
													In the Shopify settings, deselect to “Restrict access
													to visitors with a password” (Refer to the image
													below).
													<div className="img mt-2">
														<img src={remove_password} className="img-fluid" />
													</div>
												</li>
												<li className="next-li">
													Click <strong>Save</strong>
												</li>
												<li className="next-li">Launch your Shopify Store</li>
												<li className="next-li">
													Enjoy your Launch with Customized happiness from{" "}
													<a href={process.env.REACT_APP_URL}>brandbldr.ai</a>
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="faq">
										<div className="heading">
											<h4>FAQs</h4>
										</div>
										<div id="accordion">
											<div className="text-left">
												<div className="card-header" id="headingOne">
													<h5 className="mb-0">
														<button
															className="btn"
															data-toggle="collapse"
															data-target="#collapseOne"
															aria-expanded="true"
															aria-controls="collapseOne"
															type="button"
														>
															How do I update my Shop's Banner?
														</button>
													</h5>
												</div>

												<div
													id="collapseOne"
													className="collapse show"
													aria-labelledby="headingOne"
													data-parent="#accordion"
												>
													<div className="card-body">
														<ol>
															<li className="">
																Go to Sales channels -&gt; Online store
															</li>
															<li className="">Themes &gt; Customize</li>
															<li className="">
																Select image banner, Click change on the right
																side
															</li>
															<li className="">
																Select image & choose images from library or
																upload{" "}
																<strong>
																	(You can also use{" "}
																	<a href="http://canva.com" target="_blank">
																		CANVA.COM
																	</a>{" "}
																	to create custom images)
																</strong>
															</li>
															<li className="">Once selected -&gt; Click done</li>
														</ol>
													</div>
												</div>
											</div>
											<div className="text-left">
												<div className="card-header" id="headingTwo">
													<h5 className="mb-0">
														<button
															className="btn collapsed"
															data-toggle="collapse"
															data-target="#collapseTwo"
															aria-expanded="false"
															aria-controls="collapseTwo"
															type="button"
														>
															How do I find sourcing of My Products?
														</button>
													</h5>
												</div>
												<div
													id="collapseTwo"
													className="collapse"
													aria-labelledby="headingTwo"
													data-parent="#accordion"
												>
													<div className="card-body">
														<ol>
															<li className="">Go to Products</li>
															<li className="">Click on a product</li>
															<li className="">
																Scroll to Metafields at the bottom of the page
															</li>
															<li className="">Click on Show All</li>
															<li className="">
																Click on Metafields without definition on the
																right
															</li>
															<li className="">Click on Product source link</li>
															<li className="">
																Click on the icon next to it to open the link
															</li>
														</ol>
													</div>
												</div>
											</div>
											<div className="text-left">
												<div className="card-header" id="headingThree">
													<h5 className="mb-0">
														<button
															className="btn collapsed"
															data-toggle="collapse"
															data-target="#collapseThree"
															aria-expanded="false"
															aria-controls="collapseThree"
															type="button"
														>
															How do I change the email?
														</button>
													</h5>
												</div>
												<div
													id="collapseThree"
													className="collapse"
													aria-labelledby="headingThree"
													data-parent="#accordion"
												>
													<div className="card-body">
														<ol>
															<li className="">
																Go to your Shopify Store and Click "Settings"
															</li>
															<li className="">
																Go to "Store details" section and click on the
																Edit "Icon" of the profile section
															</li>
															<li className="">
																Update the "Store email" to new one and click
																"Save"
															</li>
														</ol>
													</div>
												</div>
											</div>
											<div className="text-left">
												<div className="card-header" id="headingFour">
													<h5 className="mb-0">
														<button
															className="btn collapsed"
															data-toggle="collapse"
															data-target="#collapseFour"
															aria-expanded="false"
															aria-controls="collapseFour"
															type="button"
														>
															How do I change the Billing Information?
														</button>
													</h5>
												</div>
												<div
													id="collapseFour"
													className="collapse"
													aria-labelledby="headingFour"
													data-parent="#accordion"
												>
													<div className="card-body">
														<ol>
															<li className="">
																Go to your Shopify Store and Click "Settings"
															</li>
															<li className="">
																Go to "Store details" section and click on the
																Edit "Icon" of the Billing information section
															</li>
															<li className="">
																Update the required info and click "Save"
															</li>
														</ol>
													</div>
												</div>
											</div>
											<div className="text-left">
												<div className="card-header" id="headingFive">
													<h5 className="mb-0">
														<button
															className="btn collapsed"
															data-toggle="collapse"
															data-target="#collapseFive"
															aria-expanded="false"
															aria-controls="collapseFive"
															type="button"
														>
															How to remove the API key?
														</button>
													</h5>
												</div>
												<div
													id="collapseFive"
													className="collapse"
													aria-labelledby="headingFive"
													data-parent="#accordion"
												>
													<div className="card-body">
														<ol>
															<li className="">
																Go to Apps and Sales Channels from the Shopify Settings
															</li>
															<li className="">
																Click on Develop Apps
															</li>
															<li className="">
																Click on the listed App
															</li>
															<li className="">
																Click on Uninstall App
															</li>
															<li className="">
																Click on Uninstall button in the confirmation popup
															</li>
														</ol>
														<div className="img mt-2">
															<img src={remove_api_one} className="img-fluid" />
														</div>
														<div className="img mt-4">
															<img src={remove_api_two} className="img-fluid" />
														</div>
														<div className="img mt-4">
															<img src={remove_api_three} className="img-fluid" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</main>
	);
}
export default Success;
