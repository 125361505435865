import React, { Component } from "react";
import list_img from "../images/list.png";
import grid_img from "../images/grid.png";

class StoreDetails extends Component {
	constructor(props) {
		super(props);
		const isDisplay = this.props.storeData.product_display;
		if (isDisplay === "list") {
			var isList = "so-pro-select";
			var isGrid = "";
		} else if (isDisplay === "grid") {
			var isList = "";
			var isGrid = "so-pro-select";
		} else {
			var isList = "so-pro-select";
			var isGrid = "";
		}
		this.state = {
			listProduct_display: isList,
			gridProduct_display: isGrid
		};
	}

	continue = (e) => {
		e.preventDefault();
		// this.props.nextStep();
	};

	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const { product_display, handleChange, handleData } = this.props;

		const onProductDisplay = (e) => {
			if (e.target.value === "list") {
				this.setState({ listProduct_display: "so-pro-select" });
				this.setState({ gridProduct_display: "" });
			} else if (e.target.value === "grid") {
				this.setState({ gridProduct_display: "so-pro-select" });
				this.setState({ listProduct_display: "" });
			} else {
				this.setState({ listProduct_display: "" });
				this.setState({ gridProduct_display: "" });
			}
			handleData({ product_display: e.target.value });
		};

		return (
			<div>
				{/* <label className="form-group__label">Product Display:</label> */}
				<label className="">Choose Product Display</label>

				<div className="form-row">
					<div className={`col-6  ${this.state.listProduct_display}`}>
						<div className="so-view-div">
							{/* <img src={list_img} alt="enter credit card" /> */}

							<label>
								<input
									type="radio"
									data-productdisplay="list"
									value="list"
									name="product_display"
									onChange={(e) => onProductDisplay(e)}
									checked={this.state.listProduct_display !== ""}
								/>
								List View
							</label>
						</div>
					</div>

					<div className={`col-6 ${this.state.gridProduct_display}`}>
						<div className="so-view-div">
							{/* <img src={grid_img} alt="enter credit card" /> */}

							<label>
								<input
									type="radio"
									data-productdisplay="grid"
									value="grid"
									name="product_display"
									onChange={(e) => onProductDisplay(e)}
									checked={this.state.gridProduct_display !== ""}
								/>
								Grid View
							</label>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default StoreDetails;
