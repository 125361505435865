import React, { useEffect, useState } from "react";
import Form from "../../component/Form";
// import "../../App.css";
// import "../../component/Stepper/stepper.css";
import "../../css/style.css";
import store_logo from "../../images/store-logo/brandbldr-logo-dark.png";
import { useLocation } from 'react-router-dom';
function StorebuilderForm() { 
	const insertSnapPixel =(snapId) => {
		if (window.snaptr) return;

		// Snap Pixel initialization
		window.snaptr = function () {
			window.snaptr.handleRequest
				? window.snaptr.handleRequest.apply(window.snaptr, arguments)
				: window.snaptr.queue.push(arguments);
		};
		window.snaptr.queue = [];

		// Creating the Snap Pixel script tag
		const scriptTag = document.createElement("script");
		scriptTag.async = true;
		scriptTag.src = "https://sc-static.net/scevent.min.js";

		// Inserting the script tag before other scripts
		const firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

		// Function to initialize and track events after the script loads
		scriptTag.onload = () => {
			window.snaptr("init", snapId, {});
			window.snaptr("track", "APP_OPEN");
		};
	};
	useEffect(() => {
		insertSnapPixel("ad1fa1c3-78fb-47dc-b631-bb7075fd34e1");
		if(state?.step !== undefined && state?.step === 2)
			setStep(2);
	}, []);
	const [step, setStep] = useState(1);
	const nextStep = ()=>{
		setStep(step => step + 1);
	}
	const prevStep = ()=>{
		setStep(step => step - 1);
	}
	const location = useLocation();
  	const { state } = location;
	
	return (
		<>
			<main className="so-form-container d-flex align-items-center">
				<div className="container">
					<div className="card">
						
						<div className="head-title text-center">
							<img src={store_logo} alt="" className="centr-logo" />
							{step===1 ? <>	<h1>Create Your Shopify Store</h1>
							<p>Fill all details and read to access your store.</p> </>: ''}
						</div>
						<Form step={step} token={state?.token} email={state?.email} mailId={state?.mailId} isMailAvail={state?.email=== undefined || state?.email === "" ? false : true} nextStep={nextStep} prevStep={prevStep}/>
					</div>
				</div>
			</main>
		</>
	);
}

export default StorebuilderForm;
