import React from "react";
import { useNavigate } from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Custom CSS
import "../../../css/dashboard.css";
import store_logo from "../../../images/store-logo/brandbldr-logo-hori-white.png";
import user_img from "../../../images/user.png";
import Sidebar from "./partials/Sidebar";
import UserHome from "./UserHome";
import ShopifyLink from "./ShopifyLink";
import Profile from "./Profile";
// import ProductManagement from "./ProductManagement";
// import UserManagement from "./UserManagement";
import $ from "jquery";
function Dashboard(props) {
	const navigate = useNavigate();
	const hamburger_svg = (
		<svg
			width="16"
			height="14"
			viewBox="0 0 16 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="16" height="2" fill="#000" />
			<rect y="6" width="16" height="2" fill="#000" />
			<rect y="12" width="16" height="2" fill="#000" />
		</svg>
	);
	const isMobile = window.innerWidth < 480;
	let class_wrapper = "wrapper";
	if (isMobile) {
		class_wrapper += " mobile";
	}
	const handleClick = (e) => {
		e.preventDefault();
		$("#sidebar").show();
	};
	const handleLogout = (e) => {
		localStorage.removeItem("token");
		localStorage.removeItem("userName");
		localStorage.removeItem("role");
		localStorage.removeItem("lastLoginTime");
		navigate("/login");
	};
	return (
		<>
			<div className={class_wrapper}>
				<Sidebar
					store_logo={store_logo}
					isMobile={isMobile}
					active={props.screen}
				/>
				<div id="content">
					<nav
						className="navbar navbar-expand-lg justify-content-end"
						id="navbar"
					>
						{isMobile ? (
							<button className="btn btn-toggle" onClick={handleClick}>
								{hamburger_svg}
							</button>
						) : (
							""
						)}
						<div className="d-flex ">
							<div className="d-flex align-items-center">
								<div className="user">
									<div className="img">
										<img src={user_img} alt="" className="img-fluid" />
									</div>
									<div className="dropdown">
										<div
											className="dropdown-toggle"
											id="dropdownMenuButton"
											data-toggle="dropdown"
											aria-haspopup="true"
											aria-expanded="false"
										>
											{localStorage.getItem("userName")}
										</div>
										<div
											className="dropdown-menu"
											aria-labelledby="dropdownMenuButton"
										>
											<span className="dropdown-item" onClick={handleLogout}>
												Logout
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</nav>
					{props.screen === "home" ? (
						<UserHome />
					) : props.screen === "shopify_link" ? (
						<ShopifyLink />
					) : props.screen === "profile" ? (
						<Profile />
					) : (
						""
					)}
					{/*: props.screen === "user-management" ? (
						<UserManagement />
					) : props.screen === "product-management" ? (
						<ProductManagement />
					) : (
						""
					)} */}
				</div>
			</div>
		</>
	);
}
export default Dashboard;
