import React from "react";
import MobileStepsPopup from "../../component/Popup/MobileStepsPopup";
import banner_main from "../../images/home-banner-img.png";
export function Affiliate(props) {
	return (
		<>
			<section className="banner text-center is-affiliate ">
				<div className="logo-section affiliate-logo">
					<img src={props.store_logo} alt="site-logo" className="img-fluid" />
				</div>
				<h1>Unlock Your eCommerce Potential</h1>
				<p>
					Effortlessly create your Shopify store with custom themes and curate
					products tailored to your brand.
				</p>
				<small>Start, style, and sell with our all-in-one platform!</small>

				<MobileStepsPopup isAffiliate={true} />
				<div className="absolute">
					<img src={banner_main} alt="" className="img-fluid" />
				</div>
				<div className="divider">
					<svg
						id="Layer_2"
						data-name="Layer 2"
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 1921 286.5"
					>
						<g id="Layer_3" data-name="Layer 3">
							<path
								className="cls-1"
								d="M1920.5,168.23v117.77H.5v-117.77h.01L83.29,85.45c113.27-113.27,296.9-113.27,410.17-.01h.01c72.33,72.35,189.62,72.35,261.95,.01C812.05,28.82,886.28,.5,960.51,.5s148.44,28.32,205.08,84.95h.01c72.34,72.34,189.62,72.34,261.95,0C1484.19,28.82,1558.41,.5,1632.63,.5s148.46,28.32,205.09,84.95l82.78,82.78Z"
							/>
						</g>
					</svg>
				</div>
			</section>
		</>
	);
}
