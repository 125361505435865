import React, { Component } from "react";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
import StepperCompo from "./Stepper/StepperCompo";
import axios from "axios";
// import { TailSpin } from "react-loader-spinner";
import shopifyLogo from "../images/shopify.png";
import InteractiveLoader from "./Popup/InteractiveLoader";
import PlanImage from '../images/plan-activate.png';
import AccountExpiredPopup from "./Popup/AccountExpiredPopup";
import TokenExpirePopup from "./Popup/TokenExpirePopup";
class AccessStore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			shopifyLink: "",
			submitError: "",
			successBox: false,
			storeError: "",
			storenameError: "",
			copiedText: "",
			loading: false,
			disabled: true,
			new_disabled: false,
			showPopup: false,
			loaderDuration: 265000,
			showAccountExpiredPopup: false,
			showTokenExpiredPopup: false,
			tokenExpireMessage: "",
		};
	}
	async componentDidMount() {
		const trackId = localStorage.getItem("page_track_id");
		this.pageTracker(trackId);
		if(this.props.token !== undefined)
		{
			let userStepsData = await this.getUserSteps(this.props.token);
			if(userStepsData.isError === false){
				let details = userStepsData.data;
				this.props.handleData({
					businessname: details.business_name,
					isErrorBusinessName: false,
					theme_color: details.themeColor,
					email: details.user_email,
					selectedOptions: details.store_products,
				})
				if(details.accountStore.isActive){
					this.props.handleData({
						username: details.accountStore.store_email,
						password: details.accountStore.store_password,
						storename: details.accountStore.stores.store_name,
						shop_link: details.accountStore.store_url,
						access_token: details.accountStore.stores.access_token,
					})
				}
				else{
					this.setState({ showAccountExpiredPopup: true });
				}
			}else{
				this.setState({ showTokenExpiredPopup: true });
				this.setState({ tokenExpireMessage: userStepsData.message });
			}
		}
		
    }
	async getUserSteps(token){
		const response = await axios.get(`${process.env.REACT_APP_FRONTEND_URL}api/get/userStoreSteps/${token}`);
		if(response.data.isError === true){
			return response.data;
		}
		return response.data.leftUSerStoreResult;
	}
	async pageTracker(trackId, isverify = false) {
        try {
			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};
			const formData = new FormData();
			if(trackId === null || trackId ==="")
            // Fetch client IP
            {
				const ipResponse = await axios.get('https://api.ipify.org?format=json');
				const ip = ipResponse.data.ip;

				// Fetch client country using the IP
				const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
				const country = countryResponse.data.country_name;

				// Determine the device type
				let deviceType = 'Unknown';
				if (isMobile) {
					deviceType = 'Mobile';
				} else if (isTablet) {
					deviceType = 'Tablet';
				} else if (isBrowser) {
					deviceType = 'Browser';
				}
				// Storing Data
				
				formData.append("ip", ip);
				formData.append("device", deviceType);
				formData.append("country", country);
			}else{
				formData.append("track_id", trackId);
			}
			formData.append("landing_page_count", 0);
			formData.append("step_1_count", 0);
			if(isverify)
			{
				formData.append("plain_activated_count", 1);	
				formData.append("step_2_count", 0);
			}
			else
			{	
				formData.append("step_2_count", 1);
				formData.append("plain_activated_count", 0);
			}
			formData.append("built_store_count", 0);
            const pageTrackerResponse = await axios.post(
                `${process.env.REACT_APP_FRONTEND_URL}api/page-tracker`,
                formData,
                config
            );
            if (pageTrackerResponse.data.success === true) {
                localStorage.setItem("page_track_id", pageTrackerResponse.data.data._id);
            }
        } catch (error) {
            console.error('Error Page Track info:', error);
        }
    }
	back = (e) => {
		e.preventDefault();
		this.props.prevStep();
	};

	render() {
		const {
			handleChange,
			access_token,
			storename,
			username,
			password,
			shop_link,
			sendDataToParent,
			handleData,
			storeData,
			pickThemeError,
			themFileSubmit,
			productStoreSubmit,
			processStore,
			submitStoreData,
			handleShopifyLink,
			shopifyLink, 
			token, 
			email
		} = this.props;

		const handleCopy = (text, field) => {
			const textarea = document.createElement("textarea");
			textarea.value = text;
			document.body.appendChild(textarea);
			textarea.select();
			try {
				const successful = document.execCommand("copy");
				const msg = successful ? "successful" : "unsuccessful";
				if (successful) {
					this.setState({ copiedText: field });
					setTimeout(() => this.setState({ copiedText: "" }), 3000);
				}
				console.log("Fallback: Copying text command was " + msg);
			} catch (err) {
				console.error("Fallback: Oops, unable to copy", err);
			}
			document.body.removeChild(textarea);
		};
		// const known_emails = [
		// 	"triggeredejavu@gmail.com",
		// 	"aizazriazahmed@gmail.com",
		// 	"eazypm@hadz.cloud",
		// 	"info@hadibizri.com"
		// ]
		const handleNextStep = async (e) => {
			e.preventDefault();
			// if(known_emails.includes(email))
			// { 
			this.setState({new_disabled: true, showPopup:true });
			const submitStoreResponse = await submitStoreData();
			console.log(submitStoreResponse, submitStoreResponse.message);
			if (submitStoreResponse.status == "success") {
				let response = await processStore(e);
				if (response.status === "success") {
					try {
						const response = await axios.post(
							`${process.env.REACT_APP_FRONTEND_URL}api/user/addAffiliateCreatedStore`,
							{ affiliate_link: localStorage.getItem("affiliate_url") },
							{
								headers: {
									"Content-Type": "application/json"
								}
							}
						);
						console.log(response);
					} catch (error) {
						console.log(error);
					}
					this.setState({new_disabled: false, showPopup:false });
					localStorage.removeItem("affiliate_url");
					const obj = this;
					obj.props.nextStep();
				}else {
					this.setState({
						loading: false,
						new_disabled: false,
						submitError: response.message,
						showPopup:false
					});
				}
			} else {
				this.setState({
					loading: false,
					new_disabled: false,
					submitError: submitStoreResponse && submitStoreResponse.message ? submitStoreResponse.message : "Something went wrong!",
					showPopup:false
				});
			}
			// }
			// else
			// {			
			// 	this.setState({ showPopup: true });
			// 	try {
			// 		const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
			// 		await delay(20000); // Add a delay of 20 seconds
			// 		const submitStoreResponse = await submitStoreData();
			// 		console.log(submitStoreResponse);
			// 		if (submitStoreResponse.status == "success") {
			// 			await delay(12000);
			// 			const response = await productStoreSubmit(e);
						
			// 			if (response.status === "success") {
			// 				try {
			// 					const response = await axios.post(
			// 						`${process.env.REACT_APP_FRONTEND_URL}api/user/addAffiliateCreatedStore`,
			// 						{ affiliate_link: localStorage.getItem("affiliate_url") },
			// 						{
			// 							headers: {
			// 								"Content-Type": "application/json"
			// 							}
			// 						}
			// 					);
			// 					console.log(response);
			// 				} catch (error) {
			// 					console.log(error);
			// 				}
			// 				localStorage.removeItem("affiliate_url");
			// 				const obj = this;
			// 				obj.props.nextStep();
			// 			} else {
			// 				this.setState({
			// 					loading: false,
			// 					showPopup: false,
			// 					submitError: "something wrong! please try again."
			// 				});
			// 			}
			// 		} else {
			// 			this.setState({
			// 				loading: false,
			// 				showPopup: false,
			// 				submitError: submitStoreResponse.message
			// 			});
			// 		}
			// 	} catch (error) {
			// 		this.setState({
			// 			loading: false,
			// 			showPopup: false,
			// 			submitError: error.message
			// 		});
			// 	}
			// }
		};
		const submitAccessToken = async (e) => {
			e.preventDefault();
			this.setState({ loading: true });
			if (!storename) {
				this.setState({
					storenameError: "Store name required."
				});
			} else {
				this.setState({
					storenameError: ""
				});
				handleShopifyLink(`https://${storename}.myshopify.com/`);
			}
			if (storename) {
				
				const config = {
					headers: {
						"Content-Type": "application/json"
					}
				};
				try {
					const apiResponse = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/acounts/confirmedBasicPlan`,
						{
							store_name: storename
						}
					);
					if (apiResponse.data.status === "error") {
						// console.log("here");

						this.setState({
							// disabled: false,
							loading: false,
							storeError: apiResponse.data.message,
							successBox: false
						});
					} else {
						const trackId = localStorage.getItem("page_track_id");
						await this.pageTracker(trackId, true);
						const formData = new FormData();
						formData.append("businessname", storeData.businessname);
						formData.append("storename", storename);
						formData.append("theme_color", storeData.theme_color);
						formData.append("access_token", storeData.access_token);
						const storeThemeResponse = await axios.post(
							`${process.env.REACT_APP_FRONTEND_URL}api/upload/store/theme`,
							formData,
							config
						);
						if (storeThemeResponse.data.status == "success") {
							handleData({
								pickThemeError: "",
								theme_file_name: storeThemeResponse.data.theme_name,
								theme_id: storeThemeResponse.data.theme_id
							});
							this.setState({
								disabled: false,
								loading: false,
								successBox: true,
								storeError: ""
							});
						} else {
							this.setState({
								disabled: false,
								loading: false,
								successBox: true,
								storeError: storeThemeResponse.data.message
							});
						}
					}
				} catch (error) {
					// Handle errors from await
					this.setState({ loading: false });
					console.log(error);
				}
			} else {
				this.setState({ loading: false });
			}
		};
		const handleAccessClick = async (e) => {
			try {
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/user/addNewShopifyClick`,
					{ shopify_link: shopifyLink },
					{
						headers: {
							"Content-Type": "application/json"
						}
					}
				);
				console.log(response);
			} catch (error) {
				console.log(error);
			}
			// window.open("https://" + shop_link);
			window.open("https://admin.shopify.com/login");
		};

		const copy_svg = (
			<svg
				width="20"
				height="20"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z"
					stroke=""
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z"
					stroke=""
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</svg>
		);
		const arrow_svg = (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="20"
				height="12"
				viewBox="0 0 20 12"
				fill="none"
			>
				<path
					d="M0 6H18.3333"
					stroke="white"
					strokeWidth="2"
					strokeMiterlimit="10"
				/>
				<path
					d="M13.3336 1L18.3336 6L13.3336 11"
					stroke="white"
					strokeWidth="2"
					strokeMiterlimit="10"
				/>
			</svg>
		);
		const isMobile = window.innerWidth < 480;

		return (
			<div className="so-form">
				{this.state.showAccountExpiredPopup && <AccountExpiredPopup handleData={this.props.handleData} email={this.props.email} isOpen={true} />}
				{this.state.showTokenExpiredPopup && <TokenExpirePopup handleData={this.props.handleData} message={this.state.tokenExpireMessage} isOpen={true} />}
				{this.state.showPopup ? (
					this.state.new_disabled ? <InteractiveLoader isSimple={true} text={"We have started building your store, we need 5-30 minutes to complete it, you will receive an email when it is complete"} /> :
					<InteractiveLoader interval={this.state.loaderDuration} />
				) : (
					""
				)}
				<form>
					<StepperCompo count={1} />

				<h3 className="details">
					Access Shopify using the email and password below:
					</h3> 
					<p className="text-danger mb-4"><em>Don't Worry! You can change the email and password after you've signed up, and built your store. We setup the account for you, so you don't have to.</em></p>
					<div className="form-row">
						<div className="col-md-12">
							<div className="form-group step2_formgroup">
								<label className="form-group__label">
									<div className="d-flex align-items-center mini-head">
										<span className="number">1</span>
										<div className="text">
											Log in using the provided email and password:
										</div>
									</div>
								</label>
								<div className="creds d-flex align-items-center">
									<div className="left">
										<div className="item d-flex align-items-center flex-wrap">
											<p className="">Email:</p>
											<div className="d-flex">
												<strong>{username}</strong>
												<div
													className="copy"
													onClick={() => handleCopy(username, "username")}
												>
													{copy_svg}
													{this.state.copiedText === "username"
														? "Copied!"
														: "Copy"}
												</div>
											</div>
										</div>
										<div className="divider"></div>
										<div className="item d-flex align-items-center flex-wrap">
											<p className="">Password:</p>
											<div className="d-flex">
												<strong>{password}</strong>
												<div
													className="copy"
													onClick={() => handleCopy(password, "password")}
												>
													{copy_svg}
													{this.state.copiedText === "password"
														? "Copied!"
														: "Copy"}
												</div>
											</div>
										</div>
									</div>
									<div className="access-btn">
									{isMobile ? (
										<a
											rel="noopener noreferrer"
											className="so-access-btn"
											href="https://admin.shopify.com/login"
											target="_blank"
										>
											<img src={shopifyLogo} alt="shopify" /> Access Shopify
										</a>
									) : (
										<a
											rel="noopener noreferrer"
											className="so-access-btn"
											onClick={handleAccessClick}
											target="_blank"
										>
											<img src={shopifyLogo} alt="shopify" /> Access Shopify
										</a>
									)}
									</div>
								</div>
							</div>
						</div>
					</div>
					
					<div className="form-row">
						<div className="col-md-12">
							<div className="div_business sub-plan-card">
								<label className="d-flex align-items-start mini-head">
									<span className="number">2</span>
									<div className="text">
										Subscribe to the Basic Shopify Plan for $1/month
										{/* <PlanPopup handleData={handleData} /> */}
										<h6 className="mt-3 mb-3">Please click on "Select a plan" after you have logged into your Shopify account to activate your Shopify store</h6>
										<div className="text-center">
											<img src={PlanImage} alt="" className="img-fluid" />
										</div>
									</div>
									
								</label>
							</div>
						</div>
						
					</div>

					<div className="div_business so-relative">
						<label className="d-flex align-items-start mini-head">
							<span className="number">3</span>

							<div className="text">
								Verify Plan{" "}
							
							</div>
						</label>
						
						<div className="disclaimer mb-5">
							Disclaimer: Please make sure you are subscribed to the Shopify Basic Plan (see step 2) before clicking verify so we can build your store.
						</div>
						<button
							onClick={(e) => submitAccessToken(e)}
							disabled={this.state.loading}
							className={`btn ${
								this.state.successBox ? "d-none" : "d-block"
							} w-100 mx-auto veriy-btn`}
						>
							{this.state.loading ? (
									<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							) : (
								this.state.successBox ? "Verified" : "Verify"
							)}
						</button>

						<p className="error mt-4 text-center">{this.state.storeError}</p>
					</div>

					<div className="div_business mt-4">
						<p className="error">
							{this.state.submitError && this.state.submitError}
						</p>
					</div>

					{/* {this.state.loading && (
						<TailSpin
							height="80"
							width="80"
							color="#4fa94d"
							ariaLabel="tail-spin-loading"
							radius="1"
							wrapperStyle={{}}
							wrapperClass="custom-loader"
						/>
					)} */}
					
					<div className="footer-btns form-row">
						<div className="col-12">
							<button
								disabled={this.state.disabled || this.state.new_disabled}
								className={`buttons__button w-100 buttons__button--next mt-0 ${
									this.state.disabled ? "d-none" : ""
								} ${this.state.submitError ? "danger" : ''}`}
								onClick={(e) => handleNextStep(e)}
							>
							{this.state.new_disabled ? (
								<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
							) : (
								this.state.submitError ? (<span>Retry {arrow_svg}</span>) : (<span> Build Store {arrow_svg}</span>)
								
							)}
							</button>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

export default AccessStore;
