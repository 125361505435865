import React, { Component } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import step1_img from "../../images/access-store/image1.jpg";
import image_4 from "../../images/access-store/image_4.jpg";
import step6_img from "../../images/access-store/Screenshot_789.jpg";
import step7_img from "../../images/access-store/images_10.jpg";
import step8_img from "../../images/access-store/Screenshot_last.jpg";
import image_56 from "../../images/access-store/image_56.jpg";
import SimpleImageViewer from "react-simple-image-viewer";

class GuidLinePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			showViewer: false,
			currentImage: 0
		};
		this.openModal = this.openModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	openModal(e) {
		e.preventDefault();
		this.setState({ open: true });
		document.body.classList.add("no-scroll");
	}

	closeModal() {
		this.setState({ open: false });
		document.body.classList.remove("no-scroll");
	}

	openImageViewer = (index) => {
		this.setState({
			currentImage: index,
			showViewer: true
		});
	};

	closeImageViewer = () => {
		this.setState({
			currentImage: 0,
			showViewer: false
		});
	};

	render() {
		const { showViewer, currentImage } = this.state;
		const images = [
			step1_img,
			image_4,
			image_56,
			step6_img,
			step7_img,
			step8_img
		];

		// const images = [

		// ];

		return (
			<div>
				<span className="guidline" onClick={(e) => this.openModal(e)}>
					How to get access token?
				</span>
				<Popup
					open={this.state.open}
					onClose={this.closeModal}
					modal
					closeOnDocumentClick
					className="guidline-popup"
				>
					<div className="step2_formgroup">
						<div className="popup_title">
							<h4>
								How to get access token?
								<span
									class="material-symbols-outlined"
									onClick={this.closeModal}
								>
									x
								</span>
							</h4>
							<p>
								After creating a store, following steps needs to be followed to
								generate an access token.
							</p>
						</div>
						<ul>
							<li>
								<b>1. </b>
								<div className="title-info">
									Go to your Shopify store and click Settings &gt; Apps and
									sales channels.
								</div>
							</li>
							{[
								"Click Develop apps and then click Allow custom app development.",
								"Click Create an app and enter a name for your app1."
							].map((step, index) => (
								<li key={index}>
									<b>{index + 2}.</b>
									<div className="title-info">
										{step}
										{index < 2 && (
											<img
												src={images[index]}
												className="step-box-custom"
												alt="step 1"
												onClick={() => this.openImageViewer(index)}
											/>
										)}
									</div>
								</li>
							))}

							<li>
								<b>4. </b>
								<div className="title-info">
									Click the API credentials tab and then click Config Admin API
									scopes.
								</div>
							</li>
							<li className="allow_li">
								<div>
									<div className="title-info">
										<div className="liarrows">
											<b>5. </b>
											In Admin API access scopes section, search & allow
											permissions for below access scopes:
										</div>

										{images
											.slice(2)
											.map(
												(src, index) =>
													index < 1 && (
														<img
															src={src}
															onClick={() => this.openImageViewer(2 + index)}
															key={index}
															style={{ margin: "2px" }}
															alt=""
															className="step-box-custom"
														/>
													)
											)}
									</div>
								</div>

								<ul className="Scope_ul">
									<li className="innerList-box">
										Themes
										<ul>
											<li className="innerList-box">write_theme, read_theme</li>
										</ul>
									</li>
									<li className="innerList-box">
										Products
										<ul>
											<li className="innerList-box">
												write_products, read_products
											</li>
										</ul>
									</li>
									<li className="innerList-box">
										Product listings
										<ul>
											<li className="innerList-box">
												write_product_listing, read_product_listing
											</li>
										</ul>
									</li>
									<li className="innerList-box">
										Store content
										<ul>
											<li className="innerList-box">
												write_content, read_content
											</li>
										</ul>
									</li>
									<li className="innerList-box">
										Online Store pages
										<ul>
											<li className="innerList-box">
												write_online_store_pages, read_online_store_pages
											</li>
										</ul>
									</li>
									<li className="innerList-box">
										Metaobject definitions
										<ul>
											<li className="innerList-box">
												write_metaobject_definitions,
												read_metaobject_definitions
											</li>
										</ul>
									</li>
									<li className="innerList-box">
										Metaobject entries
										<ul>
											<li className="innerList-box">
												write_metaobjects, read_metaobjects
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li>
								<b>6. </b>
								<div className="title-info">
									In the Selected tab, make sure the count is 14 & above scopes
									are allowed and Click Save.
									{images
										.slice(3)
										.map(
											(src, index) =>
												index < 1 && (
													<img
														src={src}
														onClick={() => this.openImageViewer(3 + index)}
														key={index}
														style={{ margin: "2px" }}
														alt=""
														className="step-box-custom"
													/>
												)
										)}
								</div>
							</li>
							<li>
								<b>7. </b>
								<div className="title-info">
									Click Install app and then click Install app again.
									{images
										.slice(4)
										.map(
											(src, index) =>
												index < 1 && (
													<img
														src={src}
														onClick={() => this.openImageViewer(4 + index)}
														key={index}
														style={{ margin: "2px" }}
														alt=""
														className="step-box-custom"
													/>
												)
										)}
								</div>
							</li>
							<li>
								<b>8. </b>
								<div className="title-info">
									Copy your admin API access token from the API credentials tab.
									{images
										.slice(5)
										.map(
											(src, index) =>
												index < 1 && (
													<img
														src={src}
														onClick={() => this.openImageViewer(5 + index)}
														key={index}
														style={{ margin: "2px" }}
														alt=""
														className="step-box-custom"
													/>
												)
										)}
								</div>
							</li>
							<li>
								<b>9. </b>
								Go to our site and fill in the admin API access token in the
								access token input.
							</li>
						</ul>
						{showViewer && (
							<SimpleImageViewer
								src={images}
								currentIndex={currentImage}
								disableScroll={false}
								onClose={this.closeImageViewer}
								closeOnClickOutside={true}
							/>
						)}
					</div>
				</Popup>
			</div>
		);
	}
}

export default GuidLinePopup;
