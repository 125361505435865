import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import Swal from "sweetalert2";
function ShopifyLink() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchSingleData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};

	const fetchSingleData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getCurrentUser`,
				config
			);
			if (response.data.isError === false) {
				setFormData({
					shopify_link: response.data.data[0].shopify_link
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const [formData, setFormData] = useState({
		shopify_link: ""
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/updateShopifyLink`,
				formData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Update Shopify Link</h2>
			</div>
			<div className="body mt-4">
				<div className="row">
					<div className="col-md-6">
						<div class="card profile">
							<div class="card-body">
								<form onSubmit={handleSubmit}>
									<div className="form-group">
										<label htmlFor="">Shopify Link</label>
										<input
											type="url"
											className="form-control"
											name="shopify_link"
											placeholder="e.g. https://www.shopify.com/xyz"
											value={formData.shopify_link}
											onChange={handleChange}
											required
										/>
									</div>
									<button className="btn btn-gradient">Update</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ShopifyLink;
