import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Step1 from "../../images/steps/1.png";
import Step2 from "../../images/steps/2.png";
import { useNavigate } from 'react-router-dom';
const MobileStepsPopup = (props) => {
	const isMobile = window.innerWidth < 480;
	const [open, setOpen] = useState(false);
	const [isMobileView, setIsMobileView] = useState(isMobile);

	useEffect(() => {
		// This could be enhanced with a resize event listener for dynamic updates
		setIsMobileView(window.innerWidth < 480);
	}, []);

	const openModal = (e) => {
		e.preventDefault();
		setOpen(true);
		document.body.style.overflow = "hidden";
	};

	const closeModal = () => {
		setOpen(false);
		document.body.style.overflow = "unset";
	};
	const navigate = useNavigate();
	const gotoPage = () => {
		// window.snaptr("track", "APP_OPEN");
		// window.location.href = "/start-building-brand";
		navigate('/start-building-brand', { state: { email: '' } }); 
		// history.push({
		// 	pathname: '/start-building-brand',
		// 	state: { email: 'test@test.com' }
		//   });
	};

	return (
		<div>
			{isMobileView ? (
				<a
					className={
						props.isAffiliate ? "btn so-btn is-affiliate " : "btn so-btn"
					}
					// onClick={openModal}
					onClick={(e) => {
						e.preventDefault();
						gotoPage();
					}}
					rel="noopener noreferrer"
				>
					Get Your Free Store Now
					{/* <svg
						width="28"
						height="16"
						viewBox="0 0 28 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0 8H25.6667"
							stroke="white"
							stroke-width="2.5"
							stroke-miterlimit="10"
						/>
						<path
							d="M18.6667 1L25.6667 8L18.6667 15"
							stroke="white"
							stroke-width="2.5"
							stroke-miterlimit="10"
						/>
					</svg> */}
				</a>
			) : (
				<a
					onClick={(e) => {
						e.preventDefault();
						gotoPage();
					}}
					rel="noopener noreferrer"
					className={
						props.isAffiliate ? "btn so-btn is-affiliate " : "btn so-btn"
					}
				>
					Get Your Free Store Now
					{/* <svg
						width="28"
						height="16"
						viewBox="0 0 28 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M0 8H25.6667"
							stroke="white"
							stroke-width="2.5"
							stroke-miterlimit="10"
						/>
						<path
							d="M18.6667 1L25.6667 8L18.6667 15"
							stroke="white"
							stroke-width="2.5"
							stroke-miterlimit="10"
						/>
					</svg> */}
				</a>
			)}
			<Popup open={open} onClose={closeModal} modal closeOnDocumentClick>
				<div className="show-box">
					<h4>
						We recommend you use your computer for this, go to{" "}
						<a
							href="http://brandbldr.ai"
							target="_blank"
							rel="noopener noreferrer"
						>
							brandbldr.ai
						</a>{" "}
						on your computer to continue.
					</h4>
					<h4>
						You can proceed on mobile, however you must open this site from your
						phones browser.
					</h4>
					<h4 className="text-danger">
						Our App will not work properly in any "app browsers" (IG, Facebook,
						TikTok..) so follow these steps to open the app in your phones
						default browser:
					</h4>
					<ul>
						<li>Click on 3 dots menu and select "Open in external browser"</li>
					</ul>
					<div className="form-row">
						<div className="col-6">
							<img src={Step1} alt="" className="img-fluid" />
						</div>
						<div className="col-6">
							<img src={Step2} alt="" className="img-fluid" />
						</div>
					</div>
					{/* <a className="btn btn-general" onClick={(e) => this.openSwal(e)}>
							Yes
						</a>*/}
					<a
						className="btn btn-warning w-100 mt-4"
						// onClick={this.handleNoClick}
						href="/start-building-brand"
						rel="noopener noreferrer"
					>
						I confirm that I am in my phones browser
					</a>
				</div>
			</Popup>
		</div>
	);
};

export default MobileStepsPopup;
