import React, { useState, useCallback, useMemo, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import axios from "axios";
import Swal from "sweetalert2";
import AWS from "aws-sdk";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
function Profile() {
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		fetchSingleData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};

	const fetchSingleData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getCurrentUser`,
				config
			);
			if (response.data.isError === false) {
				setFormData({
					userId: response.data.data[0]._id,
					coverImage: response.data.data[0].coverImage,
					testimonial: response.data.data[0].testimonial,
					sigHeading: response.data.data[0].sigHeading,
					sigDiscription: response.data.data[0].sigDiscription
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const [formData, setFormData] = useState({
		userId: "",
		coverImage: "",
		testimonial: "",
		sigHeading: "",
		sigDiscription: ""
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/updateAffiliateProfile`,
				formData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				fetchSingleData();
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};
	const handleCoverSubmit = async (e) => {
		e.preventDefault();
		if (uploadedFilesPreview.length > 0) {
			setLoading(true);
			try {
				const img_upload = await handleUpload();
				if (img_upload.success == true) {
					const updatedFormData = {
						...formData,
						["coverImage"]: img_upload.urls[0]
					};
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/user/updateAffiliateProfile`,
						updatedFormData,
						config
					);
					if (response.status === 200 && response.data.isError === false) {
						setLoading(false);
						Swal.fire({
							icon: "success",
							title: response.data.message,
							showConfirmButton: false,
							timer: 2000 // milliseconds
						});
						fetchSingleData();
					} else {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: response.data.message,
							showConfirmButton: false,
							timer: 2000 // milliseconds
						});
					}
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "There was an error, please try again later",
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				console.error("Error:", error);
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Please upload cover image",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
		}
	};
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const onDrop = useCallback((acceptedFiles) => {
		setUploadedFiles(acceptedFiles);
	}, []);
	const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
	const REGION = process.env.REACT_APP_AWS_BUCKET_REGION;

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_BUCKET_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_BUCKET_SECRET_KEY
	});
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: false
	});
	const handleUpload = async () => {
		const s3 = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });

		try {
			const urls = [];

			for (const file of uploadedFiles) {
				const fileId = uuidv4();
				const params = {
					Bucket: S3_BUCKET,
					Key: `profile/${fileId}-${file.name}`,
					ContentType: file.type,
					Body: file
				};
				const response = await s3.upload(params).promise();
				const url = response.Location;

				urls.push(url);
			}

			console.log("All files uploaded successfully");
			setUploadedFiles([]);
			return { success: true, urls: urls };
		} catch (error) {
			console.error("S3 Upload Error:", error);
			return { success: false };
		}
	};
	const uploadedFilesPreview = useMemo(
		() =>
			uploadedFiles.map((file) => (
				<div className="img" key={file.name}>
					<img src={URL.createObjectURL(file)} alt={file.name} />
				</div>
			)),
		[uploadedFiles]
	);
	const dropzoneStyles = {
		border: "2px dashed rgba(0, 16, 32, 0.15)",
		borderRadius: "4px",
		padding: "60px 20px",
		textAlign: "center",
		cursor: "pointer",
		marginBottom: "0"
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Update My Profile</h2>
			</div>
			<div className="body mt-4">
				<div className="row">
					<div className="col-md-6 mb-4">
						<div class="card profile">
							<div class="card-body">
								<form onSubmit={handleCoverSubmit}>
									<div className="form-group">
										<label>Cover Image</label>
										<div {...getRootProps()} style={dropzoneStyles}>
											<p className="m-0">
												Drag & drop image here, or click to select file
											</p>
											<input {...getInputProps()} className="dropzone-input" />
										</div>
										<small className="text-danger">
											*Adding new image will replace previous image
										</small>
										{uploadedFilesPreview.length > 0 && (
											<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
												<h6>New Image</h6>
												{uploadedFilesPreview}
											</div>
										)}
										<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
											{formData.coverImage != null ? (
												<div className="img">
													<img
														src={formData.coverImage}
														alt={formData.coverImage}
													/>
												</div>
											) : (
												""
											)}
										</div>
									</div>
									<button type="submit" className="btn btn-gradient">
										Update Cover Image
									</button>
								</form>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div class="card profile">
							<div class="card-body">
								<form onSubmit={handleSubmit}>
									<div className="form-row">
										<div className="col-6">
											<div className="form-group">
												<label htmlFor="sigHeading">Sign Heading</label>
												<input
													type="text"
													className="form-control"
													name="sigHeading"
													placeholder="e.g. Jad Kantari"
													value={formData.sigHeading}
													id="sigHeading"
													onChange={handleChange}
													required
												/>
											</div>
										</div>
										<div className="col-6">
											<div className="form-group">
												<label htmlFor="sigDiscription">Sign Sub Heading</label>
												<input
													type="text"
													className="form-control"
													name="sigDiscription"
													placeholder="e.g. Jad Kantari"
													value={formData.sigDiscription}
													onChange={handleChange}
													id="sigDiscription"
													required
												/>
											</div>
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="testimonial">Testimonial Description</label>
										<textarea
											className="form-control"
											name="testimonial"
											cols="30"
											rows="5"
											id="testimonial"
											onChange={handleChange}
											value={formData.testimonial}
											required
										></textarea>
									</div>

									<button className="btn btn-gradient">Update</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Profile;
