import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import jwt from "jsonwebtoken";
import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
// Custom CSS
import "../css/dashboard.css";
import store_logo from "../images/store-logo/brandbldr-logo-dark.png";
function Login() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState({
		email: "",
		password: "",
		remember: true
	});
	const [loginComplete, setLoginComplete] = useState(false);
	const handleChange = (e) => {
		const { name, value, type, checked } = e.target;

		// If the input is a checkbox, update the 'remember' field with its checked state
		const newValue = type === "checkbox" ? checked : value;

		setFormData((prevData) => ({ ...prevData, [name]: newValue }));
	};
	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			// Make an HTTP request to your authentication API
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/login`,
				{
					email: formData.email,
					password: formData.password
				}
			);
			if (response.data.isError === false) {
				// Assuming your API returns a token upon successful login
				const token = response.data.token;
				const role = response.data.role;
				const userName = response.data.userName;
				// Store the token in localStorage or a secure storage solution
				const decodedToken = jwt.decode(token, { complete: true });
				console.log(decodedToken.payload.exp);
				localStorage.setItem("token", token);
				localStorage.setItem("userName", userName);
				localStorage.setItem("role", role);
				localStorage.setItem("lastLoginTime", decodedToken.payload.exp);
				setLoading(false);
				if (token && parseInt(role) === 5150) {
					navigate("/admin/home");
				} else if (token && parseInt(role) === 2004) {
					navigate("/user/home");
				}
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			console.error("Login failed", error);
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error please try again later!",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// Handle login failure (show error message, etc.)
		} finally {
			// Set loginComplete to true when handleLogin is complete
			setLoginComplete(true);
		}
	};

	useEffect(() => {
		// Check if the token is set in localStorage or sessionStorage
		const token = localStorage.getItem("token");

		const role = localStorage.getItem("role");
		if (token && parseInt(role) === 5150) {
			// Redirect to the admin route if the token is present
			navigate("/admin/home");
		} else if (token && parseInt(role) === 2004) {
			navigate("/user/home");
		}
	}, [loginComplete, navigate]);
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}

			<section id="login">
				<div className="login-card card">
					<div className="logo">
						<img src={store_logo} alt="Brandbldr Logo" className="img-fluid" />
					</div>
					<div className="headings">
						<h1>Welcome Back</h1>
						<h3>Welcome to the Dashboard</h3>
					</div>
					<div className="card-body">
						<form onSubmit={handleLogin}>
							<div className="form-group">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									placeholder="John Micheal@gmail.com"
									name="email"
									id="email"
									value={formData.email}
									onChange={handleChange}
									className="form-control"
									required
								/>
							</div>
							<div className="form-group">
								<label htmlFor="password">Password</label>
								<input
									type="password"
									placeholder="****************"
									name="password"
									id="password"
									value={formData.password}
									onChange={handleChange}
									className="form-control"
									required
								/>
							</div>
							<div className="d-flex align-items-center justify-content-between info">
								<div className="remember-me">
									<label htmlFor="remember">
										<input
											type="checkbox"
											name="remember"
											id="remember"
											checked={formData.remember}
											onChange={handleChange}
										/>
										Remember me
									</label>
								</div>
								<div className="forgot-password">
									<a href="#">Forgot Password?</a>
								</div>
							</div>
							<button type="submit" className="btn btn-submit btn-gradient">
								Log in
							</button>
						</form>
						<div className="text-center footer">
							Don’t have an account? <a href="#">Sign up</a>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default Login;
