import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const Continue = () => {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const step = queryParams.get('step');
        if(step == 1){
            const email = queryParams.get('email');
            navigate('/start-building-brand', { state: {email: email, step:1} });
        }else if(step == 2){
            const token = queryParams.get('token');
            navigate('/start-building-brand', { state: {token: token, step:2} });
        }else {
            navigate('/');
        }
    }, []);
    return (<></>);
};
export default Continue;