import React, { useState, useEffect } from "react";
import "../../css/loader.css";
function InteractiveLoader(props) {
	useEffect(() => {
		// Add your class to the body element
		document.body.classList.add("no-scroll");

		// Optionally, you can remove the class when the component unmounts
		return () => {
			document.body.classList.remove("no-scroll");
		};
	}, []);
	const textArray = [
		"Creating your Store",
		"Connecting to Shopify",
		"Applying Customizations",
		"Adding your Winning Products",
		"Adding Unique Descriptions",
		"Adding Additional Product Data",
		"Hang in there, finalizing!",
		"Done .. Let's Visit your Store"
	];
	const [progress, setProgress] = useState(0);
	const [text, setText] = useState(props.isSimple ? props.text : textArray[0]);

	useEffect(() => {
		if(!props.isSimple){
			const totalTimeInSeconds = props.interval;
			const interval = 1000; // Update every second
			const steps = totalTimeInSeconds / interval;

			const updateProgress = () => {
				setProgress((prevProgress) => {
					const newProgress = prevProgress + 100 / steps;
					if (newProgress > 0 && newProgress < 20) {
						setText(textArray[0]);
					}
					if (newProgress > 20 && newProgress < 30) {
						setText(textArray[1]);
					}
					if (newProgress > 30 && newProgress < 40) {
						setText(textArray[2]);
					}
					if (newProgress > 50 && newProgress < 60) {
						setText(textArray[3]);
					}
					if (newProgress > 70 && newProgress < 80) {
						setText(textArray[4]);
					}
					if (newProgress > 70 && newProgress < 80) {
						setText(textArray[5]);
					}
					if (newProgress > 80 && newProgress < 90) {
						setText(textArray[6]);
					}
					if (newProgress > 90) {
						setText(textArray[7]);
					}
					return newProgress >= 100 ? 100 : newProgress;
				});
			};

			const intervalId = setInterval(updateProgress, interval);

			// Clear the interval when the component unmounts
			return () => clearInterval(intervalId);
			}
	}, []);
	return (
		<>
			<div className="popup-overlay">
				<div className="popup-body">
					<div className="preloader">
						<svg
							className="cart"
							role="img"
							aria-label="Shopping cart line animation"
							viewBox="0 0 128 128"
							width="128px"
							height="128px"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="8"
							>
								<g className="cart__track" stroke="hsla(0,10%,10%,0.1)">
									<polyline points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80" />
									<circle cx="43" cy="111" r="13" />
									<circle cx="102" cy="111" r="13" />
								</g>
								<g className="cart__lines" stroke="">
									<polyline
										className="cart__top"
										points="4,4 21,4 26,22 124,22 112,64 35,64 39,80 106,80"
										strokeDasharray="338 338"
										strokeDashoffset="-338"
									/>
									<g className="cart__wheel1" transform="rotate(-90,43,111)">
										<circle
											className="cart__wheel-stroke"
											cx="43"
											cy="111"
											r="13"
											strokeDasharray="81.68 81.68"
											strokeDashoffset="81.68"
										/>
									</g>
									<g className="cart__wheel2" transform="rotate(90,102,111)">
										<circle
											className="cart__wheel-stroke"
											cx="102"
											cy="111"
											r="13"
											strokeDasharray="81.68 81.68"
											strokeDashoffset="81.68"
										/>
									</g>
								</g>
							</g>
						</svg>
						{
							props.isSimple ? "" : (
								<div className="preloader__text">
									<div className="loader-container">
										<div
											className="progress-bar"
											style={{ width: `${parseInt(progress)}%` }}
										>
											{parseInt(progress)}%
										</div>
									</div>
								</div>
							)
						}
						<p className="text">{text}</p>
						<div className="footer-text">
							<div className="disclaimer">
								Hang on! You'll be redirected as this popup self-closes in a few seconds.
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default InteractiveLoader;
