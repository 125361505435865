import React, { useState, useEffect, useCallback, useMemo } from "react";
import { TailSpin } from "react-loader-spinner";
import DataTable from "react-data-table-component";
import { useDropzone } from "react-dropzone";
import AWS from "aws-sdk";
import Tooltip from "react-tooltip";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import $ from "jquery";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
function ProductManagement() {
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		fetchData();
	}, []);
	const config = {
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${localStorage.getItem("token")}`
		}
	};
	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/store/getAllProducts`,
				config
			);
			if (response.data.isError === false) {
				const sortedData = response.data.data.sort(
					(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
				);
				setRecords(sortedData);
				setfullData(sortedData);
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const fetchSingleData = async (id) => {
		setLoading(true);
		try {
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/store/getProductById`,
				{
					productId: id
				},
				config
			);
			if (response.data.isError === false) {
				setEditFormData({
					productId: response.data.data._id,
					title: response.data.data.title,
					category: response.data.data.category,
					images_url: response.data.data.images_url,
					price: response.data.data.price,
					link_1: response.data.data.link_1,
					photo_url_1: response.data.data.photo_url_1,
					description: response.data.data.description,
					category_description: response.data.data.category_description,
					overview: response.data.data.overview,
					overview_discription: response.data.data.overview_discription[0],
					first_img_heading: response.data.data.first_img_heading,
					first_img_description: response.data.data.first_img_description,
					second_img_heading: response.data.data.second_img_heading,
					second_img_description: response.data.data.second_img_description,
					block_title: response.data.data.block_title,
					blocka_heading: response.data.data.blocka_heading,
					blocka_description: response.data.data.blocka_description,
					blockb_heading: response.data.data.blockb_heading,
					blockb_description: response.data.data.blockb_description,
					blockc_heading: response.data.data.blockc_heading,
					blockd_description: response.data.data.blockd_description,
					images: response.data.data.images
					// ... update other properties based on the response
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	// ];
	const close_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
		>
			<path
				d="M13.3596 0.640137L0.639648 13.3601"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.3596 13.3601L0.639648 0.640137"
				stroke="white"
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const edit_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M14.0345 1L2.39655 12.6379L1 19L7.36207 17.6034L19 5.96552L14.0345 1Z"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.362 17.6035L2.39648 12.6379"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M17.0602 7.90521L12.0947 2.9397"
				stroke="#069399"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const delete_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
		>
			<path
				d="M15.6667 19.0001H4.33337C3.23004 19.0001 2.33337 18.1034 2.33337 17.0001V5.66675H17.6667V17.0001C17.6667 18.1034 16.77 19.0001 15.6667 19.0001Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.33337 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M10 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.6666 9.33325V15.3333"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M19 5.66675H1V4.33341C1 3.41341 1.74667 2.66675 2.66667 2.66675H17.3333C18.2533 2.66675 19 3.41341 19 4.33341V5.66675Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6667 2.66667H7.33337V1.66667C7.33337 1.3 7.63337 1 8.00004 1H12C12.3667 1 12.6667 1.3 12.6667 1.66667V2.66667Z"
				stroke="#D30C0C"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const view_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
		>
			<path d="M15 12c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3zm9-.449s-4.252 8.449-11.985 8.449c-7.18 0-12.015-8.449-12.015-8.449s4.446-7.551 12.015-7.551c7.694 0 11.985 7.551 11.985 7.551zm-7 .449c0-2.757-2.243-5-5-5s-5 2.243-5 5 2.243 5 5 5 5-2.243 5-5z" />
		</svg>
	);
	const info_svg = (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="20"
			viewBox="0 0 16 16"
			id="Info"
		>
			<g fill="#444444" data-name="Layer 2" className="color231f20 svgShape">
				<path
					d="M8 2a6 6 0 1 0 6 6 6 6 0 0 0-6-6Zm0 11a5 5 0 1 1 5-5 5 5 0 0 1-5 5Z"
					fill="#444444"
					className="color000000 svgShape"
				></path>
				<path
					d="M8 6.85a.5.5 0 0 0-.5.5v3.4a.5.5 0 0 0 1 0v-3.4a.5.5 0 0 0-.5-.5zM8 4.8a.53.53 0 0 0-.51.52v.08a.47.47 0 0 0 .51.47.52.52 0 0 0 .5-.5v-.12A.45.45 0 0 0 8 4.8z"
					fill="#444444"
					className="color000000 svgShape"
				></path>
			</g>
		</svg>
	);
	const prev_svg = (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12H20M4 12L8 8M4 12L8 16"
				stroke="#149CC4"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const next_svg = (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 12H20M20 12L16 8M20 12L16 16"
				stroke="#149CC4"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
	const truncateText = (text, limit) => {
		const words = text.split(" ");
		const truncated = words.slice(0, limit).join(" ");

		return words.length > limit ? truncated + "..." : truncated;
	};
	const CustomDescriptionCell = ({ row }) => (
		<>
			<div>
				{truncateText(row.description, 10)}{" "}
				<span
					className="cursor-pointer"
					data-tip
					data-for={"description" + row._id}
				>
					{info_svg}
				</span>
			</div>
			<Tooltip id={"description" + row._id} effect="solid" place="bottom">
				<div style={{ width: "200px" }}>{row.description}</div>
			</Tooltip>
		</>
	);

	const CustomProductLinkCell = ({ row }) => (
		<a href={row.link_1} target="_blank" rel="noopener noreferrer">
			Link
		</a>
	);
	const CustomProductPhotoLinkCell = ({ row }) => (
		<a href={row.photo_url_1} target="_blank" rel="noopener noreferrer">
			Link
		</a>
	);
	const CustomPriceCell = ({ row }) => (
		<span className="price">${row.price.toFixed(2)}</span>
	);
	const CustomActions = ({ row }) =>
		row.isActive === true ? (
			<div className="action-btns">
				<button
					onClick={() => ProductClick(row._id, "viewProduct")}
					className="btn btn-view"
					data-id={row._id}
				>
					{view_svg}
				</button>
				<button
					onClick={() => ProductClick(row._id, "editProduct")}
					className="btn btn-edit"
					data-id={row._id}
				>
					{edit_svg}
				</button>
				<button
					onClick={() => deleteProductClick(row._id)}
					className="btn btn-delete"
				>
					{delete_svg}
				</button>
			</div>
		) : (
			<div className="action-btns">
				<button
					onClick={() => ProductClick(row._id, "viewProduct")}
					className="btn btn-view"
					data-id={row._id}
				>
					{view_svg}
				</button>
			</div>
		);
	const CustomStatusCell = ({ row }) =>
		row.isActive === true ? (
			<span className="badge badge-pill badge-success">Active</span>
		) : (
			<span className="badge badge-pill badge-danger">Disabled</span>
		);
	const columns = [
		{
			name: "Product Name",
			selector: (row) => row.title,
			sortable: true,
			wrap: true,
			maxWidth: "10%"
		},
		{
			name: "Category",
			selector: (row) => row.category,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Description",
			cell: (row) => <CustomDescriptionCell row={row} />,
			sortable: false,
			maxWidth: "25%",
			wrap: true
		},
		{
			name: "Price",
			cell: (row) => <CustomPriceCell row={row} />,
			selector: (row) => row.price,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Product Link",
			cell: (row) => <CustomProductLinkCell row={row} />,
			sortable: false,
			maxWidth: "10%"
		},
		{
			name: "Image Link",
			cell: (row) => <CustomProductPhotoLinkCell row={row} />,
			sortable: false,
			maxWidth: "10%"
		},
		{
			name: "Status",
			cell: (row) => <CustomStatusCell row={row} />,
			selector: (row) => row.isActive,
			sortable: true,
			maxWidth: "10%"
		},
		{
			name: "Action",
			cell: (row) => <CustomActions row={row} />,
			sortable: false,
			maxWidth: "15%"
		}
	];
	const [fullData, setfullData] = useState("");
	const [records, setRecords] = useState("");
	const [currentIndex, setCurrentIndex] = useState(0);
	function handleFilter(e) {
		if (e.target.value.toLowerCase() != "") {
			const filterData = records.filter((row) => {
				return row["title"]
					.toLowerCase()
					.includes(e.target.value.toLowerCase());
			});
			setRecords(filterData);
		} else {
			setRecords(fullData);
		}
	}
	const productCategoryObj = [
		"Baby Products",
		"Childcare Niche",
		"Beauty and Skincare Niche",
		"Health & Wellness Niche",
		"Tech and Gadgets Niche",
		"Home Decor Niche",
		"Fashion and Accessories Niche",
		"Pet Supplies Niche", 
		"Home Office and Productivity Niche",
		"Travel and Outdoor Adventure Niche"
	];
	const [formData, setFormData] = useState({
		title: "",
		category: "Baby Products",
		images_url: `${process.env.REACT_APP_URL}images/dummy.png`,
		price: "",
		link_1: "",
		description: "",
		category_description: "",
		overview: "",
		overview_discription: "",
		first_img_heading: "",
		first_img_description: "",
		second_img_heading: "",
		second_img_description: "",
		block_title: "",
		blocka_heading: "",
		blocka_description: "",
		blockb_heading: "",
		blockb_description: "",
		blockc_heading: "",
		blockd_description: ""
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name == "overview_discription") {
			setFormData((prevData) => ({ ...prevData, [name]: [value] }));
		} else setFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const [editFormData, setEditFormData] = useState({
		productId: "",
		title: "",
		category: "Baby Products",
		images_url: `${process.env.REACT_APP_URL}images/dummy.png`,
		price: "",
		link_1: "",
		photo_url_1: "",
		description: "",
		category_description: "",
		overview: "",
		overview_discription: "",
		first_img_heading: "",
		first_img_description: "",
		second_img_heading: "",
		second_img_description: "",
		block_title: "",
		blocka_heading: "",
		blocka_description: "",
		blockb_heading: "",
		blockb_description: "",
		blockc_heading: "",
		blockd_description: "",
		images: []
	});
	const handleEditChange = (e) => {
		const { name, value } = e.target;
		setEditFormData((prevData) => ({ ...prevData, [name]: value }));
	};
	const handleEditSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);
		try {
			let updatedFormData = {};
			if (uploadedFilesPreview.length > 0) {
				const img_upload = await handleEditUpload();
				console.log(img_upload);
				if (img_upload.success == true) {
					updatedFormData = {
						...editFormData,
						["photo_url_1"]: img_upload.urls[0],
						["images"]: img_upload.urls
					};
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
			} else {
				updatedFormData = editFormData;
			}
			updatedFormData.overview_discription = [
				updatedFormData.overview_discription
			];

			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/store/updateProduct`,
				updatedFormData,
				config
			);
			if (response.status === 200 && response.data.isError === false) {
				setLoading(false);
				// Reset the form if needed
				// $("#editProduct").modal("hide");
				fetchData();
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				// setEditFormData({
				// 	_id: "",
				// 	title: "",
				// 	category: "",
				// 	images_url: `${process.env.REACT_APP_URL}images/dummy.png`,
				// 	price: "",
				// 	link_1: "",
				// 	photo_url_1: "",
				// 	description: "",
				// 	category_description: ""
				// });
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			console.error("Error:", error);
		}
	};

	// Add functions to handle previous and next buttons
	const handlePrevious = () => {
		setCurrentIndex(
			(prevIndex) => (prevIndex - 1 + fullData.length) % fullData.length
		);
	};
	const handleNext = () =>
		setCurrentIndex((prevIndex) => (prevIndex + 1) % fullData.length);
	useEffect(() => {
		setLoading(true);
		if (fullData) {
			setEditFormData({
				productId: fullData[currentIndex]._id,
				title: fullData[currentIndex].title,
				category: fullData[currentIndex].category,
				images_url: fullData[currentIndex].images_url,
				price: fullData[currentIndex].price,
				link_1: fullData[currentIndex].link_1,
				photo_url_1: fullData[currentIndex].photo_url_1,
				description: fullData[currentIndex].description,
				category_description: fullData[currentIndex].category_description,
				overview: fullData[currentIndex].overview,
				overview_discription: fullData[currentIndex].overview_discription[0],
				first_img_heading: fullData[currentIndex].first_img_heading,
				first_img_description: fullData[currentIndex].first_img_description,
				second_img_heading: fullData[currentIndex].second_img_heading,
				second_img_description: fullData[currentIndex].second_img_description,
				block_title: fullData[currentIndex].block_title,
				blocka_heading: fullData[currentIndex].blocka_heading,
				blocka_description: fullData[currentIndex].blocka_description,
				blockb_heading: fullData[currentIndex].blockb_heading,
				blockb_description: fullData[currentIndex].blockb_description,
				blockc_heading: fullData[currentIndex].blockc_heading,
				blockd_description: fullData[currentIndex].blockd_description,
				images: fullData[currentIndex].images
				// ... update other properties based on the response
			});
		}
		setLoading(false);
	}, [currentIndex]);
	const ProductClick = async (e, id) => {
		setLoading(true);
		const index = fullData.findIndex((record) => record._id === e);
		setCurrentIndex(index);
		try {
			// Wait for fetchSingleData to complete
			await fetchSingleData(e);
			setLoading(false);
			let modal = $(`#${id}`);
			modal.modal("show");
		} catch (error) {
			setLoading(false);
			console.error("Error fetching single data:", error);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// Handle the error if needed
		}
	};
	const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
	const handleEditOpen = async (e) => {
		e.preventDefault();
		setLoading(true);
		$("#viewProduct").modal("hide");
		await delay(500);
		$("#editProduct").modal("show");
		setLoading(false);
	};
	const deleteProductClick = async (e) => {
		Swal.fire({
			title: "Are you sure?",
			text: "You won't be able to revert this!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#25E5C3",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, delete it!"
		}).then(async (result) => {
			if (result.isConfirmed) {
				setLoading(true);
				const response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/store/deleteProduct`,
					{
						productId: e
					},
					config
				);
				if (response.data.isError === false) {
					setLoading(false);
					Swal.fire({
						icon: "success",
						title: response.data.message,
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
				fetchData();
			}
		});
	};
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (uploadedFilesPreview.length > 0) {
			setLoading(true);
			try {
				const img_upload = await handleUpload();
				if (img_upload.success == true) {
					const updatedFormData = {
						...formData,
						["photo_url_1"]: img_upload.urls[0],
						["images"]: img_upload.urls
					};
					const response = await axios.post(
						`${process.env.REACT_APP_FRONTEND_URL}api/store/addProduct`,
						updatedFormData,
						config
					);
					if (response.status === 200 && response.data.isError === false) {
						setLoading(false);
						// Reset the form if needed
						$("#addProduct").modal("hide");
						fetchData();
						Swal.fire({
							icon: "success",
							title: response.data.message,
							showConfirmButton: false,
							timer: 2000 // milliseconds
						});
						setFormData({
							title: "",
							category: "",
							images_url: `${process.env.REACT_APP_URL}images/dummy.png`,
							price: "",
							link_1: "",
							photo_url_1: "",
							description: "",
							category_description: ""
						});
					} else {
						setLoading(false);
						Swal.fire({
							icon: "error",
							title: response.data.message,
							showConfirmButton: false,
							timer: 2000 // milliseconds
						});
					}
				} else {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "There was an error, please try again later",
						showConfirmButton: false,
						timer: 2000 // milliseconds
					});
				}
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "There was an error, please try again later",
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				console.error("Error:", error);
			}
		} else {
			Swal.fire({
				icon: "error",
				title: "Please upload atleast one image",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
		}
	};
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const onDrop = useCallback((acceptedFiles) => {
		setUploadedFiles(acceptedFiles);
	}, []);
	const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET_NAME;
	const REGION = process.env.REACT_APP_AWS_BUCKET_REGION;

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_AWS_BUCKET_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_AWS_BUCKET_SECRET_KEY
	});

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: true
	});
	const handleUpload = async () => {
		const s3 = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });

		try {
			const urls = [];

			for (const file of uploadedFiles) {
				const fileId = uuidv4();
				const params = {
					Bucket: S3_BUCKET,
					Key: `images/${fileId}-${file.name}`,
					ContentType: file.type,
					Body: file
				};
				const response = await s3.upload(params).promise();
				const url = response.Location;

				urls.push(url);
			}

			console.log("All files uploaded successfully");
			setUploadedFiles([]);
			return { success: true, urls: urls };
		} catch (error) {
			console.error("S3 Upload Error:", error);
			return { success: false };
		}
	};
	const handleEditUpload = async () => {
		const s3 = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });

		try {
			editFormData.images.forEach((element) => {
				const parts = element.split("/");

				// Get the last part of the array
				const desiredPart = parts[parts.length - 1];
				console.log("images/" + desiredPart);
				const del_params = {
					Bucket: S3_BUCKET,
					Key: "images/" + desiredPart
				};

				// Delete the file
				s3.deleteObject(del_params, (err, data) => {
					if (err) {
						console.error("Error deleting file:", err);
					} else {
						console.log("File deleted successfully");
					}
				});
			});
			const urls = [];

			for (const file of uploadedFiles) {
				const fileId = uuidv4();
				const params = {
					Bucket: S3_BUCKET,
					Key: `images/${fileId}-${file.name}`,
					ContentType: file.type,
					Body: file
				};
				const response = await s3.upload(params).promise();
				const url = response.Location;

				urls.push(url);
			}

			console.log("All files uploaded successfully");
			setUploadedFiles([]);
			return { success: true, urls: urls };
		} catch (error) {
			console.error("S3 Upload Error:", error);
			return { success: false };
		}
	};
	const uploadedFilesPreview = useMemo(
		() =>
			uploadedFiles.map((file) => (
				<div className="img" key={file.name}>
					<img src={URL.createObjectURL(file)} alt={file.name} />
				</div>
			)),
		[uploadedFiles]
	);
	const dropzoneStyles = {
		border: "2px dashed rgba(0, 16, 32, 0.15)",
		borderRadius: "4px",
		padding: "20px",
		textAlign: "center",
		cursor: "pointer",
		marginBottom: "20px"
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}

			<div className="header">
				<h2>Products Management</h2>
				<div className="btns">
					<button className="btn btn-outline">
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16 1H4C2.34315 1 1 2.34315 1 4V16C1 17.6569 2.34315 19 4 19H16C17.6569 19 19 17.6569 19 16V4C19 2.34315 17.6569 1 16 1Z"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10.12 15V5"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M6 9.11L10.12 5L14.23 9.11"
									stroke="#069399"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Bulk Upload
					</button>
					<button
						className="btn btn-gradient"
						data-toggle="modal"
						data-target="#addProduct"
					>
						<div className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M10 5.13989V14.8599"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
								<path
									d="M14.8601 10H5.14014"
									stroke="white"
									strokeMiterlimit="10"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</div>
						Add Product
					</button>

					<div
						className="modal fade so-modal"
						id="addProduct"
						tabIndex="-1"
						role="dialog"
						aria-labelledby="modelTitleId"
						aria-hidden="true"
					>
						<div className="modal-dialog" role="document">
							<div className="modal-content">
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
								>
									{close_svg}
								</button>
								<div className="modal-body">
									<h2>Add New Product</h2>

									<form onSubmit={handleSubmit} className="row">
										<div className="col-md-6">
											<div className="form-group">
												<label htmlFor="">Product Name</label>
												<input
													type="text"
													className="form-control"
													name="title"
													value={formData.title}
													onChange={handleChange}
													required
												/>
											</div>
											<div className="form-group">
												<label htmlFor="">Category</label>
												<select
													className="form-control"
													name="category"
													required
													value={formData.category}
													onChange={handleChange}
												>
													{productCategoryObj.map((category, index) => (
														<option key={index} value={category}>
															{category}
														</option>
													))}
												</select>
											</div>
											<div className="form-group">
												<label htmlFor="">Category Description</label>
												<textarea
													className="form-control"
													name="category_description"
													value={formData.category_description}
													onChange={handleChange}
													cols="30"
													rows="3"
													required
												></textarea>
											</div>
											<div className="form-group">
												<label htmlFor="">Price</label>
												<input
													type="number"
													className="form-control"
													name="price"
													value={formData.price}
													onChange={handleChange}
													required
												/>
											</div>
											<div className="form-group">
												<label htmlFor="">Product Link</label>
												<input
													type="url"
													placeholder="e.g. https://www.xyz.com"
													className="form-control"
													name="link_1"
													value={formData.link_1}
													onChange={handleChange}
													required
												/>
											</div>

											<div className="form-group">
												<label htmlFor="">Images</label>

												<div {...getRootProps()} style={dropzoneStyles}>
													<p className="m-0">
														Drag & drop images here, or click to select files
													</p>
													<input
														{...getInputProps()}
														className="dropzone-input"
													/>
												</div>
												{uploadedFilesPreview.length > 0 && (
													<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
														{uploadedFilesPreview}
													</div>
												)}
											</div>
											<div className="form-group">
												<label htmlFor="">Product Description</label>
												<textarea
													className="form-control"
													name="description"
													value={formData.description}
													onChange={handleChange}
													cols="30"
													rows="4"
													required
												></textarea>
											</div>
										</div>
										<div className="col-md-6">
											<div className="form-group separator">
												<h4>Overview</h4>
												<div className="form-row">
													<div className="col-6">
														<label htmlFor="">Description</label>
														<textarea
															name="overview"
															className="form-control"
															value={formData.overview}
															onChange={handleChange}
															cols="30"
															rows="3"
														></textarea>
													</div>
													<div className="col-6">
														<label htmlFor="">Decription for bullets </label>
														<textarea
															name="overview_discription"
															className="form-control"
															placeholder="e.g. text 1&#13;text 2 &#13;text 3"
															// value={formData.overview_discription}
															onBlur={handleChange}
															cols="30"
															rows="3"
														></textarea>
														<small className="text-danger">
															*Separated by line
														</small>
													</div>
												</div>
											</div>
											<div className="separator">
												<h4>Image Block 1</h4>
												<div className="form-group">
													<label htmlFor="">Heading</label>
													<input
														type="text"
														name="first_img_heading"
														value={formData.first_img_heading}
														onChange={handleChange}
														className="form-control"
													/>
												</div>
												<div className="form-group">
													<label htmlFor="">Description</label>
													<textarea
														className="form-control"
														name="first_img_description"
														value={formData.first_img_description}
														onChange={handleChange}
														cols="30"
														rows="3"
													></textarea>
												</div>
											</div>
											<div className="separator">
												<h4>Image Block 2</h4>
												<div className="form-group">
													<label htmlFor="">Heading</label>
													<input
														type="text"
														name="second_img_heading"
														value={formData.second_img_heading}
														onChange={handleChange}
														className="form-control"
													/>
												</div>
												<div className="form-group">
													<label htmlFor="">Description</label>
													<textarea
														className="form-control"
														name="second_img_description"
														value={formData.second_img_description}
														onChange={handleChange}
														cols="30"
														rows="3"
													></textarea>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="big-one">
												<div className="">
													<h4>Block Title</h4>
													<div className="form-group">
														<input
															type="text"
															name="block_title"
															value={formData.block_title}
															onChange={handleChange}
															className="form-control"
														/>
													</div>
												</div>
												<div className="">
													<div className="row">
														<div className="col-md-4">
															<h4>Block A</h4>
															<div className="form-group">
																<label htmlFor="">Heading</label>
																<input
																	type="text"
																	name="blocka_heading"
																	value={formData.blocka_heading}
																	onChange={handleChange}
																	className="form-control"
																/>
																<label htmlFor="">Description</label>
																<textarea
																	className="form-control"
																	name="blocka_description"
																	value={formData.blocka_description}
																	onChange={handleChange}
																	cols="30"
																	rows="3"
																></textarea>
															</div>
														</div>
														<div className="col-md-4">
															<h4>Block B</h4>
															<div className="form-group">
																<label htmlFor="">Heading</label>
																<input
																	type="text"
																	name="blockb_heading"
																	value={formData.blockb_heading}
																	onChange={handleChange}
																	className="form-control"
																/>
																<label htmlFor="">Description</label>
																<textarea
																	className="form-control"
																	name="blockb_description"
																	value={formData.blockb_description}
																	onChange={handleChange}
																	cols="30"
																	rows="3"
																></textarea>
															</div>
														</div>
														<div className="col-md-4">
															<h4>Block C</h4>
															<div className="form-group">
																<label htmlFor="">Heading</label>
																<input
																	type="text"
																	name="blockc_heading"
																	value={formData.blockc_heading}
																	onChange={handleChange}
																	className="form-control"
																/>
																<label htmlFor="">Description</label>
																<textarea
																	className="form-control"
																	name="blockd_description"
																	value={formData.blockd_description}
																	onChange={handleChange}
																	cols="30"
																	rows="3"
																></textarea>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-12">
											<button className="btn btn-gradient">Save Product</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="body">
				<div className="table">
					<div className="text-end">
						<input type="text" placeholder="Search" onChange={handleFilter} />
					</div>

					<DataTable columns={columns} data={records} pagination></DataTable>
				</div>
			</div>

			{/* edit modal  */}
			<div
				className="modal so-modal fade"
				id="editProduct"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<div className="d-flex align-items-center justify-content-center mb-4">
								<div className="prev cursor-pointer" onClick={handlePrevious}>
									{prev_svg}
								</div>
								<div className="title mx-4">
									<h2 className="mb-0">Edit Product</h2>
								</div>
								<div className="next cursor-pointer" onClick={handleNext}>
									{next_svg}
								</div>
							</div>
							{/* <h2>Edit Product</h2> */}
							<form onSubmit={handleEditSubmit} className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="">Product Name</label>
										<input
											type="text"
											className="form-control"
											name="title"
											value={editFormData.title}
											onChange={handleEditChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Category</label>
										<select
											className="form-control"
											name="category"
											required
											value={editFormData.category}
											onChange={handleEditChange}
										>
											{productCategoryObj.map((category, index) => (
												<option key={index} value={category}>
													{category}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="">Category Description</label>
										<textarea
											className="form-control"
											name="category_description"
											value={editFormData.category_description}
											onChange={handleEditChange}
											cols="30"
											rows="3"
											required
										></textarea>
									</div>
									<div className="form-group">
										<label htmlFor="">Price</label>
										<input
											type="number"
											className="form-control"
											name="price"
											value={editFormData.price}
											onChange={handleEditChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Product Link</label>
										<input
											type="url"
											placeholder="e.g. https://www.xyz.com"
											className="form-control"
											name="link_1"
											value={editFormData.link_1}
											onChange={handleEditChange}
											required
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Images</label>
										<div {...getRootProps()} style={dropzoneStyles}>
											<p className="m-0">
												Drag & drop images here, or click to select files
											</p>
											<input {...getInputProps()} className="dropzone-input" />
										</div>
										<small className="text-danger">
											*Adding new images will replace previous images
										</small>
										{uploadedFilesPreview.length > 0 && (
											<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
												<h6>New Images</h6>
												{uploadedFilesPreview}
											</div>
										)}
										<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
											{editFormData.images != null ? (
												editFormData.images.length > 0 &&
												editFormData.images[0] != "" ? (
													editFormData.images.map((photoUrl, index) => (
														<div className="img" key={`photo-${index}`}>
															<img src={photoUrl} alt={`photo-${index}`} />
														</div>
													))
												) : (
													<div className="img">
														<img
															src={editFormData.photo_url_1}
															alt={editFormData.photo_url_1}
														/>
													</div>
												)
											) : (
												<div className="img">
													<img
														src={editFormData.photo_url_1}
														alt={editFormData.photo_url_1}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="">Product Description</label>
										<textarea
											className="form-control"
											name="description"
											value={editFormData.description}
											onChange={handleEditChange}
											cols="30"
											rows="4"
											required
										></textarea>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group separator">
										<h4>Overview</h4>
										<div className="form-row">
											<div className="col-6">
												<label htmlFor="">Description</label>
												<textarea
													name="overview"
													className="form-control"
													value={editFormData.overview}
													onChange={handleEditChange}
													cols="30"
													rows="3"
												></textarea>
											</div>
											<div className="col-6">
												<label htmlFor="">Decription for bullets </label>
												<textarea
													name="overview_discription"
													className="form-control"
													placeholder="e.g. text 1&#13;text 2 &#13;text 3"
													value={editFormData.overview_discription}
													onChange={handleEditChange}
													cols="30"
													rows="3"
												></textarea>
												{/* <small className="text-danger">
													*Separated by line
												</small> */}
											</div>
										</div>
									</div>
									<div className="separator">
										<h4>Image Block 1</h4>
										<div className="form-group">
											<label htmlFor="">Heading</label>
											<input
												type="text"
												name="first_img_heading"
												value={editFormData.first_img_heading}
												onChange={handleEditChange}
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Description</label>
											<textarea
												className="form-control"
												name="first_img_description"
												value={editFormData.first_img_description}
												onChange={handleEditChange}
												cols="30"
												rows="3"
											></textarea>
										</div>
									</div>
									<div className="separator">
										<h4>Image Block 2</h4>
										<div className="form-group">
											<label htmlFor="">Heading</label>
											<input
												type="text"
												name="second_img_heading"
												value={editFormData.second_img_heading}
												onChange={handleEditChange}
												className="form-control"
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Description</label>
											<textarea
												className="form-control"
												name="second_img_description"
												value={editFormData.second_img_description}
												onChange={handleEditChange}
												cols="30"
												rows="3"
											></textarea>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="big-one">
										<div className="">
											<h4>Block Title</h4>
											<div className="form-group">
												<input
													type="text"
													name="block_title"
													value={editFormData.block_title}
													onChange={handleEditChange}
													className="form-control"
												/>
											</div>
										</div>
										<div className="">
											<div className="row">
												<div className="col-md-4">
													<h4>Block A</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blocka_heading"
															value={editFormData.blocka_heading}
															onChange={handleEditChange}
															className="form-control"
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blocka_description"
															value={editFormData.blocka_description}
															onChange={handleEditChange}
															cols="30"
															rows="3"
														></textarea>
													</div>
												</div>
												<div className="col-md-4">
													<h4>Block B</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blockb_heading"
															value={editFormData.blockb_heading}
															onChange={handleEditChange}
															className="form-control"
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blockb_description"
															value={editFormData.blockb_description}
															onChange={handleEditChange}
															cols="30"
															rows="3"
														></textarea>
													</div>
												</div>
												<div className="col-md-4">
													<h4>Block C</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blockc_heading"
															value={editFormData.blockc_heading}
															onChange={handleEditChange}
															className="form-control"
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blockd_description"
															value={editFormData.blockd_description}
															onChange={handleEditChange}
															cols="30"
															rows="3"
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 text-center">
									<button className="btn btn-gradient">Save Product</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			{/* view modal */}
			<div
				className="modal so-modal fade"
				id="viewProduct"
				tabIndex="-1"
				role="dialog"
				aria-labelledby="modelTitleId"
				aria-hidden="true"
			>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
						>
							{close_svg}
						</button>
						<div className="modal-body">
							<div className="d-flex align-items-center justify-content-center mb-4">
								<div className="prev cursor-pointer" onClick={handlePrevious}>
									{prev_svg}
								</div>
								<div className="title mx-4">
									<h2 className="mb-0">View Product</h2>
								</div>
								<div className="next cursor-pointer" onClick={handleNext}>
									{next_svg}
								</div>
							</div>

							<form onSubmit={handleSubmit} className="row">
								<div className="col-md-6">
									<div className="form-group">
										<label htmlFor="">Product Name</label>
										<input
											type="text"
											className="form-control"
											name="title"
											value={editFormData.title}
											onChange={handleChange}
											disabled
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Category</label>
										<select
											className="form-control"
											name="category"
											disabled
											value={editFormData.category}
											onChange={handleChange}
										>
											{productCategoryObj.map((category, index) => (
												<option key={index} value={category}>
													{category}
												</option>
											))}
										</select>
									</div>
									<div className="form-group">
										<label htmlFor="">Category Description</label>
										<textarea
											className="form-control"
											name="category_description"
											value={editFormData.category_description}
											onChange={handleChange}
											cols="30"
											rows="3"
											disabled
										></textarea>
									</div>
									<div className="form-group">
										<label htmlFor="">Price</label>
										<input
											type="number"
											className="form-control"
											name="price"
											value={editFormData.price}
											onChange={handleChange}
											disabled
										/>
									</div>
									<div className="form-group">
										<label htmlFor="">Product Link</label>
										<input
											type="url"
											placeholder="e.g. https://www.xyz.com"
											className="form-control"
											name="link_1"
											value={editFormData.link_1}
											onChange={handleChange}
											disabled
										/>
									</div>

									<div className="form-group">
										<label htmlFor="">Images</label>
										<div className="preview-imgs d-flex flex-wrap align-items-center justify-content-start">
											{editFormData.images != null ? (
												editFormData.images.length > 0 &&
												editFormData.images[0] != "" ? (
													editFormData.images.map((photoUrl, index) => (
														<div className="img" key={`photo-${index}`}>
															<img src={photoUrl} alt={`photo-${index}`} />
														</div>
													))
												) : (
													<div className="img">
														<img
															src={editFormData.photo_url_1}
															alt={editFormData.photo_url_1}
														/>
													</div>
												)
											) : (
												<div className="img">
													<img
														src={editFormData.photo_url_1}
														alt={editFormData.photo_url_1}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="form-group">
										<label htmlFor="">Product Description</label>
										<textarea
											className="form-control"
											name="description"
											value={editFormData.description}
											onChange={handleChange}
											cols="30"
											rows="4"
											disabled
										></textarea>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group separator">
										<h4>Overview</h4>
										<div className="form-row">
											<div className="col-6">
												<label htmlFor="">Description</label>
												<textarea
													name="overview"
													className="form-control"
													value={editFormData.overview}
													onChange={handleChange}
													cols="30"
													disabled
													rows="3"
												></textarea>
											</div>
											<div className="col-6">
												<label htmlFor="">Decription for bullets </label>
												<textarea
													name="overview_discription"
													className="form-control"
													placeholder="e.g. text 1&#13;text 2 &#13;text 3"
													value={editFormData.overview_discription}
													onBlur={handleChange}
													cols="30"
													rows="3"
													disabled
												></textarea>
												{/* <small className="text-danger">
													*Separated by line
												</small> */}
											</div>
										</div>
									</div>
									<div className="separator">
										<h4>Image Block 1</h4>
										<div className="form-group">
											<label htmlFor="">Heading</label>
											<input
												type="text"
												name="first_img_heading"
												value={editFormData.first_img_heading}
												onChange={handleChange}
												className="form-control"
												disabled
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Description</label>
											<textarea
												className="form-control"
												name="first_img_description"
												value={editFormData.first_img_description}
												onChange={handleChange}
												cols="30"
												rows="3"
												disabled
											></textarea>
										</div>
									</div>
									<div className="separator">
										<h4>Image Block 2</h4>
										<div className="form-group">
											<label htmlFor="">Heading</label>
											<input
												type="text"
												name="second_img_heading"
												value={editFormData.second_img_heading}
												onChange={handleChange}
												className="form-control"
												disabled
											/>
										</div>
										<div className="form-group">
											<label htmlFor="">Description</label>
											<textarea
												className="form-control"
												name="second_img_description"
												value={editFormData.second_img_description}
												onChange={handleChange}
												cols="30"
												rows="3"
												disabled
											></textarea>
										</div>
									</div>
								</div>
								<div className="col-md-12">
									<div className="big-one">
										<div className="">
											<h4>Block Title</h4>
											<div className="form-group">
												<input
													type="text"
													name="block_title"
													value={editFormData.block_title}
													onChange={handleChange}
													className="form-control"
													disabled
												/>
											</div>
										</div>
										<div className="">
											<div className="row">
												<div className="col-md-4">
													<h4>Block A</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blocka_heading"
															value={editFormData.blocka_heading}
															onChange={handleChange}
															className="form-control"
															disabled
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blocka_description"
															value={editFormData.blocka_description}
															onChange={handleChange}
															cols="30"
															rows="3"
															disabled
														></textarea>
													</div>
												</div>
												<div className="col-md-4">
													<h4>Block B</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blockb_heading"
															value={editFormData.blockb_heading}
															onChange={handleChange}
															className="form-control"
															disabled
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blockb_description"
															value={editFormData.blockb_description}
															onChange={handleChange}
															cols="30"
															rows="3"
															disabled
														></textarea>
													</div>
												</div>
												<div className="col-md-4">
													<h4>Block C</h4>
													<div className="form-group">
														<label htmlFor="">Heading</label>
														<input
															type="text"
															name="blockc_heading"
															value={editFormData.blockc_heading}
															onChange={handleChange}
															className="form-control"
															disabled
														/>
														<label htmlFor="">Description</label>
														<textarea
															className="form-control"
															name="blockd_description"
															value={editFormData.blockd_description}
															onChange={handleChange}
															cols="30"
															rows="3"
															disabled
														></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 text-center">
									<button
										type="button"
										onClick={handleEditOpen}
										className="btn btn-gradient"
									>
										Edit Product
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default ProductManagement;
