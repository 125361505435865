import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import Swal from "sweetalert2";
import axios from "axios";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { log } from "async";
export default function EmailTemplates() {
	useEffect(() => {
		fetchData();
	}, []);
	const fetchData = async () => {
		setLoading(true);
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			};
			const response = await axios.get(
				`${process.env.REACT_APP_FRONTEND_URL}api/emailtemplates/getAllTemplates`,
				config
			);
			if (response.data.isError === false) {
				response.data.data.forEach((item) => {
					if (item.emailType == "instant") {
						setCredUsed({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setCredsEditorState(EditorState.createWithContent(contentState));
					}
					if (item.emailType == "store_not_active") {
						setStoreNotActive({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setNotActiveEditorState(
							EditorState.createWithContent(contentState)
						);
					}
					if (item.emailType == "store_not_active_first_attempt") {
						setStoreNotActiveSecondAttempt({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setNotActiveSecondAttemptEditorState(
							EditorState.createWithContent(contentState)
						);
					}
					if (item.emailType == "store_not_active_second_attempt") {
						setStoreNotActiveThirdAttempt({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setNotActiveThirdAttemptEditorState(
							EditorState.createWithContent(contentState)
						);
					}
					if (item.emailType == "store_active") {
						setStoreActive({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setActiveEditorState(EditorState.createWithContent(contentState));
					}
					if (item.emailType == "incomplete_store") {
						setStoreNotComplete({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setStoreNotCompleteEditorState(EditorState.createWithContent(contentState));
					}
					if (item.emailType == "store_final") {
						setStoreFinal({
							id: item._id,
							subject: item.subject,
							content: item.content,
							delay: item.delay,
							emailType: item.emailType
						});
						let contentState = stateFromHTML(item.content); //automatedMessage.message being "<p>Test</p>"
						setStoreFinalEditorState(EditorState.createWithContent(contentState));
					}
				});
			}
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.log(error);
		}
	};
	const [loading, setLoading] = useState(false);
	const [credsEditorState, setCredsEditorState] = useState(
		EditorState.createEmpty()
	);
	const [credsUsed, setCredUsed] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "instant"
	});
	const [notActiveEditorState, setNotActiveEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeNotActive, setStoreNotActive] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "store_not_active"
	});
	const [notActiveSecondAttemptEditorState, setNotActiveSecondAttemptEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeNotActiveSecondAttempt, setStoreNotActiveSecondAttempt] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "store_not_active_first_attempt"
	});
	const [notActiveThirdAttemptEditorState, setNotActiveThirdAttemptEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeNotActiveThirdAttempt, setStoreNotActiveThirdAttempt] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "store_not_active_first_attempt"
	});
	const [activeEditorState, setActiveEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeActive, setStoreActive] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "store_active"
	});
	const [storeNotCompleteEditorState, setStoreNotCompleteEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeFinal, setStoreFinal] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "store_final"
	});
	const [storeFinalEditorState, setStoreFinalEditorState] = useState(
		EditorState.createEmpty()
	);
	const [storeNotComplete, setStoreNotComplete] = useState({
		id: null,
		subject: "",
		content: "",
		delay: null,
		emailType: "incomplete_store"
	});
	const handleCredsUsedChange = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setCredUsed((prevData) => ({ ...prevData, [name]: value }));
		} else {
			// console.log(e);
			setCredsEditorState(e);
			const contentState = credsEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setCredUsed((prevData) => ({ ...prevData, content: htmlContent }));
		}
	};
	const handleNotActiveChange = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreNotActive((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setNotActiveEditorState(e);
			const contentState = notActiveEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreNotActive((prevData) => ({
				...prevData,
				content: htmlContent
			}));
		}
	};
	const handleNotActiveSecondAttemptChange = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreNotActiveSecondAttempt((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setNotActiveSecondAttemptEditorState(e);
			const contentState = notActiveSecondAttemptEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreNotActiveSecondAttempt((prevData) => ({
				...prevData,
				content: htmlContent
			}));
		}
	};
	const handleNotActiveChangeThirdAttempt = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreNotActiveThirdAttempt((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setNotActiveThirdAttemptEditorState(e);
			const contentState = notActiveThirdAttemptEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreNotActiveThirdAttempt((prevData) => ({
				...prevData,
				content: htmlContent
			}));
		}
	};
	const handleActiveChange = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreActive((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setActiveEditorState(e);
			const contentState = activeEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreActive((prevData) => ({ ...prevData, content: htmlContent }));
		}
	};
	const handleNotStoreComplete = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreNotComplete((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setStoreNotCompleteEditorState(e);
			const contentState = storeNotCompleteEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreNotComplete((prevData) => ({ ...prevData, content: htmlContent }));
		}
	};
	const handleStoreFinalChange = async (e) => {
		if (e.target != undefined) {
			const { name, value } = e.target;
			setStoreFinal((prevData) => ({ ...prevData, [name]: value }));
		} else {
			setStoreFinalEditorState(e);
			const contentState = storeFinalEditorState.getCurrentContent();
			const htmlContent = stateToHTML(contentState);
			setStoreFinal((prevData) => ({ ...prevData, content: htmlContent }));
		}
	};
	const handleSubmit = async (e, type) => {
		e.preventDefault();
		setLoading(true);
		try {
			const config = {
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${localStorage.getItem("token")}`
				}
			};
			const formData = new FormData();
			let is_update = false;
			let response;
			if (type == "credsUsed") {
				formData.append("subject", credsUsed.subject);
				formData.append("content", credsUsed.content);
				formData.append("emailType", credsUsed.emailType);
				formData.append("delay", credsUsed.delay);
				if (credsUsed.id != null) {
					formData.append("templateId", credsUsed.id);
					is_update = true;
				}
			} else if (type == "storeNotActive") {
				formData.append("subject", storeNotActive.subject);
				formData.append("content", storeNotActive.content);
				formData.append("emailType", storeNotActive.emailType);
				formData.append("delay", storeNotActive.delay);
				if (storeNotActive.id != null) {
					formData.append("templateId", storeNotActive.id);
					is_update = true;
				}
			} else if (type == "storeNotActiveSecondAttempt") {
				formData.append("subject", storeNotActiveSecondAttempt.subject);
				formData.append("content", storeNotActiveSecondAttempt.content);
				formData.append("emailType", storeNotActiveSecondAttempt.emailType);
				formData.append("delay", storeNotActiveSecondAttempt.delay);
				if (storeNotActiveSecondAttempt.id != null) {
					formData.append("templateId", storeNotActiveSecondAttempt.id);
					is_update = true;
				}
			} else if (type == "storeNotActiveThirdAttempt") {
				formData.append("subject", storeNotActiveThirdAttempt.subject);
				formData.append("content", storeNotActiveThirdAttempt.content);
				formData.append("emailType", storeNotActiveThirdAttempt.emailType);
				formData.append("delay", storeNotActiveThirdAttempt.delay);
				if (storeNotActiveThirdAttempt.id != null) {
					formData.append("templateId", storeNotActiveThirdAttempt.id);
					is_update = true;
				}
			} else if (type == "storeActive") {
				formData.append("subject", storeActive.subject);
				formData.append("content", storeActive.content);
				formData.append("emailType", storeActive.emailType);
				formData.append("delay", storeActive.delay);
				if (storeActive.id != null) {
					formData.append("templateId", storeActive.id);
					is_update = true;
				}
			} else if (type == "storeNotComplete") {
				formData.append("subject", storeNotComplete.subject);
				formData.append("content", storeNotComplete.content);
				formData.append("emailType", storeNotComplete.emailType);
				formData.append("delay", storeNotComplete.delay);
				if (storeNotComplete.id != null) {
					formData.append("templateId", storeNotComplete.id);
					is_update = true;
				}
			} else if (type == "storeFinal") {
				formData.append("subject", storeFinal.subject);
				formData.append("content", storeFinal.content);
				formData.append("emailType", storeFinal.emailType);
				formData.append("delay", storeFinal.delay);
				if (storeFinal.id != null) {
					formData.append("templateId", storeFinal.id);
					is_update = true;
				}
			}
			if (is_update) {
				response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/update/EmailTemplate`,
					formData,
					config
				);
			} else {
				response = await axios.post(
					`${process.env.REACT_APP_FRONTEND_URL}api/add/EmailTemplate`,
					formData,
					config
				);
			}
			if (response.data.isError === false) {
				Swal.fire({
					icon: "success",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
				fetchData();
			} else {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: response.data.message,
					showConfirmButton: false,
					timer: 2000 // milliseconds
				});
			}
		} catch (error) {
			// Handle errors from await
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "There was an error, please try again later",
				showConfirmButton: false,
				timer: 2000 // milliseconds
			});
			// setAccessTokenError(error.response.data.message);
			console.log(error);
		}
	};
	return (
		<>
			{loading && (
				<TailSpin
					height="80"
					width="80"
					color="#4fa94d"
					ariaLabel="tail-spin-loading"
					radius="1"
					wrapperStyle={{}}
					wrapperClass="custom-loader"
				/>
			)}
			<div className="header">
				<h2>Email Templates</h2>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">Template For Credentials Issued</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "credsUsed")}>
						<div className="form-group mw-75 ml-0 text-left">
							<label htmlFor="subject">Subject</label>
							<input
								type="text"
								className="form-control"
								name="subject"
								value={credsUsed.subject}
								onChange={handleCredsUsedChange}
								id="subject"
								required
							/>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={credsEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleCredsUsedChange}
							/>
						</div>
						<button type="submit" className="btn btn-primary mt-2 float-right">
							Save
						</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template For Credentials Issued and Store Activated
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeActive")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject2">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeActive.subject}
										onChange={handleActiveChange}
										id="subject2"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay2">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeActive.delay}
										onChange={handleActiveChange}
										id="delay2"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={activeEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleActiveChange}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template For Credentials Issued and Store not Activated (1st Attempt)
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeNotActive")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject1">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeNotActive.subject}
										onChange={handleNotActiveChange}
										id="subject1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay1">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeNotActive.delay}
										onChange={handleNotActiveChange}
										id="delay1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={notActiveEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleNotActiveChange}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template For Credentials Issued and Store not Activated (2nd Attempt)
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeNotActiveSecondAttempt")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject1">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeNotActiveSecondAttempt.subject}
										onChange={handleNotActiveSecondAttemptChange}
										id="subject1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay1">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeNotActiveSecondAttempt.delay}
										onChange={handleNotActiveSecondAttemptChange}
										id="delay1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={notActiveSecondAttemptEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleNotActiveSecondAttemptChange}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template For Credentials Issued and Store not Activated (3rd Attempt)
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeNotActiveThirdAttempt")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject1">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeNotActiveThirdAttempt.subject}
										onChange={handleNotActiveChangeThirdAttempt}
										id="subject1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay1">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeNotActiveThirdAttempt.delay}
										onChange={handleNotActiveChangeThirdAttempt}
										id="delay1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={notActiveThirdAttemptEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleNotActiveChangeThirdAttempt}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template for lost users who provided email only
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeNotComplete")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject1">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeNotComplete.subject}
										onChange={handleNotStoreComplete}
										id="subject1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay1">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeNotComplete.delay}
										onChange={handleNotStoreComplete}
										id="delay1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={storeNotCompleteEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleNotStoreComplete}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
			<div className="card mb-4 email-template-card">
				<div className="card-header">
					Template for Store Complete Final Mail
				</div>
				<div className="card-body">
					<form onSubmit={(e) => handleSubmit(e, "storeFinal")}>
						<div className="form-row">
							<div className="col-md-8">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="subject1">Subject</label>
									<input
										type="text"
										className="form-control"
										name="subject"
										value={storeFinal.subject}
										onChange={handleStoreFinalChange}
										id="subject1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
							<div className="col-md-4">
								<div className="form-group mw-100 ml-0 text-left">
									<label htmlFor="delay1">Delay (in minutes)</label>
									<input
										type="number"
										className="form-control"
										name="delay"
										value={storeFinal.delay}
										onChange={handleStoreFinalChange}
										id="delay1"
										aria-describedby="helpId"
										required
									/>
								</div>
							</div>
						</div>
						<div className="form-group mw-100 mt-4 ml-0 text-left">
							<label htmlFor="subject">Content</label>
							<Editor
								editorState={storeFinalEditorState}
								toolbarClassName="toolbarClassName"
								wrapperClassName="wrapperClassName"
								editorClassName="editorClassName"
								toolbar={{
									options: [
										"blockType",
										"inline",
										"fontSize",
										"fontFamily",
										"link",
										"emoji",
										"list",
										"textAlign",
										"history"
									],
									inline: { inDropdown: true },
									list: { inDropdown: true },
									textAlign: { inDropdown: true },
									link: { inDropdown: true },
									history: { inDropdown: true }
								}}
								onEditorStateChange={handleStoreFinalChange}
							/>
						</div>
						<button className="btn btn-primary mt-2 float-right">Save</button>
					</form>
				</div>
			</div>
		</>
	);
}
