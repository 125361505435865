import React, { useEffect, useState } from "react";
import { isMobile, isTablet, isBrowser } from 'react-device-detect';
// import { Player, BigPlayButton } from "video-react";
import axios from "axios";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import "video-react/dist/video-react.css"; // import css
import { useNavigate, useLocation } from 'react-router-dom';
// import "../../App.css";
// import "../../component/Stepper/stepper.css";
import "../../css/style.css";
import store_logo from "../../images/store-logo/brandbldr-logo-dark.png";

import jad_kantari from "../../images/jad.jpeg";
// import main_video_poster from "../../images/video-poster.png";
// import main_video from "../../images/landing-video.mp4";
// import MobileStepsPopup from "../../component/Popup/MobileStepsPopup";
import Partner from '../../images/partners/partners.png';
import Partner1 from '../../images/partners/partners1.png';
import { Affiliate } from "./Affiliate";
// import BrowserDetectPopup from "../../component/Popup/BrowserDetectPopup";
function Index() {
	// const insertGTM = (gtmId) => {
	// 	if (!gtmId) return;

	// 	(function (w, d, s, l, i) {
	// 		w[l] = w[l] || [];
	// 		w[l].push({
	// 			"gtm.start": new Date().getTime(),
	// 			event: "gtm.js"
	// 		});
	// 		const f = d.getElementsByTagName(s)[0],
	// 			j = d.createElement(s),
	// 			dl = l !== "dataLayer" ? "&l=" + l : "";
	// 		j.async = true;
	// 		j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
	// 		f.parentNode.insertBefore(j, f);
	// 	})(window, document, "script", "dataLayer", gtmId);
	// };
	const insertSnapPixel = (snapId) => {
		if (window.snaptr) return;

		// Snap Pixel initialization
		window.snaptr = function () {
			window.snaptr.handleRequest
				? window.snaptr.handleRequest.apply(window.snaptr, arguments)
				: window.snaptr.queue.push(arguments);
		};
		window.snaptr.queue = [];

		// Creating the Snap Pixel script tag
		const scriptTag = document.createElement("script");
		scriptTag.async = true;
		scriptTag.src = "https://sc-static.net/scevent.min.js";

		// Inserting the script tag before other scripts
		const firstScriptTag = document.getElementsByTagName("script")[0];
		firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);

		// Function to initialize and track events after the script loads
		scriptTag.onload = () => {
			window.snaptr("init", snapId, {});
			window.snaptr("track", "PAGE_VIEW");
		};
	};
	const isMobile = window.innerWidth < 480;
	const [isMobileView, setIsMobileView] = useState(isMobile);
	const [inAppBrowser, setInAppBrowser] = useState(false);
	const currentUrl = window.location.href;
	const [isLoading, setIsLoading] = useState(true);
	const [affilateData, setAffiliateData] = useState({
		coverImg: "",
		testimonial: "",
		sigHeading: "",
		sigDiscription: "",
		isAffiliate: false
	});
	const pageTracker = async () => {
		try {
			// Fetch client IP
			const ipResponse = await axios.get('https://api.ipify.org?format=json');
			const ip = ipResponse.data.ip;

			// Fetch client country using the IP
			const countryResponse = await axios.get(`https://ipapi.co/${ip}/json/`);
			const country = countryResponse.data.country_name;

			// Determine the device type
			let deviceType = 'Unknown';
			if (isMobile) {
				deviceType = 'Mobile';
			} else if (isTablet) {
				deviceType = 'Tablet';
			} else if (isBrowser) {
				deviceType = 'Browser';
			}
			//Storing Data
			const config = {
				headers: {
					"Content-Type": "application/json"
				}
			};
			const formData = new FormData();
			formData.append("ip", ip);
			formData.append("device", deviceType);
			formData.append("country", country);
			formData.append("landing_page_count", 1);
			const pageTrackerResponse = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/page-tracker`,
				formData,
				config
			);
			if(pageTrackerResponse.data.success === true){
				localStorage.setItem("page_track_id", pageTrackerResponse.data.data._id);
			}
		} catch (error) {
			console.error('Error Page Track info:', error);
		}
	};
	async function postUrl() {
		let shopify_link = "https://shopify.pxf.io/c/3761361/1101159/13624";
		try {
			const aff_res = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/getAffiliateUser`,
				{ affiliate_link: currentUrl },
				// { affiliate_link: "https://brandbldr.ai/?ref=e4zy/" },
				{ headers: { "Content-Type": "application/json" } }
			);
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/user/addNewAffiliateClick`,
				{ affiliate_link: currentUrl },
				{ headers: { "Content-Type": "application/json" } }
			);
			if (
				aff_res.data.isError === false &&
				aff_res.data.data.length > 0
				// &&
				// aff_res.data.data[0].coverImage !== null &&
				// aff_res.data.data[0].testimonial !== null &&
				// aff_res.data.data[0].sigHeading !== null &&
				// aff_res.data.data[0].sigDiscription !== null
			) {
				setAffiliateData({
					coverImg: aff_res.data.data[0].coverImage,
					testimonial: aff_res.data.data[0].testimonial,
					sigHeading: aff_res.data.data[0].sigHeading,
					sigDiscription: aff_res.data.data[0].sigDiscription,
					isAffiliate:
						aff_res.data.data[0].sigHeading == "Jad Kantari" ? false : true
				});
				shopify_link = aff_res.data.data[0].shopify_link;
				setIsLoading(false);
			} else {
				setAffiliateData({
					coverImg: jad_kantari,
					testimonial: `This is the first-ever AI-powered store builder that delivers
									to you a fully-built Shopify store loaded with hand-picked
									winning products that my team and I PERSONALLY compiled in an
									effort to minimize all barriers standing between you and that
									first sale. We constantly update the pool of products to
									ensure you get nothing but top-sellers. Even the theme you get
									is a custom built theme that I have personally developed and
									used to scale my brands to generate over $30 million in sales.
									And the best part is it's completely FREE! No hidden fees. No bs. You won't get this
									anywhere else, and it won't be free forever. Take advantage of
									this while you still can, and please make sure you follow the
									steps closely throughout the funnel as it is very crucial you
									FULLY complete all the steps in order to receive your free
									store. Welcome to the future of e-commerce, and good luck on
									your journey.`,
					sigHeading: "Jad Kantari",
					sigDiscription: "BRALE",
					isAffiliate: false
				});
				setIsLoading(false);
			}
		} catch (error) {
			console.log(error);
			setIsLoading(false);
			setAffiliateData({
				coverImg: jad_kantari,
				testimonial: `This is the first-ever AI-powered store builder that delivers
									to you a fully-built Shopify store loaded with hand-picked
									winning products that my team and I PERSONALLY compiled in an
									effort to minimize all barriers standing between you and that
									first sale. We constantly update the pool of products to
									ensure you get nothing but top-sellers. Even the theme you get
									is a custom built theme that I have personally developed and
									used to scale my brands to generate over $30 million in sales.
									And the best part is it's completely FREE! No hidden fees. No bs. You won't get this
									anywhere else, and it won't be free forever. Take advantage of
									this while you still can, and please make sure you follow the
									steps closely throughout the funnel as it is very crucial you
									FULLY complete all the steps in order to receive your free
									store. Welcome to the future of e-commerce, and good luck on
									your journey.`,
				sigHeading: "Jad Kantari",
				sigDiscription: "BRALE",
				isAffiliate: false
			});
		}
		localStorage.setItem("shopify_link", shopify_link);
	}
	useEffect(() => {
		if(detectInAppBrowser() || detectAd()){
			const userAgent = navigator.userAgent || navigator.vendor || window.opera;
			const isAndroid = /Android/i.test(userAgent);
			const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
			if(isAndroid){
				// window.open("googlechrome://brandbldr.ai", '_system');
				window.location = 'intent:https://brandbldr.ai#Intent;end';
			}else{
				window.location = "x-safari-https://brandbldr.ai";
			}
		}
		
		// insertGTM("GTM-NFQHG2B3");
		insertSnapPixel("ad1fa1c3-78fb-47dc-b631-bb7075fd34e1");
		// setInAppBrowser(detectInAppBrowser());
		
		postUrl();
		const isTrackedAlready = localStorage.getItem("page_track_id");
		if(isTrackedAlready === "" || isTrackedAlready === null)
		{
			pageTracker();
		}
		localStorage.setItem("affiliate_url", currentUrl);

		setIsMobileView(window.innerWidth < 480);
	}, []);
	const [isError, setIsError] = useState(true);
	const [errorMsg, setErrorMsg] = useState("");
	const [email, setEmail] = useState("");
	const handleEmailChange = (e)=>{
		let mail = e.target.value;
		setErrorMsg("");
		setIsError(false);
		if(!validateEmail(mail)){
			setErrorMsg("*Enter a valid email!");
			setIsError(true);
		}
		setEmail(e.target.value);
	}
	const navigate = useNavigate();
	const checkStoreExists = async(email) => {
		const config = {
			headers: {
				"Content-Type": "application/json",
			}
		};
		const response = await axios.post(
			`${process.env.REACT_APP_FRONTEND_URL}api/acounts/checkUserStoreExist`,
			{email: email},
			config
		);
		return response;
	}
	const handleClick = async(e)=>{
		e.preventDefault();
		try {
			let isStoreExists = await checkStoreExists(email);
			if(isStoreExists.data.isError === true){
				setIsError(true);
				setErrorMsg(isStoreExists.data.message);
				return;
			}
			const config = {
				headers: {
					"Content-Type": "application/json",
				}
			};
			const formData = new FormData();
			formData.append("email", email);
			formData.append("storeStatus", 'incomplete');
			setIsError(true);
			const response = await axios.post(
				`${process.env.REACT_APP_FRONTEND_URL}api/add/userEmailStatus`,
				formData,
				config
			);
			if (response.data.isError === false) {
				setIsError(false);
				// console.log(response.data.data._id);
				navigate('/start-building-brand', { state: { email: email, mailId: response.data.data._id,  } });
			} else {
				setErrorMsg(response.data.message);
			}
		}
		catch (error) {
			setErrorMsg("Something went wrong, try again!");
			
			console.log(error);
		}
	}
	const validateEmail = (email) => {
		// Regular expression for email validation
		const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		return emailPattern.test(email);
	};
	const location = useLocation();
	const detectAd = () =>{
		if(isMobileView){
			const queryParams = new URLSearchParams(location.search);
			const isAd = queryParams.get('ad');
			if(isAd !== null){
				return true;
			}
			return false;
		}
		return false;
	}
	const detectInAppBrowser = () => {
		const userAgent = navigator.userAgent || navigator.vendor || window.opera;
		if (/instagram/i.test(userAgent) || /FBAN|FBAV/i.test(userAgent) || /Snapchat/i.test(userAgent) || /tiktok/i.test(userAgent) || /Discord/i.test(userAgent)) {
		  return true;
		}
		return false;
	};
	return (
		<>
			<main className="so-main-container">
				{/* {
					inAppBrowser ? <BrowserDetectPopup isOpen={inAppBrowser}/> : ''
				} */}
				
				{!affilateData.isAffiliate ? (
					/*<section className="banner text-center">
						<div className="container top-section">
							<div className="row align-items-center">
								<div className="col-md-2">
									<div className="logo-section">
										<img
											src={store_logo}
											alt="site-logo"
											className="img-fluid"
										/>
									</div>
								</div>
								<div className="col-md-8">
									{isMobileView ? (
										<div className="text">
											<span className="text-colored">Congrats!</span> <br />{" "}
											Follow The Steps Below To Get YOUR E-Com Store{" "}
											<u>BUILT FOR YOU</u> <br />{" "}
											<span className="text-colored">100% FREE!</span>
										</div>
									) : (
										<div className="text">
											<span className="text-colored">Congrats!</span> Follow The
											Steps Below To Get YOUR E-Com Store <u>BUILT FOR YOU</u>{" "}
											<span className="text-colored">100% FREE!</span>
										</div>
									)}
								</div>
								<div className="col-md-2"></div>
							</div>
						</div>

						<div className="container">
							<div className="video-section">
								<Player poster={main_video_poster}>
									<source src={main_video} />
									<BigPlayButton position="center" />
								</Player>
								<div className="important">
									<strong>** IMPORTANT ** </strong>
									<br />
									<strong>
										This is <u> your ONLY chance</u> to get a Done For You Store
										Built 100% FREE!{" "}
									</strong>
									If you close this page, you may never{" "}
									<em>see this free offer again.</em>
								</div>
							</div>
						</div>

						<div className="container">
							<h1>
								<span className="text-colored">STEP 1:</span> Create Your{" "}
								<span className="text-colored"> Free Account </span> &{" "}
								<u>Our A.I.</u> Will <u>Build Your Store Now!</u>
							</h1>
							<p>
								As a <strong> special bonus </strong> I am pre-loading your
								store with <u> 20+ home-run potential products!</u>
							</p>
							<small>
								Start, style, and sell with our all-in-one platform!
							</small>
						</div>
						<MobileStepsPopup />
						<div className="container">
							<p>
								Our software will build you a professional Shopify store in less
								than 15 minutes...
							</p>
						</div>
					</section>*/
					<section className="new-banner">
						<div className="container">
							<div className="logo-section">
								<img
									src={store_logo}
									alt="site-logo"
									className="img-fluid"
								/>
							</div>
							<h1 className="main-heading">
								My <span className="colored">AI Software</span> Will Build You A Shopify Dropshipping Store In Less Than 10 Minutes... For 100% FREE + FREE TRAINING
							</h1>
							{/* <div className="video-section">
								<Player poster={main_video_poster}>
									<source src={main_video} />
									<BigPlayButton position="center" />
								</Player>
							</div> */}
							<div className="notice-text">
								<h2>No strings attached </h2>
								<h5>No upselling a course.</h5>
								<h5>Just pure free education.</h5>
							</div>
							<div className="mail-box">
								<input type="email" name="email" onChange={handleEmailChange} value={email} placeholder="Enter your email" />
								<button className="btn" onClick={handleClick} disabled={isError ? true : false}>Access</button>
							</div>
							<div className="note text-red">{errorMsg}</div>
							<p className="sub-tagline">
								As a special bonus, I'm pre-loading your store with 20 trending products and high quality suppliers
							</p>
							{/* <div className="text-center">
								<MobileStepsPopup />
							</div> */}
							<div className="partners-img">
								<img src={Partner} alt="" className="img-fluid" />
								<img src={Partner1} alt="" className="img-fluid" />
							</div>
						</div>
					</section>
				) : (
					<Affiliate store_logo={store_logo} />
				)}
				{/* <section className="working container">
					<h2 className="text-center">How it Works</h2>
					<div className="row text-center">
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									<div className="icon">
										<svg
											width="100"
											height="100"
											viewBox="0 0 100 100"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="50"
												cy="50"
												r="50"
												fill="url(#paint0_linear_30_3)"
											/>
											<path
												d="M50.6692 61.1478C56.1631 66.3732 58.3114 66.4435 60.5653 68.693C62.9131 71.0363 64.087 73.3795 61.7392 75.7227C59.3914 78.066 57.0436 76.8943 54.6957 74.5511C52.4418 72.3016 52.3479 70.0521 47.1357 64.6744"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M38.2609 67.5214C46.6892 67.5214 53.5217 60.7023 53.5217 52.2904C53.5217 43.8786 46.6892 37.0594 38.2609 37.0594C29.8325 37.0594 23 43.8786 23 52.2904C23 60.7023 29.8325 67.5214 38.2609 67.5214Z"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M58.8042 60.4566C68.8999 60.152 76.9999 51.9038 76.9999 41.7459C76.9999 31.3888 68.5947 23 58.2173 23C51.9721 23 46.4547 26.0579 43.0386 30.7327"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M58.2174 23C55.2709 25.9408 53.3692 30.4398 52.5005 35.3957"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M57.7126 41.7458H77"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M59.2034 59.4021C66.5403 50.4627 66.2234 30.9904 58.2173 23"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_30_3"
													x1="18"
													y1="93"
													x2="83"
													y2="12"
													gradientUnits="userSpaceOnUse"
												>
													<stop stopColor="#0B4F9D" />
													<stop offset="1" stopColor="#25E5C3" />
												</linearGradient>
											</defs>
										</svg>
									</div>
									<div className="title">Ready, Set, Grow! 💡 </div>
									<p>
										Your dream online store is just a few details away. Fill out
										your business information now and let's turn your e-commerce
										dreams into reality!
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									<div className="icon">
										<svg
											width="100"
											height="100"
											viewBox="0 0 100 100"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="50"
												cy="50"
												r="50"
												fill="url(#paint0_linear_30_4)"
											/>
											<path
												d="M38.86 69.29H24.43C23.64 69.29 23 68.64 23 67.85V24.43C23 23.64 23.64 23 24.43 23H75.56C76.36 23 77 23.64 77 24.43V67.85C77 68.64 76.36 69.28 75.57 69.28H69.29"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M23 30.71H77"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M26.8601 26.86H29.4301"
												stroke="white"
												strokeWidth="2"
												strokeLinejoin="round"
											/>
											<path
												d="M32 26.86H34.57"
												stroke="white"
												strokeWidth="2"
												strokeLinejoin="round"
											/>
											<circle cx="50" cy="69.29" r="1" fill="#434343" />
											<path
												d="M61.1399 73.82C61.1399 70.64 62.8499 69.04 62.8499 65.86C62.8499 61.5 62.8499 62.15 62.8499 59.49C62.8499 54.71 52.5599 53.12 52.5599 53.12V44.9C52.5599 43.31 50.9599 41.72 49.1299 41.72C47.4299 41.72 45.6999 43.31 45.6999 44.9V59.49L40.3899 57.72C37.7099 56.84 35.8599 60.24 38.1999 61.75C43.0299 64.88 45.6999 66.3 45.6999 67.44V73.81"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M44 77V73.82H62.86V77"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_30_4"
													x1="18"
													y1="93"
													x2="83"
													y2="12"
													gradientUnits="userSpaceOnUse"
												>
													<stop stopColor="#0B4F9D" />
													<stop offset="1" stopColor="#25E5C3" />
												</linearGradient>
											</defs>
										</svg>
									</div>
									<div className="title">Customize 🛠️</div>
									<p>
										Select your niche categories and provide us with your store
										info, and we'll tailor your customers an unforgettable
										shopping experience.
									</p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="card">
								<div className="card-body">
									<div className="icon">
										<svg
											width="100"
											height="100"
											viewBox="0 0 100 100"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<circle
												cx="50"
												cy="50"
												r="50"
												fill="url(#paint0_linear_30_5)"
											/>
											<path
												d="M74.65 54.52L73.48 58.04L33.78 61.82"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M29.9099 38.09H42.9599"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M42.9599 78C44.904 78 46.4799 76.424 46.4799 74.48C46.4799 72.536 44.904 70.96 42.9599 70.96C41.0159 70.96 39.4399 72.536 39.4399 74.48C39.4399 76.424 41.0159 78 42.9599 78Z"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M71.13 67.43H37.69C35.98 67.43 34.52 66.21 34.22 64.53L29.22 34.15C29.02 33.03 28.05 32.21 26.91 32.21H24.17C23.53 32.22 23 32.74 23 33.39V34.56"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M65.26 78C67.204 78 68.78 76.424 68.78 74.48C68.78 72.536 67.204 70.96 65.26 70.96C63.3159 70.96 61.74 72.536 61.74 74.48C61.74 76.424 63.3159 78 65.26 78Z"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M62.9101 52.18C70.6918 52.18 77.0001 45.8717 77.0001 38.09C77.0001 30.3083 70.6918 24 62.9101 24C55.1284 24 48.8201 30.3083 48.8201 38.09C48.8201 45.8717 55.1284 52.18 62.9101 52.18Z"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<path
												d="M68.78 34.57L60.6 42.75L57.04 39.26"
												stroke="white"
												strokeWidth="2"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
											<defs>
												<linearGradient
													id="paint0_linear_30_5"
													x1="18"
													y1="93"
													x2="83"
													y2="12"
													gradientUnits="userSpaceOnUse"
												>
													<stop stopColor="#0B4F9D" />
													<stop offset="1" stopColor="#25E5C3" />
												</linearGradient>
											</defs>
										</svg>
									</div>
									<div className="title">Launch with Flair! 🌟 </div>
									<p>
										Elevate your online presence with a Shopify store customized
										to perfection and stocked with handpicked, curated products.
									</p>
								</div>
							</div>
						</div>
					</div>
				</section> */}
				{!isLoading ? (
					<section className={`about ${!affilateData.isAffiliate ? 'admin-box': ''}`}>
						<div className="container">
							<div className="row align-items-center">
								<div className="col-md-6">
									<img
										src={affilateData.coverImg}
										alt="Jad Kantari"
										className="img-fluid home-img"
									/>
								</div>
								<div className="col-md-6">
									<p>{affilateData.testimonial}</p>
									<div className="info">
										<strong>{affilateData.sigHeading}</strong>
										<p>{affilateData.sigDiscription}</p>
									</div>
								</div>
							</div>
						</div>
					</section>
				) : (
					""
				)}

				<footer className="text-center">
					<small className="copyrights">
						Copyright &copy; {new Date().getFullYear()} Brandbldr
					</small>
				</footer>
			</main>
		</>
	);
}

export default Index;
